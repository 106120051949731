import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ServicesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="64" height="59" viewBox="0 0 64 59">
      <path d="M53.3333 14.75H5.33325V4.91663H53.3333V14.75ZM29.4133 29.5H21.3333V25.8125C21.3333 25.1241 21.9199 24.5833 22.6666 24.5833H34.7733C39.5733 21.8054 45.5199 21.3875 50.6666 23.3541V17.2083H7.99992V49.1666H31.5199C25.8666 43.7583 25.1733 35.6458 29.4133 29.5V29.5ZM62.3733 51.625L58.6666 55.042L50.3466 47.495C48.5066 48.552 46.3199 49.1666 43.9999 49.1666C37.3333 49.1666 31.9999 44.25 31.9999 38.1041C31.9999 31.9583 37.3333 27.0416 43.9999 27.0416C50.6666 27.0416 55.9999 31.9583 55.9999 38.1041C55.9999 40.2675 55.3333 42.3079 54.1599 44.0041L62.3733 51.625ZM50.6666 38.1041C50.6666 36.4741 49.9642 34.9109 48.714 33.7584C47.4637 32.6058 45.768 31.9583 43.9999 31.9583C42.2318 31.9583 40.5361 32.6058 39.2859 33.7584C38.0356 34.9109 37.3333 36.4741 37.3333 38.1041C37.3333 39.7341 38.0356 41.2973 39.2859 42.4499C40.5361 43.6025 42.2318 44.25 43.9999 44.25C45.768 44.25 47.4637 43.6025 48.714 42.4499C49.9642 41.2973 50.6666 39.7341 50.6666 38.1041Z" fill="white"/>
    </SvgIcon>
  )
}

export default ServicesIcon;
