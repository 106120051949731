import React, { useEffect, useState } from 'react';
import { Grid, Icon, makeStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';

import RenderTextFieldV2 from '../../../inputs/inputs-v2/RenderTextFieldV2';
import CompanyTableRow from './CompanyTableRow';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

type Props = {
  services: ServiceCompany[];
  onClickService?: (serviceId: string) => void;
  hasTitle?: boolean;
  count?: number;
  page?: number;
  pageSize?: number;
  onChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangePageSize?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeFilter?: (query: string) => void;
}

const ServicesTable = (props: Props) => {
  const [filteredServices, setFilteredServices] = useState<ServiceCompany[]>([]);
  const classes = useStyles();
  const tableHasClient = props.services.some((service) => !!service.clientName);

  useEffect(() => {
    setFilteredServices(props.services);
  }, [props.services]);

  const handleChangeFilter: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const value = e.target.value.toLowerCase();
    if(!!props.onChangeFilter) props.onChangeFilter(value);
    else if (value.length > 0 ) {
      const filterAux = props.services.filter((service) => service.name.toLowerCase().includes(value))
      setFilteredServices(filterAux);
    } else setFilteredServices(props.services);
   };

  return (
    <>
      {!!props.hasTitle && (
        <Grid item xs={12} className={classes.divider}>
          <Typography variant="h6" color="primary">Servicios:</Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <RenderTextFieldV2 onChange={handleChangeFilter} placeholder="Buscar por Nombre o RFC" startIcon={<Icon>search</Icon>} />
      </Grid>
      <Grid item xs={12} >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '35%' }}>Nombre del servicio</TableCell>
                {tableHasClient ? (
                  <>
                    <TableCell style={{ width: '17.5%' }}>Proveedor</TableCell>
                    <TableCell style={{ width: '17.5%' }}>Cliente</TableCell>
                  </>
                ) : (
                  <TableCell style={{ width: '35%' }}>Nombre del proveedor/cliente</TableCell>
                )}
                <TableCell style={{ width: '15%' }}>Estatus</TableCell>
                <TableCell style={{ width: '15%' }}>Ultima consulta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredServices.map((service, i) => {
                return <CompanyTableRow key={i} tableHasClient={tableHasClient} {...service} onClick={props.onClickService} />;
              })}
            </TableBody>
            {props.count !== undefined && props.page !== undefined && props.onChangePage && (

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={5}
                    count={props.count}
                    rowsPerPage={props.pageSize ?? 10}
                    page={props.page}
                    onPageChange={props.onChangePage}
                    onRowsPerPageChange={props.onChangePageSize}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto'
  }
}));

export default ServicesTable;