import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormLabel from '@material-ui/core/FormLabel';
//import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormNewUserData from '../components/management/company/SectionNewCompany/FormCreateCompany';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function NewCompanyFormPage() {

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);
 
  const handleClickBack = () => {
    history.push('/dashboard/companies/0');
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container className={classes.grid}>

      <Grid item xs={12} className={classes.buttonBack}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickBack}
        >
          Regresar
        </Button>
      </Grid>

      <div className={classes.root}>

        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {/* <Tab label="Detalles del Cliente" {...a11yProps(0)} />
            <Tab label="Monto y Método de pago" {...a11yProps(1)} /> */}
            <Tab label="Contrato" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container>
            <FormNewUserData />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>

        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>

        </TabPanel>
      </div>
    </Grid>
  );
}
export default NewCompanyFormPage;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    height: 'auto',
    justifyContent: 'center',
  },
  divForm: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    justifyContent: 'center',
    paddingBottom: 40,
    paddingTop: 40,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonBack: {
    paddingBottom: 10,
  },
  buttonSave: {
    paddingTop: 40,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  button2: {
    paddingBottom: 10,
    paddingTop: 40
  },
  inputLarge: {
    width: 660,
    paddingLeft: 2
  },
  input: {
    width: 460,
    paddingLeft: 2
  },
  panel: {
    width: 1000,
  },
  selectDiv: {
    paddingBottom: 10,
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto'
  },
  configSelect: {
    background: 'white', border: '1px  #1166B6',
    borderRadius: 20, fontSize: '14px', height: 35,
    padding: '5px', color: '#838d93', width: 460,
  },

}));

/**
 * 
 *  <Grid item xs={6}>
              <Typography variant="h5" color="primary" >Identidad</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary" >Tipo Plan</Typography>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup onChange={handleChangeIdentity} row aria-label="position" name="identity">
                <FormControlLabel value="fisica" control={<Radio color="primary" />} label="Persona Fisica" />
                <FormControlLabel value="moral" control={<Radio color="primary" />} label="Personal Moral" />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup onChange={handleChangeStatus} row aria-label="position" name="status">
                <FormControlLabel value="PREMIUM" control={<Radio color="primary" />} label="Premiun" />
                <FormControlLabel value="FREE" control={<Radio color="primary" />} label="Free" />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label={indentity === "fisica" ? 'Razón social' : 'Nombre completo'}
                id="outlined-size-small"
                value={details.name}
                onChange={handleChangeDetails('name')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="RFC"
                id="outlined-size-small"
                value={details.rfc}
                onChange={handleChangeDetails('rfc')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" >Datos Representante Legal</Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12} className={classes.divider}>
              <TextField
                label="Nombre Representante Legal:"
                id="outlined-size-small"
                value={details.legalUserName}
                onChange={handleChangeDetails('legalUserName')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Domicilio Fiscal :"
                id="outlined-size-small"
                value={details.addres}
                onChange={handleChangeDetails('addres')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={2} className={classes.divider}>
              <TextField
                label="Código Postal :"
                id="outlined-size-small"
                value={details.postal}
                onChange={handleChangeDetails('postal')}
                variant="outlined"
                className={classes.input}
                size="small"
              />
            </Grid>

            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Numero exterior :"
                id="outlined-size-small"
                value={details.numberExt}
                onChange={handleChangeDetails('numberExt')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Numero interior :"
                id="outlined-size-small"
                value={details.numeberInt}
                onChange={handleChangeDetails('numeberInt')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="País :"
                id="outlined-size-small"
                value={details.country}
                onChange={handleChangeDetails('country')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Cuidad :"
                id="outlined-size-small"
                value={details.city}
                onChange={handleChangeDetails('city')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Colonia :"
                id="outlined-size-small"
                value={details.colony}
                onChange={handleChangeDetails('colony')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>

            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Curp :"
                id="outlined-size-small"
                value={details.legalUserCurp}
                onChange={handleChangeDetails('legalUserCurp')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Sitio Web :"
                id="outlined-size-small"
                value={details.web}
                onChange={handleChangeDetails('web')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Nº de empleados :"
                id="outlined-size-small"
                value={details.employees}
                onChange={handleChangeDetails('employees')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <FormLabel >
                <Typography variant="h6" color="primary">Sector al que pertenece :</Typography>
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={age}
                onChange={handleChangeSelect}
                className={classes.configSelect}
              >
                <MenuItem value={10}>Sector 1</MenuItem>
                <MenuItem value={20}>Sector 2</MenuItem>
                <MenuItem value={30}>Sector 3</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <Typography variant="h6" color="primary">Servicios ofrecidos :</Typography>
              <Select

                fullWidth
                value={age2}
                onChange={handleChangeSelect2}
                className={classes.configSelect}
              >
                <MenuItem value={10}>Servicios 1</MenuItem>
                <MenuItem value={20}>Servicios 2</MenuItem>
                <MenuItem value={30}>Servicios 3</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary" >Administrador</Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Nombre Administrador :"
                id="outlined-size-small"
                value={details.adminUserName}
                onChange={handleChangeDetails('adminUserName')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Email :"
                id="outlined-size-small"
                value={details.emailAdminName}
                onChange={handleChangeDetails('emailAdminName')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>

            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="RFC Administrador :"
                id="outlined-size-small"
                value={details.curpAdmin}
                onChange={handleChangeDetails('curpAdmin')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.divider}>
              <TextField
                label="Número Telefónico :"
                id="outlined-size-small"
                value={details.celAdmin}
                onChange={handleChangeDetails('celAdmin')}
                variant="outlined"
                size="small"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonSave}>
              <Button
                color="primary"
                variant="contained"
                onClick={createCompany}
              >
                Crear empresa
              </Button>
            </Grid>
 */
