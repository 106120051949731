import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';

const StatusTooltip = withStyles<'tooltip', Record<string, never>, { textcolor?: 'warning' | 'success' | 'error' | 'info' } & TooltipProps>((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: ({ textcolor }) => (textcolor ? theme.palette[textcolor].main : theme.palette.common.black),
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold'
  },
  tooltipPlacementBottom: {
    margin: 0
  }
}))(Tooltip);

export default StatusTooltip;
