import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export interface RenderCheckboxProps {
  input: any;
  label?: JSX.Element;
  checked: boolean;
}


const RenderCheckbox = ({ input, label, checked }: RenderCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          onChange={ input.onChange }
          checked={checked}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
      }
      label={ label }
    />
  );
}

export default RenderCheckbox;
