import React from 'react';
import { Button, Grid, Typography } from "@material-ui/core";

import { FormType } from '../../../pages/ManagementPage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RenderTextField from '../../inputs/RenderTextField';

interface FormUsersProps {
  onCancel: () => void;
  onSubmit: (formData: FormUserFields) => void;
  typeForm: FormType;
  userSelected: User;
  formType: FormType;
  initialValues?:  Partial<FormUserFields>;
  companies: any[];
}

const FormUsers = (props: FormUsersProps) => {
  const {
    onCancel,
    formType,
    companies
  } = props;
  const formik = useFormik<FormUserFields>({
    initialValues: {
      companies:  companies,
      email: props.initialValues?.email ?? '',
      roles: [],
      projects: props.initialValues?.projects ?? [],
      name: props.initialValues?.name ?? '',
      lastName: props.initialValues?.lastName ?? '',
      imageFile: null,
    },
    onSubmit: props.onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required('Campo requerido'),
      lastName: Yup.string().required('Campo requerido'),
      email: Yup.string().required('Campo requerido').email('Dirección de correo no válida'),
    })
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12}>
        <Typography variant="h6" color="primary">{`${formType === 'create' ? 'Agregrar' : 'Editar'} Administrador`}</Typography>
          <RenderTextField
            id="name"
            name="name"
            error={formik.errors.name}
            label="Nombre(s)"
            onChange={formik.handleChange}
            touched={formik.touched.name}
            value={formik.values.name}
          />

          <RenderTextField
            id="lastName"
            name="lastName"
            error={formik.errors.lastName}
            label="Apellidos"
            onChange={formik.handleChange}
            touched={formik.touched.lastName}
            value={formik.values.lastName}
          />

          <RenderTextField
            id="email"
            name="email"
            error={formik.errors.email}
            label="Email"
            onChange={formik.handleChange}
            touched={formik.touched.email}
            value={formik.values.email}
            disabled={formType === 'edit'}
          />

          <div className="text-right" style={{ marginTop: 40 }}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => onCancel()}
            >
              Cancelar
            </Button>

            <Button
              className="ml-2"
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormUsers;