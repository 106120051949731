import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { FC } from "react";

type HandleDialog = [FC, () => void, () => void];

/**
 * Returns a dialog component, a show modal handler and a close modal handler.
 * At least you should use `MaterialityDialog` and `handleShowModal` from the return.
 * @returns {HandleDialog} [MaterialityDialog, handleShowModal, handleCloseModal];
 */
export const useHandleDialog = (): HandleDialog  => {
  const [showDialog, setShowDialog] = useState(false);
  const container = document.getElementById("root");

  const handleShowModal = () => {
    container!.className="main-content-blurred";
    setShowDialog(true);
  };
  const handleCloseModal = () => {
    container!.className="";
    setShowDialog(false);
  };

  /**
   * Custom dialog component shared by `useHandleDialog` hook.
   * @param  props.children Content that will be rendered inside the dialog.
   * @returns {JSX.Element} Dialog component ready to be used.
   */
  const MaterialityDialog: FC = ({ children }) => (
    <Dialog open={showDialog} onClose={handleCloseModal}  maxWidth={'md'} 
    scroll="body">
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );

  return [MaterialityDialog, handleShowModal, handleCloseModal];
}



