import { useEffect, useState } from "react";

export function useSearchDebounce(delay = 350): [string | undefined,  React.Dispatch<React.SetStateAction<string | undefined>>] {
    const [search, setSearch] = useState<string>();
    const [searchQuery, setSearchQuery] = useState<string>();

    useEffect(() => {
      const delayFn = setTimeout(() => setSearch(searchQuery), delay);
      return () => clearTimeout(delayFn);
    }, [searchQuery, delay]);
    return [search, setSearchQuery];
  }