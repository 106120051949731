import React, { Component } from 'react';
import { Button, Grid, Checkbox, Link, Typography } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/RenderTextField';
import Visibility from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

class SignIn extends Component {

  render() {
    const { 
      onRecoverPassword, 
      handleSubmit, 
      submitActions, 
      alert,
      handleSession
    } = this.props;

    return (
      <form onSubmit={ handleSubmit(submitActions) }>
        <Grid 
          className={ `${ isMobile ? 'p-5' : '' } animate__animated animate__backInUp` } 
          container 
          spacing={ 1 }
        >
          <Grid item md={ 12 } xs={ 10 } >
            <Typography variant="h4" color="primary">Iniciar Sesión</Typography>
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field 
              label="Correo"
              name="email"
              required={ true}
              component={ RenderTextField }
            />
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field
              label="Contraseña"
              name="password"
              type="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={ RenderTextField } 
            />
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Checkbox
              color="primary"
              onChange={(ev) => handleSession(ev)}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
            />
            <small className="validated-text">Mantener mi sesión abierta</small>
          </Grid>

          {
            alert.message &&
              <Grid item md={ 12 } xs={ 12 }>
                <Alert variant="filled" severity={`${alert.type}`}>
                  {alert.message}
                </Alert>
              </Grid>
          }

          <Grid item md={ 12 } xs={ 12 }>
            <Button 
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              style={{ borderRadius: 36 }}
            >
              Ingresar
            </Button >
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <small className="validated-text text-center">
              ¿Olvidaste tu contraseña?&nbsp; 
              <Link
                className="pointer"
                onClick={ () => onRecoverPassword() }
                underline='always'
              >
                Reestablécela aquí.
              </Link>
            </small>
          </Grid>

          <Grid item className={'animate__animated animate__backInUp mt-5 pt-3 mb-3'} xs={12}>
            <Grid container justifyContent="center" item sm={12}>
              <Typography style={{color: '#989EB4'}}>Powered by</Typography>
            </Grid>
            <Grid container justifyContent="center" item sm={12} alignItems="flex-end">
              <img src={logoAxeleratum} alt="Axeleratum" className="icon-powered-by" />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

}

const validate = (values) => {
  const errors = {
    email: '',
    password: ''
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (values.email) {
    if (values.email.length > 50) {
      errors.email = 'El correo electrónico no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }

    if (values.password.length < 10) {
      errors.password = 'La contraseña no puede ser menor a 10 caracteres';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'SignIn',
  validate
})(SignIn);
