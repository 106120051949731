import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import './home.css';
import 'axeleratum-sgc-frontend-library/styles/index.css';

import { authActions, alertActions } from '../../core/actions';

import HomeIcon from '@material-ui/icons/Home';
import DashboardRouting from '../../core/routes/dashboard-routing.routes';

import { Alert } from 'axeleratum-sgc-frontend-library';
// import StackIcon from '../icons/StackIcon';
import AdminIcon from '../icons/AdminIcon';
import TopBar from '../main-menu/TopBar';
import { useHistory } from 'react-router';
import { UserService } from '../../core/services/user.service';

const menuItems = [
  {
    text: 'Inicio',
    name: 'dashboard',
    icon: HomeIcon,
    link: '/home',
  },
  {
    text: 'Administración',
    name: 'admin',
    icon: AdminIcon,
    link: '/team',
  },
/*  {
    text: 'Bóveda',
    name: 'boveda',
    icon: VaultIcon,
    link: '/vault',
  },*/
  // {
  //   text: 'Plantillas',
  //   name: 'templates',
  //   icon: StackIcon,
  //   link: '/templates',
  // }
];

const Home = (props) => {
  const { alert, logout, closeAlert } = props;
  const [currentUser, setCurrentUser] = useState();
  const classes = useStyles();
  const history = useHistory();
  const userService = UserService.getInstance()

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  useEffect(() => {
  const userId = sessionStorage.getItem('userId');
  userService.getUser(userId).then((user) => {
    setCurrentUser(user);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredMenuItems = currentUser?.isProvider
      ? [menuItems[0]]
      : menuItems;
  return (
    <div className={'home'}>
      <TopBar
        menuItems={filteredMenuItems}
        onLogout={handleLogout}
        userName={currentUser?.name}
      />
      <Container
        className={classes.mainContainer}
        maxWidth='lg'
      >
        <Alert
          open={alert.message ? true : false}
          title={alert.message}
          type={alert.type}
          onConfirm={closeAlert}
        />
        <DashboardRouting {...props} />
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: '20px',
    paddingLeft: '50px',
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
    paddingRight: '10px',
    }
  },
}));

const mapStateToProps = (state) => {
  return {
    keep_active_session: state.authReducer.keep_active_session,
    loggedIn: state.authReducer.loggedIn,
    alert: state.alertReducer,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  logout: authActions.logout,
  refreshToken: authActions.refreshToken,
  closeAlert: alertActions.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
