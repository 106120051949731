import React, { FC, Fragment } from 'react';
import { Button, makeStyles, TooltipProps } from '@material-ui/core';
import StatusTooltip from './StatusTooltip';

interface StatusButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  bgColor?: 'warning' | 'success' | 'error' | 'info';
  hasTooltip?: boolean;
  tooltipTitle?: string;
}

const StatusButton: FC<StatusButtonProps> = (props) => {
  const classes = useStyles(props);
  const RenderRoot = props.hasTooltip && props.tooltipTitle ? StatusTooltip : Fragment;
  const rootProps: (Omit<TooltipProps, 'children'> & { textcolor?: 'warning' | 'success' | 'error' | 'info' }) | Record<string, never> =
    props.hasTooltip && props.tooltipTitle ? { title: props.tooltipTitle ?? '', textcolor: props.bgColor, placement: 'bottom-start' } : {};
  return (
    <RenderRoot {...(rootProps as any)}>
      <Button
        classes={{ containedSizeSmall: classes.buttonLabel, root: classes.rootButton }}
        disableElevation
        fullWidth={props.fullWidth}
        startIcon={props.startIcon}
        onClick={props.onClick}
        size="small"
        variant="contained"
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    </RenderRoot>
  );
};

const useStyles = makeStyles((theme) => ({
  rootButton: {
    textTransform: 'unset',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: ({ bgColor }: StatusButtonProps) => (bgColor ? theme.palette[bgColor].main : '#CDCECE'),
    '&:hover': {
      backgroundColor: ({ bgColor }: StatusButtonProps) => (bgColor ? theme.palette[bgColor].dark : '#CDCECE')
    }
  },
  buttonLabel: {
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    padding: '0 10px'
  }
}));

export default StatusButton;
