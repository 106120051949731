import { axiosRequestIpfsBlockchain, authHeader } from '../../configs/axios.config';

export class BlockchainService {
  private static instance: BlockchainService;

  private constructor() {};

  public static getInstance(): BlockchainService {
    if (!BlockchainService.instance) {
        BlockchainService.instance = new BlockchainService();
    }
    return BlockchainService.instance;
  }

  async createDocumentKaleidoContract(reqData: CreateContractReqBody) {
    try {
      await axiosRequestIpfsBlockchain.post(`/v1/blockchain`, reqData, {headers: authHeader()});
    } catch (error) {
      throw error;
    }
  }

  async addDocumentHistoryKaliedoContract(reqData: any) {
    try {
      const { data } = await axiosRequestIpfsBlockchain.put(`/v1/blockchain/history`, reqData, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentInitOwner(documentId: string) {
    try {
      const data = await axiosRequestIpfsBlockchain.get(`/v1/blockchain/`+documentId, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHistoryDocument(documentId: string) {
    try {
      const { data } = await axiosRequestIpfsBlockchain.get<ItemHistoryResponse>(`/v1/blockchain/${documentId}/history`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
}



