import axios from 'axios';

export const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`
});

export const axiosRequestOption = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/rest`
});

export const axiosRequestFiles = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`
});

export const axiosIpfsRequest = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}`
});

export function authHeader() {
  // return authorization header with jwt token
  let token = sessionStorage.access_token;

  if (token) {
    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
}

export function authIpfsHeader(){
  let token = sessionStorage.access_token_ipfs;

  if (token) {
    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
}

export function jsonTypeHeader(){
  return {
    'Content-Type': 'application/json'
  }
}

export const axiosBusinessRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosCompanyRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/rest`,
  headers: authHeader()
});

export const axiosProviderRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_PROVIDER}/rest`,
  headers: authHeader()
});

export const axiosRequestBlockChain = axios.create({
  baseURL: `${process.env.REACT_APP_API_BLOCKCHAIN_URL}/api`
});

export const axiosRequestIpfsBlockchain = axios.create({
  baseURL: `${process.env.REACT_APP_IPFS_BLOCKCHAIN_URL}/api`,
  headers: authHeader()
});

export const axiosCompanyRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/api/v1`,
  headers: authHeader()
});

export const axiosMaterialityRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_NTP}/api/v1`,
  headers: authHeader()
});

export const axiosNtpRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_NTP}/api/v1`,
  headers: authHeader()
});