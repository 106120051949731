import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useReduxFormStyles } from '../inputs/useReduxFormStyles';

interface DropzoneFilesProps {
  label: string;
  file: File | null;
  onChange: (files: File) => void;
  accept?: string | string[];
  labelBox?: string;
  height?: string;
  hideButton?: boolean;
}

function DropzoneFiles(props: DropzoneFilesProps) {
  const classes = useStyles();
  const fieldClasses = useReduxFormStyles();

  return (
    <Box display="flex" flexDirection="column">
      {props.label && (
        <label className={fieldClasses.fieldLabel} style={{ marginBottom: 10, marginTop: 10 }}>
          {props.label}
        </label>
      )}
      <Dropzone onDrop={(filesToUpload) => props.onChange(filesToUpload[0])} accept={props.accept}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={classes.dropzone}
              style={{'height': props.height || '138px'}}>
              <input type="file" {...getInputProps()} />
              {!props.file ? (
                <>
                  <Typography variant="subtitle1" color="primary"
                    style={{ fontWeight: 'normal', textAlign: 'center' }}>
                    {props?.labelBox || 'Arrastra el documento aquí para subirlo'}
                  </Typography>
                  {!props.hideButton && (
                    <>
                      <Typography variant="body1" color="primary" style={{ fontWeight: 'bold' }}>
                        O
                      </Typography>
                      <Button variant="contained" color="primary">
                        Buscar en tus archivos
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <p>{props.file?.name}</p>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    cursor: 'pointer',
    backgroundColor: '#F4F4F4',
    height: '138px',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:focus': {
      outline: 'none'
    },
    padding: "10px"
  },
  alertMessage: {
    marginLeft: '27px',
    marginTop: '28px',
    marginRight: '28px'
  }
}));

export default DropzoneFiles;
