import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AdminIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path d="M5.01306 10.4238C4.46787 10.4238 4.02429 10.8674 4.02429 11.4126C4.02429 11.9578 4.46787 12.4015 5.01306 12.4015C5.5584 12.4015 6.00197 11.9578 6.00197 11.4126C6.00197 10.8674 5.5584 10.4238 5.01306 10.4238Z"/>
      <path d="M6.57422 6.82031H11.4258V0.527344C11.4258 0.236069 11.1897 0 10.8984 0H7.10156C6.81029 0 6.57422 0.236069 6.57422 0.527344V6.82031Z"/>
      <path d="M17.4727 4.71094H12.4805V6.82031H12.8672C13.1585 6.82031 13.3945 7.05638 13.3945 7.34766C13.3945 7.63893 13.1585 7.875 12.8672 7.875H5.13281C4.84154 7.875 4.60547 7.63893 4.60547 7.34766C4.60547 7.05638 4.84154 6.82031 5.13281 6.82031H5.51953V4.71094H0.527344C0.236069 4.71094 0 4.94701 0 5.23828V17.4727C0 17.7639 0.236069 18 0.527344 18H17.4727C17.7639 18 18 17.7639 18 17.4727V5.23828C18 4.94701 17.7639 4.71094 17.4727 4.71094ZM7.51561 15.3173C7.47331 15.3277 7.43088 15.3327 7.38913 15.3327C7.1521 15.3327 6.93677 15.1717 6.87758 14.9312C6.66582 14.0709 5.89911 13.4699 5.01306 13.4699C4.12715 13.4699 3.36058 14.0707 3.14882 14.931C3.07919 15.2139 2.79355 15.3866 2.51065 15.317C2.22789 15.2474 2.05499 14.9617 2.12462 14.679C2.32416 13.8683 2.84175 13.2064 3.52538 12.8123C3.18095 12.4463 2.9696 11.9537 2.9696 11.4127C2.9696 10.2858 3.88641 9.36914 5.0132 9.36914C6.13998 9.36914 7.05666 10.2859 7.05666 11.4127C7.05666 11.9537 6.84531 12.4463 6.50089 12.8123C7.18465 13.2065 7.70224 13.8685 7.90164 14.6792C7.97127 14.962 7.79837 15.2478 7.51561 15.3173ZM12.5859 15.2578H10.0898C9.79857 15.2578 9.5625 15.0217 9.5625 14.7305C9.5625 14.4392 9.79857 14.2031 10.0898 14.2031H12.5859C12.8772 14.2031 13.1133 14.4392 13.1133 14.7305C13.1133 15.0217 12.8772 15.2578 12.5859 15.2578ZM15.3984 13.1484H10.0853C9.79404 13.1484 9.55797 12.9124 9.55797 12.6211C9.55797 12.3298 9.79404 12.0938 10.0853 12.0938H15.3984C15.6897 12.0938 15.9258 12.3298 15.9258 12.6211C15.9258 12.9124 15.6897 13.1484 15.3984 13.1484ZM15.3984 11.0391H10.0941C9.80283 11.0391 9.56676 10.803 9.56676 10.5117C9.56676 10.2204 9.80283 9.98438 10.0941 9.98438H15.3984C15.6897 9.98438 15.9258 10.2204 15.9258 10.5117C15.9258 10.803 15.6897 11.0391 15.3984 11.0391Z"/>
    </SvgIcon>
  )
}

export default AdminIcon;