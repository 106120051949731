import { createTheme } from '@material-ui/core/styles';

export const normal = createTheme({
  palette: {
    primary: { main: '#00709E' },
    secondary: { main: '#DCE7EB' },
    success: { main: '#57B894' },
    warning: { light: '#F9C372', main: '#FAAA60' },
    error: { main: '#FF4E3A' },
    label: { main: 'rgb(192, 204, 230)' }
  },
  itemMenu: {
    primary: {
      main: 'rgba(247, 250, 255, 0.4)'
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    h2: { fontSize: '3rem', fontWeight: 'bold'},
    h3: { fontSize: '1.75rem', fontWeight: 'bold'},
    h4:{ fontSize: '1.5rem', fontWeight: 'bold' },
    h5: { fontSize: '1.375rem', fontWeight: 'bold' },
    h6: { fontSize: '1.25rem', fontWeight: 'bold' },
    subtitle1: { fontSize: '1.125rem', fontWeight: 'bold', lineHeight: '24px' },
    subtitle2: { fontSize: '1rem', fontWeight: 'bold' },
    button:{ fontSize: '0.875rem', fontWeight: 'bold', lineHeight: '20px' },
    body1: { fontSize: '0.875rem', lineHeight: '20px' },
    caption: { fontSize: '0.75rem', lineHeight: '16px' }
  },
  //Override default props for some components
  props: {
    MuiSwitch: {
      color: "primary"
    },
    MuiSelect: {
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        getContentAnchorEl: null,
        MenuListProps: {
          disablePadding: true
        }
      }
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      getContentAnchorEl: null,
    }
  },
});

normal.overrides = {
  MuiAppBar: {
    colorPrimary: {
      color: `${normal.palette.primary.contrastText} !important`,
      backgroundColor: `${normal.palette.primary.main} !important`
    }
  },
  MuiCheckbox: {root: {color: '#00709E'}},
  MuiSelect: {select: {'&:focus': {backgroundColor: 'unset'}}},
  MuiSwitch: {
    root: {
      height: 40
    },
    colorPrimary: {
      '&$checked':{
        color: 'white',
      },
      '&$checked + $track': {
        opacity: 1
      }
    },
    checked: {}
  },
  MuiButton: {
    root: {
      textTransform: 'capitalize',
      borderRadius: 10,
    },
    contained:{
      boxShadow: 'unset'
    },
    outlinedPrimary: {
      border: `1px solid ${normal.palette.primary.main}`,
      backgroundColor: 'white',
      '&:hover': {
        border: `1px solid ${normal.palette.primary.dark}`,
      }
    },
    outlinedSecondary: {
      color: `${normal.palette.secondary.main} !important`
    },
     containedSecondary: {
      color: normal.palette.primary.main
     }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 36,
      backgroundColor: 'white',
      '&:hover': {
        '& $notchedOutline': {
          borderColor: normal.palette.primary.light,
          borderWidth: 2
        }
      },
    },
    notchedOutline: {
      borderColor: normal.palette.primary.main,
    }
  },
  MuiBackdrop: {
    root:{
      backgroundColor: 'rgba(0, 112, 158, 0.8)'
    }
  },
  MuiDialog: {paper: { borderRadius: 12 }},
  MuiDialogActions: {root: { padding: 24 }},
  MuiBreadcrumbs: {li: {fontWeight: 700}},
  MuiMenuItem: {
    root: {
      color: normal.palette.primary.main,
      paddingTop: 12,
      paddingBottom: 12
    }
  },
  MuiMenu: {
    paper: {
      borderRadius: 0,
      borderBottomRightRadius: 20
    }
  },
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: 'rgba(0, 112, 158, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(0, 112, 158, 0.2)'
        }
      }
    },
    gutters: {
      paddingLeft: '16px !important',
      paddingRight: '16px !important'
    },
    button: {
      '&:hover':{
        backgroundColor: 'rgba(0, 112, 158, 0.1)'
      }
    },
    selected: {},
    secondaryAction: {
      paddingRight: '48px !important'
    }
  }
}