import React from 'react';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import './general.scss';

const BlockerLoadPage = () => {
    
    const classes = useStyles();

    return (
        <Grid className="blocker">
            <Grid className="popup">
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                    >
                        <SaveTwoToneIcon size={100} className="App-logo"/>
                    </Fab>
                    <CircularProgress size={68} className={classes.fabProgress} />
                </div>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[300],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    }
}));


export default BlockerLoadPage;