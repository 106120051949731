import React, { useContext, useEffect, useState } from 'react';
//@ts-ignore
import { Alert } from 'axeleratum-sgc-frontend-library';
import { Grid } from '@material-ui/core';
import AlertInfo from "@material-ui/lab/Alert";


import { RolesHttp } from '../../../core/http/roles.http';
import { PermissionsHttp } from '../../../core/http/permissions.http';

import FormRoles from './FormRoles';
import { useHandleDialog } from '../../../core/hooks/useHandleDialog';
import { ManagementContext } from '../../../core/contexts/management-context/ManagementContext';
import ManagementTable from '../management-table/ManagementTable';

const Roles = () => {
  const { getRoles, roles, loadingRoles } = useContext(ManagementContext);
  const [alertStatus, setAlertStatus] = useState(false);
  const [roleSelected, setRoleSelected] = useState<Role | null>(null);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [typeForm, setTypeForm] = useState('edit');
  const [Dialog, handleShowDialog] = useHandleDialog();

  const httpRoles = new RolesHttp();
  const httpPermissions = new PermissionsHttp();

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPermissions = () => {
    httpPermissions.getPermissionsGroup((data: any) => {
      const dataMapped = data.map((permission: any) => {
        return {
          label: permission.name,
          value: permission.id,
          permissions: permission.permissions
          //description: permission.description
        }
      });
      setPermissions(dataMapped);
    }, (error: any) => console.error(error))
  }

  const handleSaveRoles = (formData: any) => {
    if (typeForm === 'create') {
      httpRoles.createRol(formData, (data: any) => {
        setAlertStatus(true);
        setRoleSelected({
          label: data.name,
          value: data.id,
        });
        getRoles();
      }, (error: any) => {
        console.error(error)
      })
    } else {
      httpRoles.editRol(formData, roleSelected?.value, (data: any) => {
        getRoles();
        setAlertStatus(true);
      }, (error: any) => {
        console.error(error)
      })
    }
  }

  const handleAddRow = () => {
    setRoleSelected(null);
    setTypeForm('create');
    handleShowDialog();
  }

  const handleSelectElement = (element: Role) => {
    setRoleSelected(element);
    setTypeForm('edit');
    handleShowDialog();
  }

  return (
    <>
      <ManagementTable
        tableItems={roles.filter((role) => role.label === 'Super Admin')}
        header={[
          {
            key: 'label',
            title: 'Label'
          }
        ]}
        addButtonProps={{ label: 'Nuevo Rol', onAdd: handleAddRow }}
        menuItems={[{
          label: 'Editar',
          action: handleSelectElement
        }]}
      />
      { !loadingRoles && roles.length > 0 &&
        (
          <Dialog>
            <FormRoles
              submitActions={(form: any) => { handleSaveRoles(form) }}
              permissions={permissions}
              roleSelected={roleSelected}
            />
          </Dialog>
        )
      }

      { (loadingRoles) && (
        <Grid container style={{ justifyContent: 'center', height: '100%' }}>
          <AlertInfo severity="info" >Cargando roles...</AlertInfo>
        </Grid>
      )}

      <Alert
        open={alertStatus}
        title="El rol ha sido guardado correctamente"
        type="success"
        onConfirm={() => setAlertStatus(false)}
      />
    </>
  );
}

export default Roles;