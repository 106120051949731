import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'

import StagesTableRow from './StagesTableRow'

export interface StagesTableProps {
  title: string;
  deliverables: Deliverable[];
  onOpenDocument?: (id: string) => void;
}

const StagesTable = (props: StagesTableProps) => {

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">{props.title}</Typography>
      </Grid>
      <Grid item xs={12} >
        {props.deliverables.length > 0 ? (<TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre del entregable</TableCell>
                <TableCell>Nombre del documento</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Mes corespondiente</TableCell>
                <TableCell>Fecha subida documento</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell>Fecha de revision</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.deliverables.map((deliverable, i) => {
                return <StagesTableRow key={i} {...deliverable} onOpenDocument={props.onOpenDocument} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>) : <Typography variant="subtitle2">Aun no hay documentos para esta etapa</Typography>}
      </Grid>
    </>
  )
}

export default StagesTable