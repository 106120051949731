import { useHistory } from "react-router";

export const useHandleBackHistory = () => {
  const history = useHistory();

  const handleClickBack = () => {
    history.go(-1);
  };

  return handleClickBack;

};