import { List, ListSubheader, makeStyles, MenuItem, TablePagination } from '@material-ui/core';
import React from 'react';
import DashedButton from '../../general/DashedButton';
import ManagementRow from './ManagementRow';
import AddIcon from '@material-ui/icons/Add';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

interface ManagementTableProps<TableItem, ItemValue> {
  tableItemsValues?: ItemValue[];

  currentPage?: number;
  /** Item or rows info that will be shown by the table. */
  tableItems: ((values: ItemValue[]) => TableItem[]) | TableItem[];
  /** Table header data. */
  header: {
    key: keyof TableItem;
    title: string;
  }[];

  /** Props for the add button at the bottom of the table */
  addButtonProps?: {
    /** Text inside the add button. */
    label: string;
    /** Action fired when the user clicks add button.  */
    onAdd?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

  }
  menuItems?: MenuItem<unknown extends ItemValue ? TableItem : ItemValue>[];
  count?: number;
  page?: number;
  pageSize?: number;
  onChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangePageSize?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}


type ManagementTableType = <TableItem, ItemValue>(props: ManagementTableProps<TableItem, ItemValue>) => JSX.Element;
/** Table that shows the items of each section in Management screen. */
const ManagementTable: ManagementTableType = (props) => {

  const renderRow = () => {
    if (typeof props.tableItems === 'function' && props.tableItemsValues) {
      return props.tableItems(props.tableItemsValues).map((item, i) => {
        const value = props.tableItemsValues![i];
        return (
          <ManagementRow
            key={i}
            currentPage={props.currentPage}
            itemValue={value}
            tableItem={item}
            header={props.header}
            menuItems={props.menuItems as MenuItem<typeof value>[]}
          />
        );
      })
    }
    else if (Array.isArray(props.tableItems))
      return props.tableItems.map((item, i) => {

        return (
          <ManagementRow
            key={i}
            currentPage={props.currentPage}
            itemValue={item}
            tableItem={item}
            header={props.header}
            menuItems={props.menuItems as MenuItem<typeof item>[]}
          />
        );
      });
  }


  const classes = useStyles();
  return (
    <div>
      {props.addButtonProps && (
        <DashedButton
          startIcon={<AddIcon />}
          fullWidth
          size="large"
          onClick={props.addButtonProps.onAdd}
        >
          {props.addButtonProps.label}
        </DashedButton>
      )}
      <List
        subheader={
          <ListSubheader className={classes.header}>
            {props.header.map((column) => <span key={column.key.toString()} className={classes.headerName}>{column.title}</span>)}
          </ListSubheader>
        }
      >
        {renderRow()}

      </List>
      {props.count !== undefined && props.page !== undefined && props.onChangePage && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: props.count }]}
          count={props.count}
          page={props.page}
          onPageChange={props.onChangePage}
          rowsPerPage={props.pageSize ?? 10}
          onRowsPerPageChange={props.onChangePageSize}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </div>

  )
}

const useStyles = makeStyles({
  header: { display: 'flex', lineHeight: 'unset' },
  headerName: { flex: 0.673 }
});


export default ManagementTable;