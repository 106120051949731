import axios from 'axios';


export const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api`
});

export class AuthService {


  login = (username, password) => {
    return axios.post( `${process.env.REACT_APP_API_BASE_URL}/oauth/token?username=${username}&password=${password}&grant_type=password`, {
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  signUp = (user) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/activate-user`, 
      {
        email: user.email,
        password: user.password,
        completeName: user.completeName
      },
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
        }
      }
    )
  }

  sendEmailPassword = (email) => {
    return axios.put( `${process.env.REACT_APP_API_BASE_URL}/public/send-email-restore-password`,
    {
      email: email.email
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  restorePassword = (uuid, password) => {
    return axios.put( `${process.env.REACT_APP_API_BASE_URL}/public/restore-password/${uuid}`,
    {
      password: password
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  refreshToken = async (token) => {
    return await axios.post( `${process.env.REACT_APP_API_BASE_URL}/oauth/token?grant_type=token&refresh_token=${token}`, {
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }
// NEW APIS LOGIN GET 
  async ipfsToken(email, password) { 
  
    try {
      const { data } = await axios.post( `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/auth/login`, 
      {
        email,
        password
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })  
      return data;
    } catch (error) {
      throw error;
    }
  }

  async newRefreshToken(token) { //REACT_APP_BASE_SECURITY_KEYCLOACK
  
    try {
      const { data } = await axios.post( `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/auth/refresh`, 
      {
        token:token

      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })  
      return data;
    } catch (error) {
      throw error;
    }
  }

}
