import React, { Component } from 'react';
import { Grid, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PropTypes from 'prop-types';
import DoneIcon from "@material-ui/icons/Done";

class SafetyIndicator extends  Component {

  calculateSafetyLevel() {
    const { password } = this.props;
    let level = 0;

    if (password.length >= 8) {
      level += 25;
    }

    if (password.length >= 10 ) {
      level += 25;
    }

    if (/[A-Z]+/.test(password) && password.length >= 15) {
      level += 25;
    }

    if (/[^a-zA-Z0-9]+/.test(password) && password.length >= 20) {
      level += 25;
    }

    return level;
  }

  getLevelData(level) {

    if (level === 25) {
      return { levelColor: 'safety-level-color-low', levelLabel: 'Bajo' };
    }

    if (level === 50 ) {
      return { levelColor: 'safety-level-color-medium', levelLabel: 'Medio' };
    }

    if (level === 75) {
      return { levelColor: 'safety-level-color-tall', levelLabel: 'Alto' };
    }

    if (level === 100) {
      return { levelColor: 'safety-level-color-high', levelLabel: 'Muy alto' };
    }

    return '';
  }

  render() {
    const { className } = this.props;
    const level = this.calculateSafetyLevel();
    const { levelColor, levelLabel } = this.getLevelData(level);

    return (
      <Grid className={ `mt-3 ${className}` } container>
        <Grid item md={ 12 }>
          <div className="text-right">
            <span className={ `validated-text ${levelColor}` }>{ levelLabel }</span>
          </div>

          <LinearProgress
            className={ `progress-bar ${levelColor}` }
            variant="determinate"
            value={ level }
          />
        </Grid>

        <Grid className="mt-3" item md={ 12 }>
          <span className="small-title">Te recomendamos:</span>

          <List>
            <ListItem className="NewPassword-listItem">
              <ListItemIcon>
                <DoneIcon className="strong-password" />
              </ListItemIcon>

              <ListItemText
                primary="La contraseña debe tener mínimo 10 caracteres y al máximo 20 caracteres"
                className="small-text strong-password validated-text"
              />
            </ListItem>

            <ListItem className="NewPassword-listItem">
              <ListItemIcon>
                <DoneIcon className="strong-password" />
              </ListItemIcon>

              <ListItemText
                primary="Distingue mayúsculas y minúsculas"
                className="small-text strong-password validated-text"
              />
            </ListItem>

            <ListItem className="NewPassword-listItem">
              <ListItemIcon>
                <DoneIcon className="strong-password" />
              </ListItemIcon>

              <ListItemText
                primary="No deberá contener nombres propios"
                className="small-text strong-password validated-text"
              />
            </ListItem>

            <ListItem className="NewPassword-listItem">
              <ListItemIcon>
                <DoneIcon className="strong-password" />
              </ListItemIcon>

              <ListItemText
                primary="Debe contener algún carácter especial (+*%&/$#-_)"
                className="small-text strong-password validated-text"
              />
            </ListItem>

            <ListItem className="NewPassword-listItem">
              <ListItemIcon>
                <DoneIcon className="strong-password" />
              </ListItemIcon>

              <ListItemText
                primary="Alguno de los caracteres debe ser mayúscula"
                className="small-text strong-password validated-text"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    )
  }
}

SafetyIndicator.propTypes = {
  password: PropTypes.string
}

export default SafetyIndicator;
