import React from 'react';
import { Grid } from "@material-ui/core";
import logoAxeleratum from '../../assets/img/axeleratum-white-logo.svg';
import logoBakertilly from '../../assets/img/bakertilly.svg';
import necesidad from '../../assets/img/necesidad.png';
import provecho from '../../assets/img/provecho.png';
import trazabilidad from '../../assets/img/trazabilidad.png';
import CarouselInstructions from './CarouselInstructions';


const carouselData = [
  {
    img: necesidad,
    subtitle: 'Necesidad',
    description:'Proceso de decisión que llevó a la adquisición del bien o servicio; así como, la diligencia observada en la contratación.'
  },{
    img: trazabilidad,
    subtitle: 'Trazabilidad',
    description: 'Derechos y obligaciones acordados.'
  },{
    img: provecho,
    subtitle: 'Provecho',
    description: 'Consecuencia o efecto que tuvieron las transacciones.'
  }
]

const Instructions = (props) => {

  return  (
    <CarouselInstructions 
      end={
        () => props.history.replace('/dashboard')
      }
      data={carouselData}
      logo={
        <Grid container justifyContent="center">
          <img src={logoAxeleratum} alt="Logo Axeleratum" />
          <img src={logoBakertilly} alt="Logo Baker Tilly" />
        </Grid>
      }/>
  )
}

export default Instructions
