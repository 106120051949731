import { Button, Tooltip, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Cell } from "./Cell.interface";
import { useFormik } from "formik";
import DropzoneFiles from "../../../inputs/DropzoneFiles";
import { toBase64, truncateText } from "../../../../core/utils/utils";

const OrkestaMediaCells: React.FC = () => {
    /** */
    const $class = useStyles();
    const [ cells, setCells ] = useState<Cell[]>( [] );

    /** Define form */
    const formik = useFormik<any>({
        initialValues: {
            cellName: '',
            imageFile: null
        },
        onSubmit: fillCells
    });

    async function fillCells() {
        /** */
        const tmpCells: Cell[] = [];
        const bs64Cell = await toBase64( formik.values.imageFile );

        tmpCells.push({
            image: bs64Cell,
            name: formik.values.cellName,
            order: 1,
            isEdit: false
        });

        setCells( cells.concat( tmpCells ) );

        formik.resetForm();
    }

    function removeCell( index: number ) {
        /** */
        const filteredCells = cells.filter( ( item, idx ) => idx !== index );

        setCells( filteredCells );
    }

    return (
        <>
            <div className={$class.wrapper}>
                {cells.length > 0 && (
                    <ul className={$class.cells}>
                        {cells.map( ( cell: Cell, index: number ) => (
                            <li key={index} className={$class.cell}>
                                <div className={$class.cellImage}>
                                    <img src={cell.image} alt={cell.name}
                                        style={{ height: '70px' }}
                                    />
                                </div>
                                <div className={`${$class.cellName} p-2`}>
                                    {cell.name.length > 25 ? (
                                        <Tooltip title={cell.name}>
                                            <strong>{truncateText( cell.name, 25 )}</strong>
                                        </Tooltip>
                                    ) : (
                                        <strong>{cell.name}</strong>
                                    )}
                                </div>
                                <Button type="button"
                                    onClick={ () => removeCell( index ) }>
                                    Remover
                                </Button>
                            </li>
                        ))}
                    </ul>
                )}
                <form className={$class.cell} onSubmit={formik.handleSubmit}>
                    <div className={$class.cellImage}>
                        <DropzoneFiles
                            accept="image/*"
                            labelBox="Arrastra la imagen aquí para subirla"
                            label=""
                            file={formik.values.imageFile}
                            height="70px"
                            hideButton={true}
                            onChange={ ( file: any ) => {
                                formik.setFieldValue( 'imageFile', file );
                            }}
                        />
                    </div>
                    <div className={$class.cellName}>
                        <input type="text" className={$class.cellInputName}
                            name="cellName"
                            value={formik.values.cellName}
                            placeholder="Ingresa el nombre de la célula"
                            onChange={formik.handleChange}
                        />
                    </div>
                    <Button type="submit"
                        color="primary"
                        disabled={!formik.values.cellName || !formik.values.imageFile}>
                        Agregar
                    </Button>
                </form>
            </div>
        </>
    );
}

const useStyles = makeStyles( theme => ({
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem"
    },
    cells: {
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem"
    },
    cell: {
        width: "14rem",
        height: "10rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "6px",
        border: "1px solid rgba(0,0,0,0.2)"
    },
    cellImage: {
        backgroundColor: "#C3DEFD",
        borderTopRightRadius: "6px",
        borderTopLeftRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem"
    },
    cellName: {
        width: "100%"
    },
    cellInputName: {
        width: "100%",
        border: "none",
        padding: "0.5rem 1rem"
    }
}));

export default OrkestaMediaCells;