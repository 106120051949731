import { useState } from "react";
import { AlertProps } from "../../../components/general/Alert";
import { useHandleCompanies } from "./hooks/useHandleCompanies";
import { useHandleRoles } from "./hooks/useHandleRoles";
import { useHandleUsers } from "./hooks/useHandleUsers";
import { ManagementContext } from "./ManagementContext";


/**
 * HOC that receives a Component and wraps it in the ManagementContext provider.
 * @param Component Any React component
 * @returns Component able to use VaultContetx inside itself.
 */
 export const withManagementContext = (Component: any) => {
  return () => {
    const [infoAlert, setInfoAlert] = useState<AlertProps>({
      open: false,
      title: ''
    });

    const closeInfoAlert = () => {
      setInfoAlert({
        open: false,
        title: ''
      })
    }

    const setAlert = (dialogInfo: Omit<AlertProps, 'onCancel'> & { addCancelButton?: boolean, holdOnConfirm?: boolean }) => {
      setInfoAlert({
        ...dialogInfo,
        onCancel: dialogInfo.addCancelButton ? closeInfoAlert : undefined,
        onConfirm:
          (() => {
            !!dialogInfo.onConfirm && dialogInfo.onConfirm();
            !dialogInfo.holdOnConfirm && closeInfoAlert();
          }) ?? closeInfoAlert
      });
    };

    const rolesHandler = useHandleRoles();
    const companiesHandler = useHandleCompanies();
    const usersHandler = useHandleUsers(setAlert);

    return (
      <ManagementContext.Provider value={{
        ...rolesHandler,
        ...companiesHandler,
        ...usersHandler,
        infoAlert,
        setAlert
      }}>
        <Component />
      </ManagementContext.Provider>
    );
  }
}