import { axiosIpfsRequest, authHeader } from "../../configs/axios.config";
import { GenericResponse } from "../../types/global";

export class UserService {
  private static instance: UserService;

  private constructor() { };

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  async listUsers() {
    try {
      const { data } = await axiosIpfsRequest.get(`api/v1/users`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getUser(userId: string) {
    try {
      const { data } = await axiosIpfsRequest.get<GetUserResponse>(`api/v1/users/${userId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createUser(body: CreateUserRequestBody) {
    try {
      const { data } = await axiosIpfsRequest.post<GetUserResponse>(`api/v1/users`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async editUser(body: EditUserRequestBody) {
    try {
      const { data } = await axiosIpfsRequest.put<GenericResponse<GetUserResponse>>(`api/v1/users`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async deleteUser(userId: string) {
    try {
      await axiosIpfsRequest.delete<GetUserResponse>(`api/v1/users/${userId}`, { headers: authHeader() });
    } catch (error) {
      throw error;
    }
  }
}