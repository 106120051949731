import React, { useState, useEffect } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import RenderTextField from '../../../core/redux-form-fields/RenderTextField';
import { List, ListItem, ListItemText, Divider, makeStyles, Grid, Button, ListItemSecondaryAction, Typography } from '@material-ui/core';
//@ts-ignore
import { CirclePicker } from 'react-color';

import { RolesHttp } from '../../../core/http/roles.http';
import PermissionGroupItem from './permission-group-item';
import { SwitchField } from './switch-field';

interface FormRolesProps {
  submitActions: (form: any) => void;
  roleSelected: Role | null;
  permissions: Permission[];
}


const FormRoles = ({ submitActions, permissions, roleSelected, initialize }: InjectedFormProps<{}, FormRolesProps> & FormRolesProps) => {
  const classes = useStyles()
  const [colorSelected, setColorSelected] = useState('')
  const [permissionsSelected, setPermissionsSelected] = useState([])
  const [checkAllCompanies, setAllCompanies] = useState(false)

  useEffect(() => {

    if (roleSelected) {
      const httpRoles = new RolesHttp()

      httpRoles.findRolById({ id: roleSelected.value }, (data: any) => {
        initialize({
          name: data.name
        });
        setColorSelected(data.color)
        setAllCompanies(data.allCompanies)
        setPermissionsSelected(data.permissionGroups)
      }, console.log);
    } else {
      initialize({
        name: ''
      });
      setPermissionsSelected([])
      setAllCompanies(false)
      setColorSelected('#b0bec5')
    }

  }, [initialize, roleSelected])


  function handleSetColor(value: any) {
    setColorSelected(value.hex)
  }

  function handleBeforeSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    const switches = []
    //@ts-ignore
    const elements = evt.target.elements;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.type === 'checkbox') {
        if ((element.name !== 'permission-group' && element.name !== 'allCompanies') && element.checked) {
          switches.push({ id: element.name })
        }
      }
    }

    const newDataRole = {
      name: elements[1].value,
      permission: switches,
      color: colorSelected,
      allCompanies: elements[0].checked,
    }
    submitActions(newDataRole)
  }

  return (
    <form onSubmit={handleBeforeSubmit}>
      <List dense className={classes.root}>
        <ListItem classes={{ gutters: classes.gutters }} disabled>
          <ListItemText primary="Nombre" />
          <ListItemSecondaryAction>
            <Typography variant="body1" display='inline' color={'primary'} gutterBottom>Todas las empresas</Typography>
            <SwitchField
              id={`allCompanies`}
              checked={checkAllCompanies}
              name={`allCompanies`}
              onClick={(value: boolean) => { setAllCompanies(value) }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem className={classes.inputItem}>
          <Field
            id="name"
            name="name"
            required={true}
            label="Nombre del rol"
            component={RenderTextField}
          />
        </ListItem>

        <ListItem classes={{ gutters: classes.gutters }} disabled>
          <ListItemText primary="Color" />
        </ListItem>
        <ListItem className={classes.inputItem}>
          <Grid container>
            <Grid item md={3} xs={12} sm={12}>
              <div className={classes.currentColor} style={{ backgroundColor: colorSelected }}>
              </div>
            </Grid>

            <Grid item md={9} xs={12} sm={12}>
              <CirclePicker
                className={classes.colorPicker}
                colors={[
                  '#f44336', '#e91e63', '#9c27b0', '#673ab7',
                  '#3f51b5', '#2196f3', '#0276aa', '#03a9f4',
                  '#008394', '#00695f', '#357a38', '#b23c17',
                  '#b2102f', '#4615b2', '#00a152', '#14a37f',
                  '#b26500', '#b28900',
                ]}
                circleSize={40}
                onChangeComplete={(color: any) => handleSetColor(color)}
              />
            </Grid>

          </Grid>
        </ListItem>
        <Divider classes={{ root: classes.divider }} component="li" />

        <ListItem classes={{ gutters: classes.gutters }} disabled>
          <ListItemText primary="Permisos" />
        </ListItem>

        {
          permissions.map((permissionGroup, index) => {
            return (
              <PermissionGroupItem
                permissionGroup={permissionGroup}
                classes={classes}
                roleSelected={roleSelected}
                permissionsSelected={permissionsSelected}
                key={index}
              />
            )
          })
        }

      </List>
      <Button
        className="ml-2"
        type="submit"
        variant="contained"
        color="primary"
        classes={{ root: classes.buttonSave }}
      >
        Guardar
        </Button>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  gutters: {
    padding: '0'
  },
  inputItem: {
    padding: '0'
  },
  divider: {
    margin: '23px 0px'
  },
  colorPicker: {
    [theme.breakpoints.up('md')]: {
      width: '80% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important'
    },
    '& span': {
      '& div': {
        [theme.breakpoints.up('md')]: {
          width: '36px !important',
          height: '36px !important',
        },
        [theme.breakpoints.down('sm')]: {
          width: '16px !important',
          height: '16px !important',
        },
        '& span': {
          '& div': {
            borderRadius: 50
          }
        }
      }
    }
  },
  currentColor: {
    width: '106px',
    height: '82px',
    marginRight: '10px',
    borderRadius: '8px'
  },
  buttonSave: {
    float: 'right'
  },
  listItemText: {
    [theme.breakpoints.down('md')]: {
      marginRight: '48px'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '56px'
    }
  }
}
));


const validate = (values: {[key: string]: any}) => {
  const errors = {
    name: '',
  }

  if (!values.name) {
    errors.name = 'El nombre es requerido';
  }

  return errors;
}

export default reduxForm<{}, FormRolesProps>({
  form: 'FormRoles',
  validate
})(FormRoles);