import { axiosNtpRequestV1, authHeader } from '../../configs/axios.config';
import {
  AddDocReqBody,
  AddDocumentDashboardRes,
  ServiceData,
  ServiceDashboardRes,
  UpdateDocStatusReqBody,
} from "../../types/service-dashboard.interface";
        

export class NTPService {
  private static instance: NTPService;

  public static getInstance(): NTPService {
    if (!NTPService.instance) {
      NTPService.instance = new NTPService();
    }
    return NTPService.instance;
  }

  async addServiceRequirementDoc(serviceId: string, requirementUid: string, body: AddDocReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.post<AddDocumentDashboardRes>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        body,
        { headers: { ...authHeader(), 'Content-Type': 'application/json' }}
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteServiceRequirementDoc(serviceId: string, requirementUid: string, documentUid: string) {
    try {
      const { data } = await axiosNtpRequestV1.delete<ServiceDashboardRes<ServiceData>>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        {
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
          data: { uid: documentUid }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async updateServiceRequirementDocStatus(serviceId: string, requirementUid: string, body: UpdateDocStatusReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<ServiceData>>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        body,
        {
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServiceDashboardInfo(serviceId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<ServiceData>>(`/services/${serviceId}`, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }
}