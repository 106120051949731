import { createContext } from "react";
import { useHandleRoles } from "./hooks/useHandleRoles";
import { useHandleCompanies } from "./hooks/useHandleCompanies";
import { useHandleUsers } from "./hooks/useHandleUsers";
import { AlertProps } from "../../../components/general/Alert";
type ManagementContextType =
  ReturnType<typeof useHandleRoles>
  & ReturnType<typeof useHandleCompanies>
  & ReturnType<typeof useHandleUsers>
  & { infoAlert: AlertProps, setAlert: (dialogInfo: Omit<AlertProps, 'onCancel'> & {
    addCancelButton?: boolean,
    holdOnConfirm?: boolean
  }) => void }

export const ManagementContext = createContext<ManagementContextType>({
  companies: [],
  createUser: () => {return new Promise<boolean>((resolve, reject) => {})},
  filterCompanies: () => {},
  filterRoles: () => {},
  filterUsers: () => {},
  getCompanies: async () => {},
  getRoles: async () => {},
  listUsers: () => {},
  loadingCompanies: false,
  loadingRoles: false,
  loadingUsers: false,
  roles: [],
  users: [],
  infoAlert: {
    open: false,
    title: '',
    type: "success"
  },
  deleteUser: () => {},
  setAlert: () => {},
  editUser: () => {return new Promise<boolean>((resolve, reject) => {})},
});