import { Box, Button, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState, VFC } from 'react';
import { DocumentValidationStatus } from '../../types';
import RenderTextFieldV2 from '../inputs/inputs-v2/RenderTextFieldV2';

interface Props {
  name: string;
  documentStatus: string;
  onValidateStatus: (status: DocumentValidationStatus) => void;
  onReturn: () => void;
  isLoading?: boolean;
}

const WatchDocumentDrawer: VFC<Props> = ({ name, onReturn, documentStatus, onValidateStatus, isLoading }) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');

  const handleChangeComment: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setComment(e.target.value);
  };

  const handleAcceptDocument = () => {
    onValidateStatus({
      comments: comment,
      date: new Date().toISOString(),
      status: 'Aceptado'
    });
    onReturn();
  };

  const handleRejectDocument = () => {
    onValidateStatus({
      comments: comment,
      date: new Date().toISOString(),
      status: 'Rechazado'
    });
    onReturn();
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <IconButton edge="start" onClick={() => onReturn()} disabled={isLoading}>
          <ArrowBackIcon color="primary" fontSize="large" />
        </IconButton>
        <Typography className={classes.documentTitle}>{name}</Typography>
      </div>
      {documentStatus !== 'Aceptado' && documentStatus !== 'Rechazado' && (
        <>
          <RenderTextFieldV2 value={comment} onChange={handleChangeComment} multiline label="Comentario" placeholder="Agregar un comentario" />
          <Box display="flex" justifyContent="flex-start" marginTop="auto" marginBottom="24px" gridGap={12}>
            <Button variant="outlined" color="primary" onClick={handleRejectDocument} disabled={isLoading}>
              Rechazar
            </Button>
            <Button variant="contained" color="primary" onClick={handleAcceptDocument} disabled={isLoading}>
              Validar Revision
            </Button>
          </Box>
        </>
      )}
    </Drawer>
  );
};

const drawerWidth = '30%';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%'
  },
  drawer: {
    minWidth: '450px',
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    boxSizing: 'content-box',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.12)',
    minWidth: '436px',
    width: drawerWidth,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20
  },
  // necessary for content to be below app bar
  toolbar: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  documentTitle: {
    fontSize: '1.25rem',
    color: theme.palette.primary.main,
    lineHeight: '25px',
    marginLeft: 18
  }
}));

export default WatchDocumentDrawer;
