import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import {authActions, alertActions} from '../../core/actions'
import CreateAccount from './create-account';
import RestorePassword from './restore-password';
import SignIn from './sign-in';
import img from '../../assets/example-image.png';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import './onboarding.scss';
import banner from '../../assets/img/banner.svg';

class Onboarding extends Component {

  SIGN_IN = 'sign-in';
  CREATE_ACCOUNT = 'create-account';
  RESTORE_PASSWORD = 'restore-password';

  carouselData = [
    {
      /* title: 'Legaltech', */
      img,
      subtitle: 'Sub title',
      description:
        `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid!`
    },{
      /* title: 'Legaltech 2', */
      img,
      subtitle: 'Sub title 2',
      description:
        `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid! 2`
    },{
      /* title: 'Legaltech 2', */
      img,
      subtitle: 'Sub title 2',
      description:
        `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid! 2`
    }
  ]

  constructor(props) {
    super(props);

    this.state = {
      selectedForm: this.SIGN_IN,
      isNewUser: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn){
      if (this.state.isNewUser) {
        this.props.history.replace('/instructions')
      } else {
        this.props.history.replace('/dashboard')
      }
      this.props.closeAlert()
    }

    if (prevState.selectedForm !== this.state.selectedForm &&
      this.props.alert.message
      ) {
        this.props.closeAlert()
    }

    if (prevState.selectedForm === this.state.selectedForm &&
      this.state.selectedForm === this.CREATE_ACCOUNT &&
      this.props.alert.type && this.props.alert.type === 'success') {
        this.setState({ selectedForm: this.SIGN_IN })
      }
  }

  componentDidMount() {
    if (this.props.loggedIn){
      this.props.history.replace('/dashboard')
    }
  }

  renderForm() {
    const { selectedForm } = this.state;
    const {login, signUp, alert, activeSession, sendEmailPassword} = this.props;

    if (selectedForm === this.SIGN_IN) {
      return (
        <SignIn
          submitActions={ (form) => {
            login(form.email, form.password)
          }}
          onCreateAccount={ () => this.setState({ selectedForm: this.CREATE_ACCOUNT }) }
          onRecoverPassword={ () => this.setState({ selectedForm: this.RESTORE_PASSWORD }) }
          alert={ alert }
          handleSession={(ev) => {
            activeSession(ev.target.checked)
          }}
        />
      );
    }

    if (selectedForm === this.CREATE_ACCOUNT) {
      return (
        <CreateAccount
          submitActions={ (form) => {
            const newUser = {
              username: form.email,
              completeName: form.completeName,
              password: form.password,
              email: form.email
            }
            this.setState({isNewUser: true})
            signUp(newUser)
          }}
          onCancel={ () => this.setState({ selectedForm: this.SIGN_IN }) }
          errors={ alert }
        />
      );
    }

    if (selectedForm === this.RESTORE_PASSWORD) {
      return (
        <RestorePassword
          onReturnSingIn={ () => this.setState({ selectedForm: this.SIGN_IN }) }
          submitActions={ (form) => {
            sendEmailPassword(form)
          } }
          alert={ alert }
        />
      );
    }
  }

  render() {
    const { children } = this.props;

      return (
        <Grid container className="onboarding">
          <Grid
            item
            container
            md={ 6 }
            xs={ 12 }
            sm={ 12 }
            className="h-100"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={ 5 } xs={ isMobile ? 12 : 5 } sm={ 5 }>
              {
                children ?
                  children
                :
                  this.renderForm()
              }
            </Grid>
          </Grid>
              <Grid className="banner xs-not-show" item container md={6} justifyContent="space-around" alignItems="center" direction="column">
                <section>
                  <p><span>Axeleratum</span></p>
                  <h1>Gestión Interna</h1>
                </section>
                <img src={banner} alt="banner" />
                <h3>¡Bienvenido!</h3>
              </Grid>
        </Grid>
      );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.authReducer.loggedIn,
    alert: state.alertReducer,
    keep_active_session: state.authReducer.keep_active_session
  }
}

const mapDispatchToProps = {
  login: authActions.login,
  signUp: authActions.signUp,
  activeSession: authActions.activeSession,
  closeAlert: alertActions.close,
  sendEmailPassword: authActions.sendEmailPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
