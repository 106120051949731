import React, { FC, VFC } from 'react';
import { saveAs } from 'file-saver';
import { Button, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import WatchDocumentDrawer from '../components/watch-document/WatchDocumentDrawer';
import { DocumentValidationStatus } from '../types';
import { useGetDocument } from '../core/hooks/useGetDocument';

interface ViewPDFPageProps {
  name: string;
  documentId: string;
  documentStatus: string;
  onValidateStatus: (status: DocumentValidationStatus) => void;
  onReturn: () => void;
}

const ViewPDFPage: FC<ViewPDFPageProps> = (props) => {
  const classes = useStyles();
  const { fileName, fileBlob, fileMIME, fileStatus } = useGetDocument(props.documentId, true);

  return (
    <div className={classes.root}>
      <WatchDocumentDrawer {...props} isLoading={fileStatus === 'Cargando documento...'} />

      <main className={classes.content}>
        {fileBlob ? <DocumentRender mimeType={fileMIME} fileBlob={fileBlob} fileName={fileName} /> : fileStatus}
      </main>
    </div>
  );
};

interface DocumentRenderProps {
  mimeType: string | false;
  fileBlob: Blob;
  fileName: string;
}

const DocumentRender: VFC<DocumentRenderProps> = (props) => {
  const fileurl = URL.createObjectURL(props.fileBlob);

  const saveFile = () => saveAs(props.fileBlob, props.fileName);

  if (props.mimeType === 'application/pdf') {
    return <embed src={`${fileurl}#&scrollbar=0&view=Fit`} type="application/pdf" width="100%" height="100%" />;
  } else if (typeof props.mimeType === 'string' && props.mimeType.includes('image')) {
    return <img src={fileurl} alt="" width="90%" />;
  } else {
    return (
      <Button startIcon={<GetAppIcon />} variant="contained" color="primary" onClick={saveFile}>
        Guardar {props.fileName !== '' ? props.fileName : 'Documento'}
      </Button>
    );
  }
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100%'
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default ViewPDFPage;
