import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { getStatusColor } from '../../../../core/utils/utils';
import StatusButton from '../../../general/StatusButton';

type CompanyTableRowProps = {
  onClick?: (id: string) => void;
  tableHasClient?: boolean;
}

const CompanyTableRow = (props: CompanyTableRowProps & ServiceCompany) => {
  const classes = useRowStyles();

  const handleClick = () => props.onClick?.(props.id);

  return (
    <TableRow hover className={classes.root} onClick={handleClick}>
      <TableCell>{props.name}</TableCell>
      {!!props.clientName ? (
        <>
          <TableCell>{props.collaboratorName}</TableCell>
          <TableCell>{props.clientName}</TableCell>
        </>
      ) : (
        <TableCell colSpan={props.tableHasClient ? 2 : 1}>{props.collaboratorName}</TableCell>
      )}
      <TableCell>
        <StatusButton bgColor={getStatusColor(props.status)}>{props.status}</StatusButton>
      </TableCell>
      <TableCell>{props.lastQuery.toLocaleString()}</TableCell>
    </TableRow>
  )
}

const useRowStyles = makeStyles({
  root: {
    cursor: 'pointer'
  },
});

export default CompanyTableRow;