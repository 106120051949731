import React, { useState, VFC } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Avatar, Hidden, IconButton, makeStyles, SvgIconProps, Tooltip } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';

import AxeleratumIcon from '../icons/AxeleratumIcon';
import MainMenu from './MainMenu';
import SecondaryMenu from './SecondaryMenu';
import DrawerMenu from './DrawerMenu';


interface TopBarProps {
  /** Items shown on the top menu */
  menuItems: {
    /**Item tex */
    text: string,
    /** identifier each item used on map function as key prop. */
    name: string,
    /** Icon shown beside the name. */
    icon: (props: SvgIconProps) => JSX.Element,
    /** Link where the item will be redirect when the user clicks on it. */
    link: string,
  }[];
  /** Name of the logged user. Used in avatar showing the first letter of the user name. */
  userName: string;
  /** Event executed when the user clicks on 'Cerrar sesión' button. */
  onLogout: () => void;
}

/** Top bar menu shown across the whole project. */
const TopBar: VFC<TopBarProps> = ({ menuItems, userName, onLogout }) => {
  const classes = useStyles();
  const [secondMenu, setSecondMenu] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  sessionStorage.setItem('currentUser', userName);

  const toggleMenu = () => setSecondMenu(!secondMenu);

  return (
    <AppBar className={classes.appBar} onMouseLeave={() => setSecondMenu(false)}>
     <Hidden smUp>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpenDrawer(true)}>
          <MenuIcon />
        </IconButton>
     </Hidden>
      <AxeleratumIcon htmlColor="white" />
      <Hidden xsDown>
        <MainMenu show={!secondMenu} menuItems={menuItems} />
      </Hidden>
      <SecondaryMenu show={secondMenu} onLogout={onLogout} />

      <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
        {/* Button that shows user notifications. Useless for now. */}
        <Tooltip title="Notificaciones">
          <IconButton className={classes.notifications}>
            <NotificationsIcon htmlColor="white" fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* User avatar that shows the first letter of the user name. In the future, it will show user image too. */}
        <Avatar onClick={toggleMenu} className={classes.avatar}>
          {userName ? userName.toUpperCase().charAt(0) : ''}
        </Avatar>
      </div>
      <DrawerMenu menuItems={menuItems} open={openDrawer} onClose={() => setOpenDrawer(false)} />
    </AppBar>
  );
}


const useStyles = makeStyles((theme) => {
  return {
    notifications: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      marginLeft: 10,
      marginRight: 10,
      padding: 10
    },
    avatar: {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      marginLeft: 10,
      marginRight: 12,
      transition: 'color 0.3s, background-color 0.3s',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      }
    },
    appBar: {
      alignItems: 'center',
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow: '0 0 black',
      flexDirection: 'row',
      height: 65,
      padding: 20,
    },
  };
});

export default TopBar;
