import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, Grid, Typography, Box, Paper } from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import DialogConfirm from '../../../general/DialogConfirm'
import IconButton from '@material-ui/core/IconButton';


type Props = {
  onCancel: () => void;
  onAccept: () => void;
  onDelete: (item: any) => any;
  title: string;
  modalMessage: string;
  dataList: any[];
};

function ListOtherDetails({ title, onCancel, onDelete, onAccept, dataList, modalMessage }: Props) {

  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [item, setItem] = useState<any>(null);


  const onConfirmAction = () => {
    setShowDialog(false);
    onDelete(item);
  };

  const onCancelAction = () => {
    setShowDialog(false)
  };

  const handleSetSelectItem = (item: any) => {
    setItem(item);
    setShowDialog(true)

  };

  return (

    <Grid item xs={6} >
      <Box style={{ paddingLeft: '5px', paddingRight: '5px' }}>
        <Paper elevation={6}>
          <Grid container justifyContent="flex-start" spacing={2} style={{ paddingLeft: '10px' }}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => onAccept()}>{title}</Button>
            </Grid>
          </Grid>
          <Grid container >

            <List className={classes.demo}>
              {
                dataList.length! ? dataList.map((item, key) => {
                  return (
                    <ListItem key={key} style={{ textAlign: 'justify' }}>
                      <ListItemIcon>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleSetSelectItem(item)}>
                          <RemoveIcon style={{ color: 'red' }} />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText primary={item.label} style={{ fontSize: '9px' }} secondary={item.value !== '' ? item.value : ''} />
                      {/** */}
                      {/* <ListItemText primary={item.value2} /> */}
                    </ListItem>
                  );
                }) :

                  <Grid container spacing={2} style={{ padding: '15px' }}>
                    <Grid item>
                      <Typography variant="h6" color="primary">No hay información</Typography>
                    </Grid>
                  </Grid>

              }

            </List>
          </Grid>
        </Paper>
      </Box>
      <DialogConfirm
        open={showDialog}
        title={""}
        message={`¿Está seguro que desea eliminar el ${modalMessage}?`}
        onConfirm={() => onConfirmAction()}
        onClose={() => onCancelAction()}
      />
    </Grid>
  );
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 200,
      width: 850,
    },
  }),
);
export default ListOtherDetails;