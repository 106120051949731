import {
  authHeader,
  axiosBusinessRequest,
  axiosIpfsRequest,
  axiosCompanyRequestV1,
  axiosMaterialityRequestV1,
} from "../../configs/axios.config";
import { Company } from "vault-axeleratum/dist/types";
import { GenericResponse } from "../../types/global";
import axios from "axios";

export class CompaniesService {
  private static instance: CompaniesService;

  private constructor() {}

  public static getInstance(): CompaniesService {
    if (!CompaniesService.instance) {
      CompaniesService.instance = new CompaniesService();
    }
    return CompaniesService.instance;
  }

  async getActiveCompanies() {
    try {
      const { data } = await axiosBusinessRequest.get<Company[]>(
        `/companies/actives`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getActiveCompaniesMagnament() {
    try {
      const { data } = await axiosIpfsRequest.get(`api/v1/users`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCompanies(
    currentPage: number,
    pageSize = 500,
    querySearch?: string
  ) {
    try {
      const { data } = await axiosCompanyRequestV1.get(
        `/companies?page=${currentPage}&size=${pageSize}${
          !!querySearch ? `&search=${querySearch}` : ""
        }`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanies({ page, search }: { page?: number; search?: string }) {
    /*const params: any = { page: page || 0, size: 100 };
    if (search) {
      params["search"] = search;
    }
    try {
      const { data } = await axiosCompanyRequestV1.get("/companies", {
        params,
        headers: this._getAuhtHeaders(),
      }); //TODO: paginado dinamico
      return data;
    } catch (error) {
      throw error;
    }*/
  }

  async createCompanies(reqData: any) {
    try {
      const { data } = await axiosCompanyRequestV1.post(
        `/companies/init`,
        reqData,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      // Verificar si error.response existe y contiene data
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Ocurrió un error al crear la empresa');
      } else {
        throw new Error('Ocurrió un error al crear la empresa');
      }
    }
  }
  

  async getCompanyByID(companyId: any) {
    try {
      const { data } = await axiosCompanyRequestV1.get(
        `/companies/${companyId}`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUsersByCompany(companyId: any) {
    try {
      const { data } = await axiosCompanyRequestV1.get(
        `/companies/${companyId}/users`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompaniesGroups() {
    try {
      const { data } = await axiosCompanyRequestV1.get(
        `/companies/list/groups`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServicesByCompany(companyId: string) {
    const { data } = await axiosMaterialityRequestV1.get<
      GenericResponse<ServiceCompany[]>
    >(`services/companies/${companyId}`, { headers: authHeader() });
    return data.data;
  }

  async deactivateCompany(companyId: any) {
    try {
      const { data } = await axiosCompanyRequestV1.delete(
        `/companies/${companyId}`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editCompany(reqData: any) {
    try {
      const { data } = await axiosCompanyRequestV1.put(`/companies`, reqData, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCompany(body: any) {
    try {
      const { data } = await axiosCompanyRequestV1.put(`/companies`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async testApi() {
    try {
      const { data } = await axiosMaterialityRequestV1.get(`/service-type/`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //get additional produscts
  async getAllAdditionalProducts() {
    try {
      const { data } = await axiosCompanyRequestV1.get(
        `/additional/product/all`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveAdditionalProducts(companyId: string, additionalProductId: string[]) {
    try {
      const { data } = await axiosCompanyRequestV1.put(
        `/additional/product/association`,
        { companyId, additionalProductId },
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }


}
