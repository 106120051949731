import React, { VFC } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import uid from 'uid';

interface MenuComponentProps {
  buttonText: JSX.Element;
  buttonType?: 'normal' | 'icon';
  items: MenuItem[];
  disabled?: boolean;
}

const MenuComponent: VFC<MenuComponentProps> = ({ buttonText, buttonType = 'normal', items, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {
        buttonType === 'icon' ?
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={ handleClick }
            disabled={ disabled }
          >
            { buttonText }
          </IconButton>
          :
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={ handleClick }
            disabled={ disabled }
          >
            { buttonText }
          </Button>
      }

      <Menu
        id="simple-menu"
        anchorEl={ anchorEl }
        keepMounted
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
      >
        {
          items && items.map(item => (
            <MenuItem
              key={ uid() }
              onClick={ () => {
                handleClose();
                item.action?.()
              } }
              disabled={ item.disabled }
            >
              { item.label }
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
}

export default MenuComponent;