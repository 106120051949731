import React from "react";
import { OutlinedInput, FormHelperText, FormControl, Box, InputAdornment } from "@material-ui/core";

interface RenderTextFieldProps {
  disabled?: boolean;
  error?: string;
  id?: string;
  label?: string;
  multiline?: boolean;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  startIcon?: JSX.Element;
  touched?: boolean;
  type?: string;
  value?: unknown;
}

const RenderTextFieldV2 = ({
  disabled,
  error,
  id,
  label,
  multiline,
  name,
  onChange,
  placeholder,
  required,
  startIcon,
  touched,
  type = "text",
  value,
}: RenderTextFieldProps) => {
  //const classes = useReduxFormStyles();
  const rootComponentProps = { display: "inline-flex", width: "100%", flexDirection: "column" };

  return (
    <Box {...rootComponentProps}>
      {label && <label>{label}</label>}
      <FormControl variant="outlined" color="primary" fullWidth margin="dense" error={touched && !!error}>
        <OutlinedInput
          type={type}
          id={id}
          name={name}
          disabled={disabled}
          multiline={multiline}
          rows={3}
          onChange={onChange}
          placeholder={placeholder || ""}
          required={required}
          value={value}
          startAdornment={<InputAdornment position="start">{<>{startIcon}</>}</InputAdornment>}
        />
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RenderTextFieldV2;
