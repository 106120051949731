import { axiosRequest, axiosRequestFiles, authHeader, jsonTypeHeader, authIpfsHeader, axiosIpfsRequest ,axiosMaterialityRequestV1 } from '../../configs/axios.config';

export class TemplatesHttp {

  createTokenIpfs(request, sucess){
    axiosIpfsRequest.post('/api/v1/auth/login', request, {headers: jsonTypeHeader()})
    .then(({data}) => sessionStorage.setItem('access_token_ipfs', data.access_token))
    .catch(err => console.log(err))
  }

  createDocumentSet(request,sucess, error){
    axiosRequestFiles.post('/ntp', request, {headers: authIpfsHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }

  findAllDocumentSets(sucess, error){
    axiosMaterialityRequestV1.get('/service-type/', {headers: authHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }

  findAllTemplatesByBusinessId(businessId, sucess, error) {
    axiosRequest.get(`/templates/${businessId}`, {headers: authHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }

  createTemplate(template, sucess, error) {
    axiosRequest.post('/templates', template, {headers: authHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }

  updateTemplate(template, sucess, error) {
    axiosRequest.put('/templates', template, {headers: authHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }

  deleteTemplate(templateId, sucess, error) {
    axiosRequest.delete(`/templates/${templateId}`, {headers: authHeader()})
    .then(({data}) => sucess(data))
    .catch(err => error(err))
  }
}