import { combineReducers } from 'redux';
import { reducer } from 'redux-form';

import {authReducer} from './auth.reducer'
import {alertReducer} from './alert.reducer'
import {userReducer} from './user.reducer'
 
const rootReducer = combineReducers({
  authReducer,
  alertReducer,
  userReducer,
  form: reducer
})

export default rootReducer
