import React, { useContext, useEffect, useState, VFC } from 'react';
import { Grid } from '@material-ui/core';
import AlertInfo from "@material-ui/lab/Alert";
//@ts-ignore
import { Alert } from 'axeleratum-sgc-frontend-library';

import { BusinessHttp } from "../../../core/http/business.http";
import { UserHttp } from "../../../core/http/user.http";
import { TagHttp } from '../../../core/http/tag.http';
import { FoldersHttp } from '../../../core/http/folders.http';

import FormCompanies from "./FormCompanies";
import { useHandleDialog } from '../../../core/hooks/useHandleDialog';
import { ManagementContext } from '../../../core/contexts/management-context/ManagementContext';
import ManagementTable from '../management-table/ManagementTable';

const Companies: VFC = () => {
  const { getCompanies, companies, loadingCompanies } = useContext(ManagementContext);
  const [users, setUsers] = useState<any[]>(); //List of users retreived by the back-end.
  const [companySelected, setCompanySelected] = useState<Company | null>(null); //Company selected when the user clicks 'Editar' button on table item
  const [tags, setTags] = useState<any[]>(); //List of tags retreived by the back-end
  const [alertError, setAlertError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [Dialog, handleShowDialog, handleCloseDialog] = useHandleDialog();


  const businessHttp = new BusinessHttp();
  const userHttp = new UserHttp();
  const tagHttp = new TagHttp();
  const foldersHttp = new FoldersHttp();

  useEffect(() => {
    findAllUsers();
    findAllTags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //gets
  const findAllUsers = () => {
    userHttp.findAll((users: any[]) => {
      const usersOut: any[] = [];

      if (users) {
        users.forEach((user: any) => {
          if ((!user.deleted) && user.enabled) {
            usersOut.push({
              ...user,
              label: user.completeName ? user.completeName : user.username,
              value: user.id
            });
          }
        });
      }
      setUsers(usersOut);
    }, () => {});
  }

  const findAllTags = () => {
    tagHttp.findAllTags(
      (resp: any) => setTags(resp.map((tag: any) => ({
        label: tag.name,
        value: tag.name
      }))),
      () => {}
    )
  }
  //========

  //cud
  const saveCompany = (company: any) => {
    businessHttp.saveBusiness(company,
      (resp: any) => {
        handleCloseDialog();
        createContractFolder(resp.id)
        getCompanies();
      },(e: any) => {
        handleCloseDialog();
        setAlertError(true);
        setMessageError(e.response.data);
      });
  }

  const createContractFolder = (companyId: any) => {
    foldersHttp.createFolder(companyId, 'Contratos',
      () => {},
      () => {
        handleCloseDialog();
        setMessageError('Error al crear carpeta Contratos de la empresa.');
      }
    )
  }

  const updateCompany = (company: any) => {
    console.log(company);
    handleCloseDialog();
    setCompanySelected(null);
    getCompanies();
    businessHttp.updateBusiness(company,
      () => {
        handleCloseDialog();
        setCompanySelected(null);
        getCompanies();
      }, (e: any) => {
        handleCloseDialog();
        setAlertError(true);
        setMessageError( e.resposne.data);
      });
  }

  const handleSelectCompany = (company: Company) => {
    setCompanySelected(companies?.find((companyItem) => companyItem.id = company.id) ?? null);
    handleShowDialog();
  }

  return(
    <>
      <Alert
        open={ alertError }
        title={ messageError }
        type="error"
        onConfirm={ () => setAlertError(false) }
      />

      <Dialog>
        <FormCompanies
          initialValues={ companySelected
            ? {...companySelected, tags: companySelected.tags.map((tag) => tag.value)}
            : { tags }
          }
          //@ts-ignore
          submitActions={(formData: any) => (companySelected ? updateCompany(formData)  : saveCompany(formData))}
          users={ users }
          tags={ !!companySelected ? companySelected.tags : tags }
          onCancel={() => {
            handleCloseDialog();
            setCompanySelected(null);
          }}
        />
      </Dialog>

      {loadingCompanies &&
          <Grid container style={{justifyContent: 'center', width: '100%'}}>
            <AlertInfo severity="info">Cargando empresas...</AlertInfo>
          </Grid>
      }

      {!loadingCompanies && companies?.length === 0 &&
            <Grid container style={{justifyContent: 'center', width: '100%'}}>
              <AlertInfo severity="info">No hay empresas registradas</AlertInfo>
            </Grid>
      }

    <ManagementTable
      addButtonProps={{label: "Nueva Empresa"}}
      tableItems={companies}
      header={[{ key: 'name', title: 'Empresa' }]}
      menuItems={[{
        label: 'Editar',
        action: handleSelectCompany
      }]}
    />
    </>
  );
}

export default Companies;
