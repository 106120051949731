import React, { VFC } from 'react';
import { makeStyles, Avatar, CardHeader } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

interface CardUserProps {
  user: User;
}

export const CardUser: VFC<CardUserProps> = (props) => {
  const classes = useStyles()

  const {
    user
  } = props;

  const {name} = user;

  return (
    <CardHeader
      avatar={
        <Avatar
          aria-label="recipe"
          className={classes.avatar}
        >
          {name && name.toUpperCase().charAt(0)}
        </Avatar>
      }
      classes={{root: classes.cardRoot, title: classes.titleCard}}
      title={`${name}`}
    />
  );
}

const useStyles = makeStyles((theme) => ({
    cardRoot: {
      padding: 0,
      color: blue[900]

    },
    root: {
      backgroundColor: 'white',
      marginTop: 8,
      marginBottom: 8,
      padding: 12,
      borderRadius: 50,
    },
    avatar: {
      width: 56,
      height: 56,
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main
    },
    titleCard: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '20px',
      color: theme.palette.primary.main, //primary
      '&-disabled': {
        color: '#cccccc',
      },
      '&-deleted': {
        color: '#e53935',
      }
    },
    subtitleCard: {
      fontSize: '1rem',
      lineHeight: '19px',
      color: '#A8A8A8',
      '&-deleted': {
        color: '#e53935 !important',
      }
    }
}));