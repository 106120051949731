import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

export class AlertModal extends Component {
  render() {
    const { open, title, textContent, onConfirm, isError } = this.props;

    return (
      <Dialog open={ open } onClose={onConfirm}>
        <DialogContent style={{ textAlign: 'center' }}>
          {isError ? <ErrorIcon style={{ color: 'red', fontSize: 60 }} /> : <CheckCircleIcon style={{ color: 'green', fontSize: 60 }} />}
          <DialogTitle style={{ textAlign: 'center', margin: '16px 0' }}>
            <Typography variant="h6">{ title }</Typography>
          </DialogTitle>
          <DialogContentText>
            <strong>{ textContent }</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button 
            onClick={ onConfirm } 
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

AlertModal.defaultProps = {
  isError: false,
};






/*import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

export class AlertModal extends Component {
  render() {
    const { open, title, textContent, onConfirm, onCancel, isError } = this.props;

    return (
      <Dialog open={ open } onClose={onCancel}>
        <DialogTitle style={{ textAlign: 'center' }}>
          {isError ? <ErrorIcon style={{ color: 'red', marginRight: 8 }} /> : <CheckCircleIcon style={{ color: 'green', marginRight: 8 }} />}
          { title }
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <strong>{ textContent }</strong>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button 
            onClick={ onConfirm } 
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>

          <Button 
            onClick={ onCancel } 
            color="primary" 
            variant="outlined" 
            autoFocus
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

AlertModal.defaultProps = {
  isError: false,
};*/
