import React from "react";
import {  FormHelperText, FormControl, Box, Select } from "@material-ui/core";

interface RenderTextFieldProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
  error?: string;
  touched?: boolean;
  value?: unknown;
  children: JSX.Element;
  defaultValue?:any
}

const RenderSelectV2 = ({
  label,
  onChange,
  error,
  touched,
  children,
  defaultValue = ''
}: RenderTextFieldProps) => {
  //const classes = useReduxFormStyles();
  const rootComponentProps = { display: "inline-flex", width: "100%", flexDirection: "column" };

  return (
    <Box {...rootComponentProps}>
      {label && <label>{label}</label>}
      <FormControl variant="outlined" color="primary" fullWidth margin="dense" error={touched && !!error}>
        <Select onChange={onChange} native defaultValue={defaultValue} id="grouped-native-select">
          {children}
        </Select>
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RenderSelectV2;
