import React from "react";
import { Typography } from "@material-ui/core";

import PolicyList from "./PolicyList";
import PolicyListItem from "./PolicyListItem";
import PolicyParagraph from "./PolicyParagraph";

const TermsAndConditions = () => {
  return (
    <>
      <PolicyParagraph>
        <b>Axeleratum SA de CV (Axeleratum)</b> por este medio da a conocer a los usuarios que utilicen o
              hayanutilizado, de manera directa o mediante un tercero, la plataforma virtual desarrollada y administrada
              por <b>Axeleratum</b> (en singular o plural, los <b>“Usuarios”</b>) denominada plataforma <b>Materialidad Fiscal</b>,
              a la cual podrá acceder en la siguiente dirección electrónica: <b>wwww.materialidadfiscal.com</b> (en lo sucesivo la <b>“Plataforma”</b>);
              los siguientes términos y condiciones en materia de privacidad de datos (los <b>“Términos y Condiciones”</b>), mismos que
              se le solicita sean leídos detalladamente, previo a dar inicio a la utilización de <b>Materialidad Fiscal.</b>
      </PolicyParagraph>

      <PolicyParagraph>
        En términos de la fracción IX del artículo 3º de la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares (<b>“Ley de Datos Personales”</b>)
              y los artículo 49 y 50 de su Reglamento (<b>“Reglamento”</b>), se le informa
              que <b>Axeleratum</b> tiene el carácter de <b>Encargado</b>, respecto
              del tratamiento de los <b>Datos Personales</b> que sea realizado directamente
              por el <b>Responsable</b>, quien podrá ser aquella persona física o moral que
              contrate el servicio de <b>Materialidad Fiscal</b>, conforme a los requisitos
              establecidos en la legislación de la materia.
            </PolicyParagraph>
      <PolicyParagraph>
        En caso de que el <b>Usuario</b> no esté de acuerdo con alguno de los <b>Términos y Condiciones</b> aquí
              establecidos, deberá abstenerse de utilizar
              la plataforma <b>Materialidad Fiscal</b>.
            </PolicyParagraph>

      <Typography variant="subtitle1">A. CONDICIONES GENERALES</Typography>
      <PolicyParagraph>
        <PolicyList>
          <PolicyListItem>
            Los presentes <b>Términos y Condiciones</b> rigen en materia de privacidad
                  de datos, el uso por parte de los <b>Usuarios</b>, de la Plataforma desarrollada
                  por <b>Axeleratum</b>, denominada <b>Materialidad Fiscal</b>
          </PolicyListItem>
          <PolicyListItem>
            Su registro y utilización de la <b>Plataforma</b> como Usuario en <b>Materialidad
                  Fiscal</b>, bajo cualquiera de sus modalidades, implica de manera automática la plena
                  aceptación de los presentes <b>Términos y Condiciones</b>.
                </PolicyListItem>
          <PolicyListItem>
            Es responsabilidad de los <b>Usuarios</b> al utilizar <b>Materialidad Fiscal</b>, conservar
                  la confidencialidad de su cuenta de usuario y contraseña de acceso a la <b>Plataforma</b>,
                  siendo completamente responsables; en consecuencia, de todas las actividades que se realicen
                  bajo su cuenta y contraseña.
                </PolicyListItem>
          <PolicyListItem>
            <p><b>Materialidad Fiscal</b> recomienda a sus <b>Usuarios</b> que el nombre o referencia de su cuenta de
                  usuario no contenga ningún dato personal sensible o patrimonial y mantenerla bajo su
                  resguardo y confidencialidad.</p>
            <p>Conforme a lo anterior, <b>Materialidad Fiscal</b> reitera que los <b>Usuarios</b> son los únicos
                  responsables del cuidado de su cuenta y contraseña, por lo que <b>Materialidad Fiscal</b> no
                  será responsable de las consecuencias que pudieran  derivarse por la denominación y uso de la misma.</p>
          </PolicyListItem>
          <PolicyListItem>
            Queda estrictamente prohibida la reproducción parcial o total de los elementos y contenido
                  que integran <b>Materialidad Fiscal</b>,  ya que se trata de propiedad intelectual protegida por
                  <b>Axeleratum</b>.
                </PolicyListItem>
          <PolicyListItem>
            Queda estrictamente prohibido proporcionar su cuenta y contraseña a persona distinta al <b>Usuario</b> o
                  usar la cuenta de usuario y contraseña de otra persona registrada en <b>Materialidad Fiscal</b>.
                </PolicyListItem>
          <PolicyListItem>
            <b>Materialidad Fiscal</b> se encuentra trabajando constantemente en la mejora de la <b>Plataforma</b>, por
                  esa razón es posible que de tiempo en tiempo se realicen actualizaciones a la misma con la
                  finalidad de mejorar la experiencia de los <b>Usuarios</b>.
                </PolicyListItem>
          <PolicyListItem>
            El uso de <b>Materialidad Fiscal</b> requiere de conexión a Internet, por lo que si el dispositivo
                  electrónico desde el que se utilicen no se encuentra conectado a una red Wi-Fi puede consumir
                  datos móviles y generar cargos extra con su proveedor de servicios de telefonía móvil e Internet.
                </PolicyListItem>
          <PolicyListItem>
            <b>Materialidad Fiscal</b> no se hace responsable por los daños en el software y/o hardware de los
                  dispositivos electrónicos en los cuales se utilice <b>Materialidad Fiscal</b>, ni del uso irresponsable
                  o negligente que el <b>Usuario</b> realice de la <b>Plataforma</b>.
                </PolicyListItem>
          <PolicyListItem>
            El <b>Usuario</b> reconoce y acepta sujetarse a la legislación aplicable a la materia de privacidad de
                  datos, así como a las autoridades y tribunales competentes de los Estados Unidos Mexicanos, para
                  la interpretación y cualquier controversia que se derive de los presentes Términos y Condiciones.
                </PolicyListItem>
        </PolicyList>
      </PolicyParagraph>
      <Typography variant="subtitle1">B. OBLIGACIONES DEL ENCARGADO</Typography>
      <PolicyParagraph>
        <b>Axeleratum</b> bajo el carácter de <b>Encargado</b>, tendrá las siguientes obligaciones:
              <PolicyList>
          <PolicyListItem>
            <p>
              Realizar el tratamiento de los <b>Datos Personales</b>, únicamente conforme a las instrucciones que, para
                    tales efectos, le solicite el <b>Responsable</b> en el contrato respectivo.
                  </p>
            <p>
              Conforme a lo establecido en la <b>Ley de Datos Personales</b>, se entenderá por: <b>(i)</b> <b>Datos Personales</b>,
                    cualquier información concerniente a una persona física identificada o identificable,  por <b>(ii)</b>
                    Datos personales sensibles, aquellos datos personales que afecten a la esfera más íntima de su
                    titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave
                    para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen
                    racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas,
                    filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual, y por <b>(iii)</b> <b>Datos Personales</b> Patrimoniales, aquellos relacionados con la esfera y medios económicos del
                    titular de los mismos (en lo sucesivo y en su conjunto los “<b>Datos Personales</b>”).
                  </p>
          </PolicyListItem>
          <PolicyListItem>
            <p>
              Abstenerse de tratar los <b>Datos Personales</b> para finalidades distintas a las instruidas por el <b>Responsable</b>,
                    mismas que deberán encontrarse definidas en el <b>Aviso de Privacidad</b> que este último
                  ponga a disposición de los <b>Usuarios</b>, previo al tratamiento de los mismos.
                  </p>
            <p>
              Conforme a lo establecido en la <b>Ley de Datos Personales</b>, se entenderá por <b>Aviso de Privacidad</b>,
                    el documento físico, electrónico o en cualquier otro formato generado por el <b>Responsable</b>, previo
                    al tratamiento de los <b>Datos Personales</b>, de conformidad con el artículo 15 de Ley de Datos
                    Personales (en lo sucesivo y en su conjunto los “<b>Aviso de Privacidad</b>”).
                  </p>
          </PolicyListItem>
          <PolicyListItem>
            Implementar las medidas de seguridad necesarias, a fin de proteger los <b>Datos Personales</b> y la
                  información que se trate a solicitud del <b>Responsable</b>.
                </PolicyListItem>
          <PolicyListItem>
            Guardar confidencialidad respecto de los <b>Datos Personales</b> tratados.
                </PolicyListItem>
          <PolicyListItem>
            <p>
              Suprimir los <b>Datos Personales</b> objeto de tratamiento por indicación del <b>Responsable</b>, o bien
                      una vez terminada la relación jurídico-contractual entre <b>Axeleratum</b> y el <b>Responsable</b>.
                    </p>
            <p>
              Lo anterior, siempre y cuando no exista una previsión legal que exija la conservación de
                      los <b>Datos Personales</b>.
                    </p>
          </PolicyListItem>
          <PolicyListItem>
            Abstenerse de transferir los datos personales a terceros, excepto en aquellos casos en que el <b>Responsable</b> así lo indique, o cuando así lo requiera una autoridad competente.
                </PolicyListItem>
        </PolicyList>
        <b>Axeleratum</b>, observará que los acuerdos con el <b>Responsable</b>  en la contratación de <b>Materialidad Fiscal</b> sean
              acordes con el <b>Aviso de Privacidad</b> correspondiente.
            </PolicyParagraph>

      <Typography variant="subtitle1">C. SEGURIDAD DE LA PLATAFORMA</Typography>
      <PolicyParagraph>
        <b>Axeleratum</b>, le informa que la <b>Plataforma</b> usa cookies y tecnologías similares para almacenar y
              respetarlas preferencias y la configuración del <b>Usuario</b>, lo que le permite al <b>Usuario</b> iniciar
              sesión y analizar el rendimiento de <b>Materialidad Fiscal</b> y satisfacer otros fines lícitos.
            </PolicyParagraph>
      <PolicyParagraph>
        En este sentido, disponemos de una variedad de herramientas para controlar los datos recopilados
        por medio de las cookies y las tecnologías similares. Usted puede usar los controles del navegador
        de Internet que utilice para limitar el uso de las cookies, incluyendo su desactivación o bloqueo
        del navegador.
            </PolicyParagraph>
      <PolicyParagraph>
        Asimismo, <b>Axeleratum</b> le informa que cuenta con los siguientes sistemas de seguridad informática
              para la protección de sus <b>Datos Personales</b>:
              <PolicyList>
          <b>
            <li>Log Analytics de Azure</li>
            <li>Blockchain</li>
            <li>Azure Advisor</li>
            <li>Firewall</li>
          </b>
        </PolicyList>
              En caso de que llegara a existir cualquier tipo de vulneración a la seguridad de la <b>Plataforma</b>,
              que pudiera afectar sus <b>Datos Personales</b>, se hará del conocimiento del <b>Responsable</b>, para que
              adopte las medidas que considere necesarias para la atención y/o mitigación de la vulneración de
              que se trate, conforme a la legislación de la materia.
            </PolicyParagraph>
      <Typography variant="subtitle1">D. CONFIDENCIALIDAD DE LA INFORMACIÓN</Typography>
      <PolicyParagraph>
        <b>Axeleratum</b> hace del conocimiento a los <b>Usuarios</b> de <b>Materialidad Fiscal</b> que toda la documentación
              e información que sea proporcionada en la utilización de la <b>Plataforma</b> es propiedad de los mismos
              <b>Usuarios</b>, y por lo tanto, su tratamiento se encontrará a cargo del <b>Responsable</b>, conforme a las
              finalidades establecida en su <b>Aviso de Privacidad</b>; siendo <b>Axeleratum</b> el <b>Encargado</b> de resguardar
              dicha información y documentación, en su caso, como confidencial, bajo los límites establecidos en
              la legislación de la materia (la “<b>Información</b>”).
            </PolicyParagraph>
      <PolicyParagraph>
        En este sentido, <b>Axeleratum</b> se compromete a no copiar ni divulgar de ninguna manera, ni proporcionar
              a terceras personas, la <b>Información</b> que los <b>Usuarios</b> proporcionen durante el uso de la plataforma
              <b>Materialidad Fiscal</b>.
            </PolicyParagraph>
      <Typography variant="subtitle1">E. MODIFICACIÓN A LOS TÉRMINOS</Typography>
      <PolicyParagraph>
        <b>Axeleratum</b> se reserva el derecho de modificar, en cualquier momento, los presentes Términos y Condiciones,
              en su caso, añadir nuevos términos y condiciones sobre el uso de <b>Materialidad Fiscal</b>. Las modificaciones
              que se realicen de tiempo en tiempo serán aplicables inmediatamente en la fecha que se publiquen en el sitio
              web: <b>www.materialidadfiscal.com</b>. En este sentido, el uso continuado de <b>Materialidad Fiscal</b> por parte de los <b>Usuarios</b> se
              entenderá como una aceptación tácita de los mismo, por lo que en este sentido le solicitamos hacer
              una revisión constante de nuestro sitio web.
            </PolicyParagraph>
    </>
  );
}

export default TermsAndConditions;
