import React, { useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core';

interface Props {
  logo: JSX.Element;
  data: {
    img: string;
    subtitle: string;
    description: string;
  }[];
  end: () => void;
}

const CarouselInstructions = ({ logo, data, end }: Props) => {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(0);
  if (indexSelected === data.length) end();

  return (
    <Grid className={classes.instructionsCarousel} container md={12} >
      { logo &&
        <Grid container item className={classes.logoContainer} md={12} justifyContent="center">
          {logo}
        </Grid>
      }
      {
        indexSelected === data.length ? null : (
          <Grid className={classes.centralSection} container item md={12} justifyContent="center">
            <img alt="carousel" src={data[indexSelected].img} className="animate__animated animate__fadeIn" />
            <div>
              <h3 className="animate__animated animate__fadeIn">{data[indexSelected].subtitle}</h3>
              <p className="animate__animated animate__fadeIn">{data[indexSelected].description}</p>
            </div>
          </Grid>
        )
      }
      <Grid container item md={12} justifyContent="center" alignItems="center">
        {
          indexSelected === 0 ? null : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIndexSelected(indexSelected - 1)}
            >Regresar</Button>
          )
        }
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={() => {
            setIndexSelected(indexSelected + 1);
          }}
        >Siguiente</Button>
      </Grid>
    </Grid>
  )

}

const useStyles = makeStyles( theme => ({
  instructionsCarousel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: 'inherit',
    '& button': {
      margin: '0 20px'
    }
  },
  logoContainer: {
    '& img': {
      margin: '0 20px'
    },
  },
  centralSection: {
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& img': {
      width: 250,
      height: 200
    },
    '& h3': {
      color: 'white',
      textAlign: 'center'
    },
    '& p': {
      color: 'white',
      textAlign: 'center',
      maxWidth: 400
    }
  }
}));

export default CarouselInstructions
