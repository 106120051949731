import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import imgTc from '../../../assets/img_tc.png';

interface PolicyContainerProps {
  onReturn: () => void;
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  title: string;
}

const PolicyDialog: FC<PolicyContainerProps> = ({ children, onReturn, open, onAccept, onReject, title }) => {
  return (
    <Dialog open={open} scroll="body">
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton
              color="primary"
              edge="start"
              onClick={onReturn}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs>
            <h3>{title}</h3>
          </Grid>
        </Grid>
        <img className="terms-and-condition-img" src={imgTc} alt="test" />
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onAccept}
        >
          Aceptar
            </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onReject}
        >
          Rechazar
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PolicyDialog;
