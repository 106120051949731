import React from 'react';
import { Route, Switch } from 'react-router';

import Onboarding from '../../components/onboarding/onboarding';
import Instructions from '../../components/onboarding/instructions';
import NewPassword from "../../components/onboarding/new-password";
import Home from '../../components/dashboard/home';
import { PrivateRoute } from '../../components/private-route';

const Routing = () => {
  return (
      <Switch>
        <Route path="/" exact component={ Onboarding } />
        <PrivateRoute path='/dashboard' component={ Home } />
        <PrivateRoute path='/instructions' component={ Instructions } />
        <Route path="/new-password/:token_restore" component={ NewPassword } />
      </Switch>
  );
}

export default Routing;
