import React, { FC } from 'react'

interface PolicyListProps {
  type: "a" | "i" | "1" | "A" | "I" | undefined;
}

const PolicyList: FC<PolicyListProps> = ({ children, type }) => {
  return (
    <ol type={type} style={{paddingInlineStart: 20}}>
      {children}
    </ol>
  )
}

export default PolicyList
