import { useEffect, useState } from "react";
import { RolesService } from "../../../services/roles.service";


export const useHandleRoles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const rolesService = RolesService.getInstance();

  useEffect(() => {
    getRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoles = async () => {
    setLoadingRoles(true);
    try {
      const roles = await rolesService.listRoles();
      let rol = roles.data;
      console.log("done marca eeorrro",roles)
      const dataMapped = rol.map((role: any) => {
        return {
          label: role.name,
          value: role.id,
        }
      })
      setRoles(dataMapped);
      setFilteredRoles(dataMapped);
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      console.log(error);
    }
  }

  const filterRoles = (query: string) => {
    const result = query !== '' ? roles.filter(role => role.label.includes(query)) : roles
    setFilteredRoles(result);
  }

  return {
    roles: filteredRoles,
    loadingRoles,
    getRoles,
    filterRoles
  }
}