
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

//@ts-ignore
import { AlertConfirm } from 'axeleratum-sgc-frontend-library';
import { Grid} from '@material-ui/core';
import AlertInfo from "@material-ui/lab/Alert";

import { alertActions, usersActions, authActions } from '../../../core/actions';
import { TemplatesHttp } from '../../../core/http/templates.http';

import FormTemplate from './FormTemplate'
import { useHandleDialog } from '../../../core/hooks/useHandleDialog';
import { FormType } from '../../../pages/ManagementPage';
import ManagementTable from '../management-table/ManagementTable';


type PropsFromRedux = ConnectedProps<typeof connector>;

const Templates = (props: PropsFromRedux) => {
  const [MaterialityDialog, handleShowModal, handleCloseModal] = useHandleDialog();
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [dialogTitle] = useState('');
  const [typeForm] = useState<FormType>('create');
  const [documents, setdocuments] = useState<DocumentSet[]>([]); // List of companies retreived by the back-end

  const templatesHttp = new TemplatesHttp();

  useEffect(() => {
    let jsonUser = {
      email: "user-demo@qualtop.com",
      password: "1234567890"
    }
    templatesHttp.createTokenIpfs(jsonUser);
    console.log('Creando peticion de token nuevo');
    handleSelectElement()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSave = (data: any) => {
    console.log('submitSave');
  }

  const handleSelectElement = () => {
    templatesHttp.findAllDocumentSets((data: any) => {
      setdocuments(data.data);
      console.log(data);
    }
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ManagementTable
            addButtonProps={
              {
                label: "Nueva Plantilla",
                onAdd: handleShowModal
              }}
            tableItems={documents}
            header={[{ key: 'name', title: 'Plantilla' }]}
            menuItems={[{
              label: 'Editar',
              action: handleSelectElement
            }]}
          />
        </Grid>

    

        {props.fetchingUsers && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info" >Cargando templates...</AlertInfo>
          </Grid>
        )}
      </Grid>


      <MaterialityDialog>
        <FormTemplate
          onCancel={() => handleCloseModal()}
          submitActions={(formData: any) => submitSave(formData)}
          title={dialogTitle}
          typeForm={typeForm}
        />
      </MaterialityDialog>

      <AlertConfirm
        open={openAlertDelete}
        onCancel={() => setOpenAlertDelete(false)}
        textContent="El usuario será eliminado. ¿Deseas continuar?"
      />

    </>
  );
}


const mapStateToProps = (state: any) => {
  return {
    alert: state.alertReducer,
    users: state.userReducer.usersFiltered,
    fetchingUsers: state.userReducer.fetchingUsers,
    currentUser: state.authReducer.currentUser
  }
}

const mapDispatchToProps = {
  findAllUsers: usersActions.findAll,
  closeAlert: alertActions.close,
  createUser: usersActions.createNewUser,
  editUser: usersActions.editUser,
  blockUser: usersActions.blockUser,
  deleteUser: usersActions.deleteUser,
  assignRoles: usersActions.assignRoles,
  logout: authActions.logout,
}
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Templates);