import React, { useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '../icons/FilterIcon';

interface UserControlsProps {
  /** Event executed when the user types on the search input. */
  onSearch: (query: string, columnValue: string, sortValue: string) => void;
  /** What the search input wull search: users, teams, roles or companies.
   */
  searchTitle: 'Equipos' | 'Usuarios' | 'Roles' | 'Empresas' | 'Plantillas' | 'Stages';
}

/** Component that shows new user button, search user input and filter user button.  */
const UserControls = (props: UserControlsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); //Used to set where the menu will be rendered
  const [querySearch, setQuerySearch] = useState('');
  const [selectValues, setSelectValues] = useState({
    valueSelectColumn: 'name',
    valueSelectSort: 'a-z',
  });

  /** Function that handles when the user change the value on any select box of the filter/sort menu. */
  const handleChangeSelect = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    event.target.name && setSelectValues({ ...selectValues, [event.target.name]: event.target.value });
  }

  /** Function that opens the filter menu when the user clicks filter button. */
  const handleSort = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget );
  }

  /** Function that filters and sort users when the current user types on search input or changes filter/sort options */
  const handleFilter = (query: string) => {
    props.onSearch(query, selectValues.valueSelectColumn, selectValues.valueSelectSort)
  }

  return (
    <>
      {/* Search input and filter icon */}
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <TextField
            margin="dense"
            placeholder={`Buscar ${props.searchTitle.toLocaleLowerCase()}`}
            variant="outlined"
            fullWidth
            onChange={(e) => { handleFilter(e.target.value); setQuerySearch(e.target.value); }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <IconButton onClick={handleSort} style={{ backgroundColor: '#00709E' }}>
            <FilterIcon htmlColor='white' />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <Grid container direction="column" className="menuFilterContent" style={{paddingLeft: 10, paddingRight: 10}}>
            <Grid item>
              <InputLabel >Buscar por columna</InputLabel>
              <Select
                onChange={handleChangeSelect}
                value={selectValues.valueSelectColumn}
                className="selectColumn"
                name="valueSelectColumn"
              >
                <MenuItem value="">
                  <em>Nínguno</em>
                </MenuItem>

                <MenuItem value="name">Nombre</MenuItem>
                <MenuItem value="company">Empresa</MenuItem>
                <MenuItem value="rol">Rol</MenuItem>
              </Select>
            </Grid>

            <Grid item>
              <InputLabel >Criterio de ordenación</InputLabel>
              <Select
                onChange={handleChangeSelect}
                value={selectValues.valueSelectSort}
                name="valueSelectSort"
                className="selectColumn"
              >
                <MenuItem value="">
                  <em>Nínguno</em>
                </MenuItem>

                <MenuItem value="a-z">A a Z</MenuItem>
                <MenuItem value="z-a">Z a A</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <div className="text-center">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => setAnchorEl(null)}
                >
                  Cancelar
                </Button>

                <Button
                  className="ml-2"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAnchorEl(null);
                    handleFilter(querySearch);
                  }
                  }
                >
                  Aceptar
                </Button>
              </div>
            </Grid>
          </Grid>
        </Menu>
    </>
  )
}

export default UserControls;
