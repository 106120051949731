import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useReduxFormStyles } from '../../core/redux-form-fields/useReduxFormStyles';

function DropzoneFiles(props: any) {
  const files = props.input.value;
  const classes = useStyles();
  const fieldClasses = useReduxFormStyles();

  return (
    <Box display="flex" flexDirection="column">
      {props.label && <label className={fieldClasses.fieldLabel} style={{marginBottom: 10, marginTop: 10}}>{props.label}</label>}
      <Dropzone onDrop={( filesToUpload, e ) => props.input.onChange(filesToUpload)} >
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()} className={classes.dropzone}>


                <input type="file" {...getInputProps()} />
                {
                  files.length === 0 ?
                    <>
                      <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'normal' }}>Arrastra el documento aquí para subirlo</Typography>
                      <Typography variant="body1" color="primary" style={{ fontWeight: 'bold' }}>O</Typography>
                      <Button variant="contained" color="primary">Buscar en tus archivos</Button>
                    </>
                  : files.length > 1 ?
                      <Alert
                        variant="filled"
                        severity={`error`}
                        className={classes.alertMessage}
                      >
                        Solamente puedes seleccionar un archivo
                      </Alert>
                  :
                    (
                      <ul>
                        {files.map((file: any) => (
                          <li key={file.path} style={{listStyle: 'none'}}>
                            {file.path}
                          </li>
                        ))}
                      </ul>
                    )
                }
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  dropzone: {
    cursor: 'pointer',
    backgroundColor: '#F4F4F4',
    height: '138px',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:focus': {
      outline: 'none',
    }
  },
  alertMessage: {
    marginLeft: '27px',
    marginTop: '28px',
    marginRight: '28px'
  },
}));

export default DropzoneFiles