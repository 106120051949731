import React, { Component } from "react";

import {
  IconButton,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Onboarding from "./onboarding";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import RenderTextField from "../../core/redux-form-fields/RenderTextField";
import SafetyIndicator from "./safety-indicator";
import { AuthService } from "../../core/services";
import Alert from "@material-ui/lab/Alert";
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';

class NewPassword extends Component {
  state = {
    password: "",
    showPassword: false,
    alert: {
      message: '',
      type: ''
    }
  };

  authService = new AuthService();

  handleSubmit = (evt) => {
    evt.preventDefault();
    const {params} = this.props.match;
    const password = evt.target.elements[0].value

    this.authService.restorePassword(params.token_restore, password)
    .then((response) => {
      this.props.history.push('/')
    })
    .catch((errorResponse) => {
      
      this.setState({alert: {message: errorResponse.response.data, type: 'error'}})
      console.error(errorResponse)
    })
  }

  render() {
    const { password, alert } = this.state;

    return (
      <Onboarding>
        <Grid container maxWidth="sm">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={1}>
              <IconButton color="primary" edge="start">
                <Link className="link-color" to="/">
                  <ArrowBackIcon />
                </Link>
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <h3>Nueva contraseña</h3>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <form onSubmit={(event) => this.handleSubmit(event)}>
            
              <Grid item xs={12}>
                <Field 
                  label="Contraseña"
                  name="password"
                  type="password"
                  required
                  passwordAction
                  onKeyUp={ keyUp => this.setState({ password: keyUp }) }
                  icon={
                    <Visibility />
                  }
                  component={ RenderTextField } 
                />

              </Grid>
              <Grid item md={ 12 } xs={ 12 }>
                <Field
                  label="Confirmar contraseña"
                  name="passwordConfirm"
                  type="password"
                  required
                  passwordAction
                  icon={
                    <Visibility />
                  }
                  component={ RenderTextField }
                />

                <SafetyIndicator className="mt-3" password={ password } />
              </Grid>
              {
                alert.message &&
                  <Grid item md={ 12 } xs={ 12 }>
                    <Alert variant="filled" severity={alert.type}>
                      {alert.message}
                    </Alert>
                  </Grid>
              }
              <Grid item xs={12}>
                <Button variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  type='submit'
                >
                  Restablecer
                </Button>
              </Grid>
            </form>
          </Grid>
          <Grid item className={'mt-5 pt-3 mb-3'} xs={12}>
            <Grid container justifyContent="center" item sm={12}>
              <Typography style={{color: '#989EB4'}}>Powered by</Typography>
            </Grid>
            <Grid container justifyContent="center" item sm={12}>
              <img src={logoAxeleratum} alt="Axeleratum" className="icon-powered-by" />
            </Grid>
          </Grid>
        </Grid>
      </Onboarding>
    );
  }
}

const validate = (values) => {
  const errors = { 
    password: ''
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }

    if (values.password.length < 10) {
      errors.password = 'La contraseña no puede ser menor a 10 caracteres';
    }
  }

  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'La confirmación de contraseña es requerida';
  }

  if (values.password && values.passwordConfirm) {
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Las contraseñas deben coincidir';
    }
  }

  return errors
}


export default reduxForm({
  form: 'NewPassword',
  validate
})(NewPassword);
