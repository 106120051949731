import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/RenderTextField';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';

class RestorePassword extends Component {

  render() {
    const { handleSubmit, submitActions, onReturnSingIn, alert } = this.props;

    return (
      <form 
        className={ `${ isMobile ? 'p-5' : '' } animate__animated animate__backInUp` } 
        onSubmit={ handleSubmit(submitActions) }
      >
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={1}>
            <IconButton
              color="primary"
              edge="start"
              onClick={ () => onReturnSingIn() }
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>

          <Grid item xs={11}>
            <Typography variant="h5" color="primary">Restablecer contraseña</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>Ingresa tu correo para restablecer tu contraseña</p>
          </Grid>

          <Grid item xs={12}>
            <Field
              id="email"
              name="email"
              required={ true }
              placeholder="Correo"
              label="Correo"
              component={ RenderTextField }
            />
          </Grid>

          {
            alert.message &&
              <Grid item md={ 12 } xs={ 12 }>
                <Alert variant="filled" severity={`${alert.type}`}>
                  {alert.message}
                </Alert>
              </Grid>
          }

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth= { true }
              size="large">
              Enviar
            </Button>
          </Grid>

          <Grid item className={'mt-5 pt-3 mb-3'} xs={12}>
            <Grid container justifyContent="center" item sm={12}>
              <Typography style={{color: '#989EB4'}}>Powered by</Typography>
            </Grid>
            <Grid container justifyContent="center" item sm={12}>
              <img src={logoAxeleratum} alt="Axeleratum" className="icon-powered-by" />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

}

export const validate = (values) => {
  const errors = {
    email: ''
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  }

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
     errors.email = 'El correo electrónico es inválido';
  }

  return errors;
}

export default reduxForm({
  form: 'RestorePassword',
  validate
})(RestorePassword);
