import {
  authConstants
} from '../constants';

import {getCurrentUser} from '../helpers'

import initialState from '../../configs/intialState.config';

export function authReducer(state = initialState.session, action) {
  switch (action.type) {

    case authConstants.REFRESH_TOKEN_SUCESS:
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: !!sessionStorage.access_token,
        currentUser: !!sessionStorage.access_token ? getCurrentUser(action.payload.userId) : null
      }
    
    case authConstants.RESTORE_PASSWORD_FAILURE:
    case authConstants.SENT_EMAIL_PASSWORD:
    case authConstants.SEND_EMAIL_PASSWORD_FAILURE:
    case authConstants.SIGNUP_SUCCESS:
    case authConstants.SIGNUP_FAILURE:
    case authConstants.LOGIN_FAILURE:
      return {
        ...state
      }

    case authConstants.RESTORE_PASSWORD_SUCCESS:
      return {
        ...state
      }

    case authConstants.LOGOUT:
      return {
        loggedIn: false,
        keep_active_session: false,
        currentUser: null
      }

    case authConstants.ACTIVE_SESSION:
      return {
        ...state,
        keep_active_session: action.payload.keep_active_session
      }

    default:
      return state;

  }
}
