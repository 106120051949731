import React from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


interface ManagementItemProps {
  /** Section title */
  text: string;
  /** Icon shown at the left of the `text` prop. */
  Icon: JSX.Element;
  /** Action fired when the user clicks on the item or on the left arrow button at the right of the item. */
  onClick?: () => void ;
}

/**
 *  Item shown in Management Root screen. Its prupose is to go to the Management sections, like Users, Roles, Companies, etc.
 */
const ManagementItem = (props: ManagementItemProps) => {
  const classes = useStyles();
  return (
    <ListItem classes={{button: classes.managementItemRoot}} button onClick={props.onClick}>
      <ListItemIcon>
        {props.Icon}
      </ListItemIcon>
      <ListItemText primary={props.text} classes={{primary: classes.managementItemText}} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="next">
          <NavigateNextIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  managementItemRoot: {
    backgroundColor: 'white',
    borderRadius: 50,
    padding: '1.5rem',
    marginTop: 8,
    marginBottom: 8,

  },
  managementItemText: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

export default ManagementItem;
