import React, { useState } from 'react';
import { Field, reduxForm, getFormValues, InjectedFormProps } from "redux-form";
import { connect } from 'react-redux'
import clsx from 'clsx';
import { FormType } from '../../../pages/ManagementPage';
import { TextField, Divider, makeStyles, Grid, Button, InputLabel, Typography } from '@material-ui/core';
import DropzoneFiles from '../../general/DropzoneFiles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TemplatesHttp } from '../../../core/http/templates.http';

//@ts-ignore
import { Alert } from 'axeleratum-sgc-frontend-library';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const templatesHttp = new TemplatesHttp();


interface FormTemplateProps {
    selectedDocument?: string;
    onCancel: () => void;
    submitActions: (formData: any) => void;
    title: string;
    typeForm: FormType;
}

const FormStages = (props: InjectedFormProps<{}, FormTemplateProps> & FormTemplateProps) => {

    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
    const [flagProgress, setflagProgress] = React.useState(0);
    const [setDocumentName, setsetDocumentName] = React.useState('');
    const [numberSteps, setnumberSteps] = React.useState('');
    const [openAlertInsert, setopenAlertInsert] = useState(false);
    const [openAlertError] = useState(false);


    const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setsetDocumentName(event.target.value);
    };

    const handleChangeSteps = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setnumberSteps(event.target.value);
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const {
        onCancel,
        title,
    } = props;
    const getTypesDocument = () => {
        let JsonRequest = {
            documentHash: localStorage.getItem('documentHash'),
            fileExtension: "xslx",
            traceabilityPoints: 65,
            requiredPoints: 50,
            serviceTypeName: setDocumentName
        };
        let jsonUser = {
            email: "user-demo@qualtop.com",
            password: "1234567890"
        }
        templatesHttp.createTokenIpfs(jsonUser);

        templatesHttp.createDocumentSet(JsonRequest,
            (sucess: any) => {
                console.log(sucess)
                setopenAlertInsert(true);
            },
            (error: any) => {
                console.log(error)
                setopenAlertInsert(true);
            })
    }

    function getBase64(files: any) {
        setflagProgress(1);
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
            let base64 = reader.result as string;
            localStorage.setItem('documentHash', base64.replace(/^.+?;base64,/, ''));
        }
    }

    return (
        <form onSubmit={props.handleSubmit(getTypesDocument)}>
            <Grid container>
                <Grid item md={12}>
                    <Typography variant="h6" color="primary">{title}</Typography>
                    <div style={{ color: '#A8A8A8' }}>
                        <p>INSTRUCCIONES </p>
                        <dl><dt>Para poder dar de alta un nuevo Set de Documentos siga estos pasos:</dt>
                            <dd> 1.- Descargar la plantilla en formato Excel</dd>
                            <dd> 2.- Llene el documento con los datos necesarios de acuerdo al formato de la plantilla.</dd>
                            <dd> 3.- Asigne un nombre al Set de Documentos e indique el número de etapas necesarias.</dd>
                            <dd> 4.- Suba el archivo excel, el sistema validará que el documento no contenga errores.</dd>
                        </dl>

                        <Divider />
                        <div className="text-center" style={{ marginTop: 10, marginBottom: 10 }}>
                            <Button
                                className="ml-2"
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                href="https://drive.google.com/uc?export=download&id=1jznnX-g-FZxdNSV6-vwY-gPKAb_oJKOz"
                            >
                                Descargar Plantilla
                            </Button>
                        </div>
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="standard-name">Nombre</InputLabel>
                                    <TextField
                                        className={clsx(classes.margin, classes.textField)}
                                        id="standard-name"
                                        variant="outlined"
                                        label="Nombre"
                                        value={setDocumentName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="standard-name"># de Etapas</InputLabel>
                                    <TextField
                                        className={clsx(classes.margin, classes.textField)}
                                        id="standard-name"
                                        variant="outlined"
                                        label="# de Etapas"
                                        value={numberSteps}
                                        onChange={handleChangeSteps}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Divider />
                        <Field
                            name={'file'}
                            component={DropzoneFiles}
                            label="Set de Documentos"
                            onChange={(files: any) => {
                                if (props.typeForm === 'create') {
                                    getBase64(files);
                                }
                            }}
                        />
                        <Divider />
                        <div style={{ marginTop: 40 }}>
                            {flagProgress === 1 ? <LinearProgress variant="determinate" value={progress} /> : null}
                        </div>
                        <div className="text-right" style={{ marginTop: 40 }}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={() => onCancel()}
                            >
                                Cancelar
                            </Button>

                            <Button
                                className="ml-2"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </Grid>
               
                <Alert
                    open={openAlertInsert}
                    onConfirm={() => onCancel()}
                    title="Set de documentos creado correctamente"
                    type="default"
                />
                <Alert
                    open={openAlertError}
                    onConfirm={() => onCancel()}
                    title="Ah ocurrido un error inesperado, intente nuevamente!!!"
                    type="error"
                />
            </Grid>
        </form>
    );
}

const mapStateToProps = (state: any, props: FormTemplateProps) => ({
    values: getFormValues('FormStages')(state)
})

const connector = connect(mapStateToProps);

const form = reduxForm<{}, FormTemplateProps>({
    form: 'FormTemplate'
});


export default connector(form(FormStages))

 
