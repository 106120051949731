import React from "react";
import { Redirect, Route } from "react-router";
import ManagementPage from "../../pages/ManagementPage";
import DashboardHome from "../../pages/DashboardHomePage";
import VaultPage from "../../pages/VaultPage";
import CompaniesPage from "../../pages/CompaniesPage";
import NewCompanyFormPage from "../../pages/NewCompanyFormPage";
import CompanyDetailPage from "../../pages/CompanyDetailPage";
import CompanyServicePage from "../../pages/ServiceDocumentsPage";
import ServicesPage from "../../pages/ServicesPage";

const DashboardRouting = (props) => {
  const { match } = props;
  return (
    <>
      <Route exact path={`${match.path}/`} >
        <Redirect to={ `${match.path}/home`} />
      </Route>
      <Route exact path={ `${match.path}/home`} component={ DashboardHome } />
      <Route path={`${match.path}/vault`} >
        <VaultPage />
      </Route>
      <Route path={`${match.path}/team`} component={ ManagementPage } />
      <Route path={`${match.path}/companies/:page`} component={ CompaniesPage } />
      <Route path={`${match.path}/services`} component={ ServicesPage } />
      <Route path={`${match.path}/new/company`} component={ NewCompanyFormPage } />
      <Route path={`${match.path}/details/company/:id/:page`} component={ CompanyDetailPage } />
      <Route path={`${match.path}/details/service/:serviceId`} component={ CompanyServicePage } />
    </>
  );
};


export default DashboardRouting;
