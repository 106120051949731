import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs, Grid, Link, List, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
// import FlagIcon from '@material-ui/icons/Flag';
// import WorkIcon from '@material-ui/icons/Work';
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import ManagementItem from "../components/management/ManagementItem";
import UserControls from "../components/management/UserControls";
import IndexedSection from "../components/general/IndexedSection";
import Users from "../components/management/users/Users";
import Roles from "../components/management/roles/Roles";
import Companies from "../components/management/companies/Companies";
import Templates from "../components/management/templates/Templates";
import { useSearchParams } from "../core/hooks/useSearchParams";
import { createBrowserHistory } from "history";
import { withManagementContext } from "../core/contexts/management-context/withManagementContext";
import { ManagementContext } from "../core/contexts/management-context/ManagementContext";
import Alert from "../components/general/Alert";
import Stages from "../components/management/stages/Stages";

export type FormType = "edit" | "create";

type SectionOptions =
  | "Plantillas"
  | "Equipos"
  | "Usuarios"
  | "Roles"
  | "Empresas"
  | "Principal"
  | "Stages";

/**Management main page.*/
const ManagementPage = () => {
  const { filterRoles, filterCompanies, infoAlert } =
    useContext(ManagementContext);
  const [sectionSelected, setSectionSelected] =
    useState<SectionOptions>("Principal");
  let [searchParams, setSearchParams] = useSearchParams({ tab: "" });

  /**
   * Handler executed when the user selects a section on the main menu or when the user clicks 'Administracion'
   * laben on the breadcrumb.
   */
  const handleSectionSelected = (option: SectionOptions) => {
    setSectionSelected(option);
    setSearchParams({ tab: option });
  };

  /**
   * Function that gets tab query param from url and sets sectionSelected state variable based on query param
   * value. if 'tab' doesn't match SectionOptions type, the function will set SectionSelected value to 'Principal'
   * */
  const updateSectionFromURL = () => {
    const section = searchParams.get("tab");
    switch (section) {
      case "Equipos":
      case "Usuarios":
      case "Roles":
      case "Empresas":
      case "Stages":
        setSectionSelected(section);
        break;
      default:
        setSectionSelected("Principal");
        break;
    }
  };

  /**
   * Effect executed when Management component renders at firts time or
   * when the user clicks backward/forward browser button.
   * */
  useEffect(() => {
    updateSectionFromURL();
    //Event subscription to backward/forward browser buttons.
    const backListener = createBrowserHistory().listen((location) => {
      if (location.action === "POP") {
        updateSectionFromURL();
      }
    });
    return () => {
      /** Unsubscribe event listener */
      backListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**Handler executed when the user types on search user input at the top of the page. */
  const handleSearch = (
    query: string,
    columnValue: string,
    sortValue: string
  ) => {
    switch (sectionSelected) {
      case "Usuarios":
        break;
      case "Roles":
        filterRoles(query);
        break;
      case "Empresas":
        filterCompanies(query);
        break;
      case "Plantillas":
        console.log("Plantillas");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* header with breadcrumbs, search and filter users controls. */}
      <Grid
        container
        alignItems="center"
        style={{ marginBottom: 40, minHeight: 50 }}
      >
        <Grid item xs={12} sm md={8}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
          >
            <Link
              color="inherit"
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                handleSectionSelected("Principal");
              }}
            >
              Administración
            </Link>
            {sectionSelected !== "Principal" && (
              <Typography variant="h5" color="primary">
                {sectionSelected}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm md={4}>
          {sectionSelected !== "Principal" && (
            <UserControls
              onSearch={handleSearch}
              searchTitle={sectionSelected}
            />
          )}
        </Grid>
      </Grid>
      {/* Main menu of management section */}
      <IndexedSection value={sectionSelected} index="Principal">
        <List>
          <ManagementItem
            text="Usuarios"
            Icon={<PersonIcon />}
            onClick={() => handleSectionSelected("Usuarios")}
          />
          {/* <ManagementItem text="Roles" Icon={<FlagIcon />} onClick={() => handleSectionSelected('Roles')} />
          <ManagementItem text="Empresas" Icon={<WorkIcon />} onClick={() => handleSectionSelected('Empresas')} /> */}
          {
            <ManagementItem
              text="Set de documentos"
              Icon={<LibraryBooksIcon />}
              onClick={() => handleSectionSelected("Plantillas")}
            />
          }
          <ManagementItem
            text="Stages"
            Icon={<GroupIcon />}
            onClick={() => handleSectionSelected("Stages")}
          />
        </List>
      </IndexedSection>
      {/* Children sections. */}
      <IndexedSection value={sectionSelected} index="Usuarios">
        <Users />
      </IndexedSection>
      <IndexedSection value={sectionSelected} index="Roles">
        <Roles />
      </IndexedSection>
      <IndexedSection value={sectionSelected} index="Empresas">
        <Companies />
      </IndexedSection>
      <IndexedSection value={sectionSelected} index="Plantillas">
        <Templates />
      </IndexedSection>
      <IndexedSection value={sectionSelected} index="Stages">
        <Stages />
      </IndexedSection>
      {/* <IndexedSection value={sectionSelected} index="Equipos">Equipos</IndexedSection> */}
      <Alert {...infoAlert} />
    </>
  );
};

export default withManagementContext(ManagementPage);
