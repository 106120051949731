import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

import CounterCard from '../components/dashboard-home/CounterCard';
import { CompaniesService } from '../core/services/companies.service';
import BlockerLoadPage from '../components/general/BlockerLoadPage';
import ServicesIcon from '../components/icons/ServicesIcon';
import { useHistory } from 'react-router';
import { ServicesService } from '../core/services/services.service';

const DashboardHomePage = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [totalServicesCount, setTotalServicesCount] = useState(0);
  const [completedServices, setCompletedServices] = useState(0);
  const [pendingServices, setPendingServices] = useState(0);
  const [load, setLoad] = useState(false);
  const history = useHistory();

  const companiesService = CompaniesService.getInstance();
  const servicesService = ServicesService.getInstance();

  useEffect(() => {
    getCompaniesActive(0);
    getServicesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServicesCount = () => {
    servicesService.getServicesStats().then((res) => {
      setTotalServicesCount(res.services);
      setCompletedServices(res.completed);
      setPendingServices(res.pending)
    })
  }
  const getCompaniesActive = async (currentPage: any) => {
    companiesService.getAllCompanies(currentPage).then((data) => {

      setCompanies(data.content);
      setTotalCompanies(data.totalElements);

      let totalElements = data.totalPages;

      try {
        for (var i = 1; i <= totalElements; i++) {
          companiesService.getAllCompanies(i).then(async (data) => {
            setCompanies(companies => [...companies, ...data.content])
          }, (error) => {
            console.error(error);
          });
        }
      } catch (error) {
        console.log("e")
      } finally {
        setLoad(true)
      }
    }, (error) => {
      console.error(error);
    });
  }

  const handleClickCompanies = () => {
    history.push(`/dashboard/companies/${0}`);
  };

  const handleClickServices = () => {
    history.push(`/dashboard/services`);
  };

  return (
    <Grid container spacing={6}>
      {!load && (<BlockerLoadPage />)}
      {load && (
        <>
          <CounterCard
            totalCount={totalCompanies}
            title="Empresas"
            infoList={[
              {
                label: 'Clientes con plan premium',
                value: companies.reduce<number>((acc, comp) => comp.status === 'PREMIUM' ? ++acc : acc, 0)
              },
              {
                label: 'Clientes con plan gratuito',
                value: companies.reduce<number>((acc, comp) => comp.status === 'FREE' ? ++acc : acc, 0)
              },
              {
                label: 'Clientes con plan temporal',
                value: companies.reduce<number>((acc, comp) => comp.status === 'TEMP' ? ++acc : acc, 0)
              },
            ]}
            Icon={PersonIcon}
            onClick={handleClickCompanies}
          />
          <CounterCard
            totalCount={totalServicesCount}
            title="Servicios"
            infoList={[
              {
                label: 'Servicios validados:',
                value: completedServices
              },
              {
                label: 'Servicios pendientes de revisiòn',
                value: pendingServices
              },
            ]}
            Icon={ServicesIcon}
            onClick={handleClickServices}
          />
        </>
      )}
    </Grid>
  )
}

export default DashboardHomePage;
//export default withHomeContext(DashboardHome);

