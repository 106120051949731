import React , {useEffect}  from 'react';
import { Button, Grid, Typography, FormHelperText ,Select as MatSelect,FormControl,MenuItem, OutlinedInput} from "@material-ui/core";

import { FormType } from '../../../../pages/ManagementPage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RenderTextField from '../../../inputs/RenderTextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useReduxFormStyles } from '../../../inputs/useReduxFormStyles';
import Tooltip from '@material-ui/core/Tooltip';


interface FormUsersProps {
  companies: any[];
  actividad : any[];
  companyActivities : any[];
  onCancel: () => void;
  roles: Role[];
  onSubmit: (formData: any) => void;
  typeForm: FormType;
  userSelected: User;
  formType: FormType;
  initialValues?: Partial<any>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const FormUsers = (props: FormUsersProps) => {
  const {
    onCancel, initialValues, companyActivities
  } = props;

  const [servicesSelected, setServices] = React.useState([" "]);

 const handleChange = (event : any) => {
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setServices([" "]);
    } else {
      setServices(values.filter((e) => e !== " "));
    }
  };

 useEffect(() => {
    formik.setFieldValue("actividad", servicesSelected);
    // eslint-disable-next-line
  }, [servicesSelected]);



  const classesInput = useReduxFormStyles();
  const classes = useStyles();
    const formik = useFormik({
    initialValues: {
      nameCompany: initialValues?.name as string,
      rfc: initialValues?.rfc as string,
      nameLegal: initialValues?.nameLegal as string,
      address: initialValues?.address as string,
      cp: initialValues?.cp as string,
      country: initialValues?.country as string,
      city: initialValues?.city as string,
      colony: initialValues?.colony as string,
      curp: initialValues?.curp as string,
      website: initialValues?.website as string,
      employes: initialValues?.employes as string,
      numExt: initialValues?.numExt as string,
      numInt: initialValues?.numInt as string,
      status: initialValues?.status as string,
      actividad : initialValues?.actividad as [" "],
      sector : initialValues?.sector as string
    },
    onSubmit: props.onSubmit,
    validationSchema: Yup.object({
      nameCompany: Yup.string().required('Campo requerido'),
     // rfc: Yup.string().required('Campo requerido nombre'),
      nameLegal: Yup.string().required('Campo requerido'),
      address: Yup.string().required('Campo requerido'),
      cp: Yup.string().required('Campo requerido'),
      country: Yup.string().required('Campo requerido'),
      city: Yup.string().required('Campo requerido'),
      colony: Yup.string().required('Campo requerido'),
      curp: Yup.string().required('Campo requerido'),
     // website: Yup.string().required('Campo requerido nombre'),
      employes: Yup.number().typeError('Debes especificar un número').positive('Debe ser mayor que cero'),
      numExt: Yup.number().typeError('Debes especificar un número').positive('Debe ser mayor que cero').required('Campo requerido'),
      numInt: Yup.number().typeError('Debes especificar un número').positive('Debe ser mayor que cero'),
      status: Yup.string().required('Selecciona un plan'),
    })
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} >
          <Typography variant="h3" color="primary">{`Editar Compañia`}</Typography>
        </Grid>

        <Grid item xs={12}> <Divider /> </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="primary">{`Tipo de Plan`}</Typography>
          <RadioGroup row aria-label="position" onChange={formik.handleChange} name="status">
            <FormControlLabel value="PREMIUM" checked={formik.values.status === 'PREMIUM'} control={<Radio color="primary" />} label="Premiun" />
            <FormControlLabel value="FREE" checked={formik.values.status === 'FREE'} control={<Radio color="primary" />} label="Free" />
          </RadioGroup>
          <FormHelperText>{formik.errors.status}</FormHelperText>
        </Grid>
        <Grid item xs={12}> <Divider /> </Grid>
  
        <Grid item xs={6} className={classes.divider} >
            <RenderTextField
              id="nameCompany"
              name="nameCompany"
              error={formik.errors.nameCompany}
              label="Nombre empresa"
              disabled={true}
              onChange={formik.handleChange}
              touched={formik.touched.nameCompany}
              value={formik.values.nameCompany}
            />
        </Grid>
        <Grid item xs={6} className={classes.divider}>
            <RenderTextField
              id="rfc"
              name="rfc"
              error={formik.errors.rfc}
              label="RFC"
              disabled={true}
              onChange={formik.handleChange}
              touched={formik.touched.rfc}
              value={formik.values.rfc}
            />
        </Grid>
        <Grid item xs={6}>  
        <label className={classesInput.fieldLabel}>{"Servicios Ofrecidos"}</label>
        <Tooltip title={servicesSelected.concat(initialValues?.actividad)}>
            <FormControl
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  error={!!formik.touched.actividad && !!formik.errors.actividad}
                >
                 <MatSelect
                name="actividad"
                value={formik.values.actividad}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected: any) => {
                  return (
                    <div>
                      {selected[0] === " " && <span>Servicios ofrecidos (uno o varios) *</span>}
                      {selected.map(
                        (value: string, idx: number) =>
                          !!value &&
                          value !== " " && (
                            <span key={value}>
                              {idx > 0 && ", "}
                              {value.substr(0, 24)} {value.length > 24 && "..."}
                            </span>
                          )
                      )}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                {services.map((name) => (
                  <option
                    key={name}
                    value={name}
                    style={{
                      backgroundColor: `${servicesSelected.includes(name) ? "#3297FD" : "unset"}`,
                      color: `${servicesSelected.includes(name) ? "#fff" : "unset"}`,
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </option>
                ))}
              </MatSelect>
            </FormControl>
            </Tooltip>
         </Grid>

         <Grid item xs={6} >  
         <label className={classesInput.fieldLabel}>{"Sector de Actividad"}</label>
         <FormControl variant="outlined" color="primary" fullWidth margin="dense">
          <MatSelect
            name="sector"
            defaultValue={formik.values.sector}
            value={formik.values.sector}
            onChange={(e: any) => {
              formik.setFieldValue("sector", e.target.value);
            }}           
            >
              {companyActivities.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
          </MatSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}> <Typography variant="h6" color="primary">{`Datos Representante Legal`}</Typography></Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="nameLegal"
            name="nameLegal"
            error={formik.errors.nameLegal}
            label="Nombre representante legal"
            onChange={formik.handleChange}
            touched={formik.touched.nameLegal}
            value={formik.values.nameLegal}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="curp"
            name="curp"
            error={formik.errors.curp}
            label="Curp Representante Legal"
            onChange={formik.handleChange}
            touched={formik.touched.curp}
            value={formik.values.curp}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="address"
            name="address"
            error={formik.errors.address}
            label="Domicilio Fiscal"
            onChange={formik.handleChange}
            touched={formik.touched.address}
            value={formik.values.address}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="cp"
            name="cp"
            error={formik.errors.cp}
            label="Código postal"
            onChange={formik.handleChange}
            touched={formik.touched.cp}
            value={formik.values.cp}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="country"
            name="country"
            error={formik.errors.country}
            label="Pais"
            onChange={formik.handleChange}
            touched={formik.touched.country}
            value={formik.values.country}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="city"
            name="city"
            error={formik.errors.city}
            label="Cuidad"
            onChange={formik.handleChange}
            touched={formik.touched.city}
            value={formik.values.city}
          />
        </Grid>
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="colony"
            name="colony"
            error={formik.errors.colony}
            label="Colonia"
            onChange={formik.handleChange}
            touched={formik.touched.colony}
            value={formik.values.colony}
          />
        </Grid>
   
        <Grid item xs={6} className={classes.dividerInput}>
          <RenderTextField
            id="website"
            name="website"
            error={formik.errors.website}
            label="Sitio web"
            onChange={formik.handleChange}
            touched={formik.touched.website}
            value={formik.values.website}
          />
        </Grid>
        <Grid item xs={4} className={classes.dividerInput}>
          <RenderTextField
            id="employes"
            name="employes"
            error={formik.errors.employes}
            label="Nº empleados"
            onChange={formik.handleChange}
            touched={formik.touched.employes}
            value={formik.values.employes}
          />
        </Grid>
        <Grid item xs={4} className={classes.dividerInput}>
          <RenderTextField
            id="numExt"
            name="numExt"
            error={formik.errors.numExt}
            label="Num. ext"
            onChange={formik.handleChange}
            touched={formik.touched.numExt}
            value={formik.values.numExt}
          />
        </Grid>
       
        <Grid item xs={4} className={classes.dividerInput}>
          <RenderTextField
            id="numInt"
            name="numInt"
            error={formik.errors.numInt}
            label="Num. int"
            onChange={formik.handleChange}
            touched={formik.touched.numInt}
            value={formik.values.numInt}
          />
        </Grid>

     <Grid item xs={12}> <Divider /> </Grid>
        <Grid item xs={12} className={classes.buttonSave}>
          <div className="text-right" style={{ marginTop: 40 }}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => onCancel()}
            >
              Cancelar
            </Button>
            <Button
              className="ml-2"
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonBack: {
    paddingBottom: 10,
  },
  divider: {
    height: 'auto',
    padding: 2
  },
  dividerInput: {
    height: 'auto',
    padding: 2
  },
  dashedButton: {
    paddingBottom: 30,
  },
  buttonSave: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1.8),
    width: 435,
    borderRadius: '4px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px'
  },
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    //color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
   // color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  personSelect: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
}));

export default FormUsers;

/*var activities = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final",
  "Construcción",
  "Industrias manufactureras",
  "Comercio al por mayor",
  "Comercio al por menor",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Otro",
];*/

var services = [
  "Agricultura",
  "Cría y explotación de animales",
  "Aprovechamiento forestal",
  "Servicios relacionados con las actividades agropecuarias y forestales",
  "Pesca, caza y captura",
  "Extracción de petróleo y gas",
  "Minería de minerales metálicos y no metálicos, excepto petróleo y gas",
  "Servicios relacionados con la minería",
  "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final",
  "Edificación",
  "Construcción de obras de ingeniería civil",
  "Trabajos especializados para la construcción",
  "Industria alimentaria",
  "Industria de las bebidas y del tabaco",
  "Fabricación de insumos textiles y acabado de textiles",
  "Fabricación de productos textiles, excepto prendas de vestir",
  "Fabricación de prendas de vestir",
  "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos",
  "Industria de la madera",
  "Industria del papel",
  "Impresión e industrias conexas",
  "Fabricación de productos derivados del petróleo y del carbón",
  "Industria química",
  "Industria del plástico y del hule",
  "Fabricación de productos a base de minerales no metálicos",
  "Industrias metálicas básicas",
  "Fabricación de productos metálicos",
  "Fabricación de maquinaria y equipo",
  "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos",
  "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica",
  "Fabricación de equipo de transporte",
  "Fabricación de muebles, colchones y persianas",
  "Otras industrias manufactureras",
  "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por mayor de productos textiles y calzado",
  "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca",
  "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho",
  "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general",
  "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones",
  "Intermediación de comercio al por mayor",
  "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por menor de artículos de ferretería, tlapalería y vidrios",
  "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal",
  "Comercio al por menor de artículos para el cuidado de la salud",
  "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados",
  "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado",
  "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes",
  "Comercio al por menor en tiendas de autoservicio y departamentales",
  "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares",
  "Transporte aéreo",
  "Transporte por ferrocarril",
  "Transporte por agua",
  "Autotransporte de carga",
  "Transporte terrestre de pasajeros, excepto por ferrocarril",
  "Transporte por ductos",
  "Transporte turísticas",
  "Servicios relacionados con el transporte",
  "Servicios postales",
  "Servicios de mensajería y paquetería",
  "Servicios de almacenamiento",
  "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión",
  "Industria fílmica y del video, e industria del sonido",
  "Radio y televisión",
  "Telecomunicaciones",
  "Procesamiento electrónico de información, hospedaje y otros servicios relacionados",
  "Otros servicios de información",
  "Banca central",
  "Actividades bursátiles, cambiarias y de inversión financiera",
  "Compañías de seguros, fianzas, y administración de fondos para el retiro",
  "Instituciones de intermediación crediticia y financiera no bursátil",
  "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión",
  "Servicios inmobiliarios",
  "Servicios de alquiler de bienes muebles",
  "Servicios de alquiler de marcas registradas, patentes y franquicias",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios",
  "Limpieza",
  "Servicios de mantenimiento",
  "Protección y Seguridad",
  "Manejo de residuos y servicios de remediación",
  "Servicios educativos",
  "Servicios médicos de consulta externa y servicios relacionados",
  "Hospitales pertenecientes al sector privado",
  "Residencias de asistencia social y para el cuidado de la salud",
  "Otros servicios de asistencia social",
  "Servicios artísticos, culturales y deportivos, y otros servicios relacionados",
  "Museos, sitios históricos, zoológicos y similares",
  "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos",
  "Servicios de alojamiento temporal",
  "Servicios de preparación de alimentos y bebidas",
  "Servicios de reparación y mantenimiento",
  "Servicios personales",
  "Asociaciones y organizaciones",
  "Hogares con empleados domésticos",
  "Otro",
];
