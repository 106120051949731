import { useEffect, useState } from "react";
import { BusinessHttp } from "../../../http/business.http";

export const useHandleCompanies = () => {
  const [companies, setCompanies] = useState<Company[]>([]); // List of companies retreived by the back-end
  const [filteredCompanies, setFilteredCompanes] = useState<Company[]>([]); // Filtered companies if search input has a value different from empty string.
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const httpCompanies = new BusinessHttp();

  useEffect(() => {
    getCompanies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanies = () => {
    if (companies.length === 0) {
      setLoadingCompanies(true);
      httpCompanies.findAllBusiness(
        (resp: any) => {
          setCompanies(resp);
          setFilteredCompanes(resp);
          setLoadingCompanies(false);
        }, console.log);
    }
  }

  const filterCompanies = (query: string) => {
    const result = query !== '' ? companies.filter(company => company.name.includes(query)) : companies
    setFilteredCompanes(result);
  }

  return {
    companies: filteredCompanies,
    loadingCompanies,
    getCompanies,
    filterCompanies
  }
}