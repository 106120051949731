import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useHandleBackHistory } from '../core/hooks/useHandleBackHistory';
import ServicesTable from '../components/management/company/DetailsCompany/ServicesTable';
import { useHistory } from 'react-router';
import { ServicesService } from '../core/services/services.service';
import { useSearchDebounce } from '../core/hooks/useDebounce';

const ServicesPage = () => {
  const [services, setServices] = useState<ServiceCompany[]>([]);
  const history = useHistory();
  const handleClickBack = useHandleBackHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [search, setSearch] = useSearchDebounce(600);

  const servicesService = ServicesService.getInstance();

  useEffect(() => {
    servicesService.listServices(page, pageSize, search).then((servicesRes) => {
      setTotalElements(servicesRes.totalElements)
      const mappedServices = servicesRes.content.map<ServiceCompany>(({ providerName, lastQuery, ...service}) =>( {
        ...service,
        collaboratorName: providerName,
        lastQuery: new Date(lastQuery)
      }));

      setServices(mappedServices);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, search]);

  const handleClickService = (serviceId: string) => {
    history.push(`/dashboard/details/service/${serviceId}`);
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (query: string) => {
    setSearch(query.length > 0 ? query : undefined);
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={handleClickBack}
          >
            Regresar
          </Button>
        </Grid>
        <ServicesTable
          services={services}
          onClickService={handleClickService}
          page={page}
          count={totalElements}
          pageSize={pageSize}
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
          onChangeFilter={handleSearch}
        />
      </Grid>
    </>
  )
}

export default ServicesPage