import { useEffect, useState } from 'react';
import { useDownloadDocument } from './useDownloadDocument';

export const useGetDocument = (documentId: string, getOnBuildComponent?: boolean) => {
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [fileMIME, setFileMIME] = useState<string | false>('');
  const [fileName, setFileName] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [fileStatus, setFileStatus] = useState('Cargando documento...');
  const downloadDocument = useDownloadDocument();

  useEffect(() => {
    if (getOnBuildComponent) getDocument();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocument = () => {
    downloadDocument(documentId)
      .then((info) => {
        setFileName(info.name);
        setFileMIME(info.MIME);
        setFileBlob(info.blob);
        setFileURL(info.url);
        setFileStatus('Documento cargado.');
      })
      .catch(() => {
        setFileStatus('El documento no se pudo cargar.');
      });
  };

  return { fileName, fileBlob, fileMIME, fileStatus, fileURL, ...(getOnBuildComponent ? {} : { getDocument }) };
};
