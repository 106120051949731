import { StagesTableProps } from "../../components/management/company/DetailsCompany/StagesTable";
import { Stage } from "../../types/service-dashboard.interface";

/**
 * Converts a file to a Base64
 * @param file File obtained from an input
 * @returns Base 64 representation of ´file´.
 */
 export const toBase64 = (file: File) =>
 new Promise<string>((resolve, reject) => {
   const reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = () => {
     const result = (reader.result as string).replace(/^.+?;base64,/, '')
     resolve(result);
   };
   reader.onerror = (error) => {
     reject(error);
   };
 });


 export const mapDashboardToTable = (stages: Stage[]): StagesTableProps[] => {
  return stages.map<StagesTableProps>((stage) => {
    let deliverables: Deliverable[] = [];
    stage.groups.forEach((group) => {
      group.requirements.forEach((requirement) => {
        const reqDel = requirement.documents?.map<Deliverable>((document) => {
          return {
            id: document.uid,
            documentId: document.fileId,
            requirementUID: requirement.uid,
            name: requirement.name,
            documentName: document.name,
            category: group.name,
            month: document.month ?? '-',
            uploadDate: new Date(document.createdAt),
            status: document.validationStatus.status,
            revisionDate: document.validationStatus.updatedAt ? new Date(document.validationStatus.updatedAt) : undefined
          }
        }) || [];
        deliverables.push(...reqDel);
      })
    });
    return {
      title: stage.name,
      deliverables
    }
  })
}

export const mapDeliverable = (document: DocumentStage): Deliverable => ({
  category: document.category,
  documentId: document.documentId,
  documentName: document.documentName,
  month: document.month,
  status: document.status,
  id: document.UUID,
  name: document.requirementName,
  requirementUID: document.requirementUUID,
  uploadDate: new Date(document.createdAt),
  revisionDate:  document.validationDate ? new Date(document.validationDate) : undefined,
});


