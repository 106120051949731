import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

//@ts-ignore
import { AlertConfirm } from "axeleratum-sgc-frontend-library";
import { Button, Grid, Typography } from "@material-ui/core";
import AlertInfo from "@material-ui/lab/Alert";
import { alertActions, usersActions, authActions } from "../../../core/actions";
import FormTemplate from "./FormStages";
import { useHandleDialog } from "../../../core/hooks/useHandleDialog";
import { FormType } from "../../../pages/ManagementPage";
import { StagesHttp } from "../../../core/http/stages.http";

type PropsFromRedux = ConnectedProps<typeof connector>;

const StageItem = ({ stage }: any) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "72px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2rem",
      }}
    >
      <Typography variant="h5" color="primary">
        {stage.folderName}
      </Typography>

      <div>
        <Button variant="outlined" color="primary">
          Eliminar
        </Button>
      </div>
    </div>
  );
};

const Stages = (props: PropsFromRedux) => {
  // eslint-disable-next-line
  const [MaterialityDialog, handleShowModal, handleCloseModal] =
    useHandleDialog();
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [dialogTitle] = useState("");
  const [typeForm] = useState<FormType>("create");
  const [stages, setStages] = useState<DocumentSet[]>([]); // List of companies retreived by the back-end
  const [loading, setLoading] = useState(false);
  const stagessHttp = new StagesHttp();

  useEffect(() => {
    getElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSave = (data: any) => {
    console.log("submitSave");
  };

  const getElements = async () => {
    setLoading(true);
    await stagessHttp.findAllStages((data: any) => {
      console.log("📌 ~ data", data);
      setStages(data?.respuesta);
      setLoading(false);
    });
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "!00%",
            gap: "1rem",
          }}
        >
          {stages?.map((stage) => (
            <StageItem stage={stage} key={stage.id} />
          ))}
        </div>

        {loading && (
          <Grid container style={{ justifyContent: "center", width: "100%" }}>
            <AlertInfo severity="info">Cargando stages...</AlertInfo>
          </Grid>
        )}
      </div>

      <MaterialityDialog>
        <FormTemplate
          onCancel={() => handleCloseModal()}
          submitActions={(formData: any) => submitSave(formData)}
          title={dialogTitle}
          typeForm={typeForm}
        />
      </MaterialityDialog>

      <AlertConfirm
        open={openAlertDelete}
        onCancel={() => setOpenAlertDelete(false)}
        textContent="El usuario será eliminado. ¿Deseas continuar?"
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    alert: state.alertReducer,
    users: state.userReducer.usersFiltered,
    fetchingUsers: state.userReducer.fetchingUsers,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  findAllUsers: usersActions.findAll,
  closeAlert: alertActions.close,
  createUser: usersActions.createNewUser,
  editUser: usersActions.editUser,
  blockUser: usersActions.blockUser,
  deleteUser: usersActions.deleteUser,
  assignRoles: usersActions.assignRoles,
  logout: authActions.logout,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Stages);
