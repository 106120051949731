import {getCurrentUser} from '../core/helpers'

const userInitialState = {
  users: {
    users: [],
    user: null,
    usersFiltered: [],
    editing: false,
    creating: false,
    deleting: false,
    blocking: false,
    fetchingUsers: false
  },
  templates: [],
  session: {
    loggedIn: !!sessionStorage.access_token,
    keep_active_session: false,
    currentUser: !!sessionStorage.access_token ? getCurrentUser(sessionStorage.userId) : null,
  },
  alert: {}
}

export default userInitialState;