import { useState } from "react";
import { AlertProps } from "../../../../components/general/Alert";
import { UserService } from "../../../services/user.service";

export const useHandleUsers = (setInfoAlert: (dialogInfo: Omit<AlertProps, 'onCancel'> & { addCancelButton?: boolean }) => void) => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const userService = UserService.getInstance();

  const listUsers = () => {
    setLoadingUsers(true);
    userService.listUsers().then((usersRes) => {
      console.log("userd data",usersRes.data);
      setUsers(usersRes.data);
      setFilteredUsers(usersRes.data);
    }).finally(() => setLoadingUsers(false))
  }

  const filterUsers = (query: string) => {
    const result = query !== '' ? users.filter(user => `${user.name} ${user.lastName}`.includes(query)) : users;
    setFilteredUsers(result);
  }

  const createUser = async (formData: any) => {

    return new Promise<boolean>((resolve, reject) => {
      userService.createUser(formData).then((user) => {
        const newUsers = [...users];
        newUsers.push(user);
        setUsers(newUsers);
        setFilteredUsers(newUsers);
        setInfoAlert({
          open: true,
          title: 'Usuario creado correctamente',
          type: 'success',
        });
        resolve(true);
      })
      .catch((error) => {
        setInfoAlert({
          open: true,
          title: error.response.status === 409 ? 'El usuario ya existe' : error.response.data.message,
          type: 'error',
        });
        reject(false);
      });
    })
  }

  const editUser = (formData: EditUserRequestBody) => {
    return new Promise<boolean>((resolve, reject) => {
      userService.editUser(formData).then((response) => {
        const newUsers = [...users];
        const newUserI = newUsers.findIndex((user) => user.id === formData.id);
        newUsers[newUserI] = {
          ...newUsers[newUserI],
          ...formData
        };
        setUsers(newUsers);
        setFilteredUsers(newUsers);
        setInfoAlert({
          open: true,
          title: response.message,
          type: 'success',
        });
        resolve(true);
      })
      .catch((error) => {
        setInfoAlert({
          open: true,
          title: error.response.data.message,
          type: 'error',
        });
        reject(false);
      });
    })
  }

  const deleteUser = (userId: string) => {
    userService.deleteUser(userId).then(() => {
      const newUsers = users.filter((user) => user.id !== userId);
      setUsers(newUsers);
      setFilteredUsers(newUsers);
      setInfoAlert({
        open: true,
        title: 'Usuario borrado correctamente',
        type: 'success',
      });
    })
    .catch((error) => {
      setInfoAlert({
        open: true,
        title: 'El usuario ya no se pudo eliminar',
        type: 'error',
      });
    });
  }

  return {
    listUsers,
    filterUsers,
    createUser,
    editUser,
    deleteUser,
    users: filteredUsers,
    loadingUsers
  }
};