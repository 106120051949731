import { userConstants } from '../constants'
import { UserHttp } from '../http/user.http'
import { alertActions } from './alert.actions'

export const usersActions = {
  findAll,
  filterUsers,
  createNewUser,
  editUser,
  blockUser,
  deleteUser,
  assignRoles,
  sortUsers
}

const userhttp = new UserHttp()

function findAll() {
  return dispatch => {
    dispatch({type: userConstants.FETCH_USERS})
    
    userhttp.findAll((data) => {
      dispatch({
        type: userConstants.FETCH_USERS_SUCCESS,
        payload: data
      })
    }, error => {
      dispatch({
        type: userConstants.FETCH_USERS_SUCCESS
      })
      dispatch(alertActions.error('Ocurrió un error de conexión con los datos'))
      console.error(error)
    })
  }
}

function filterUsers(users, query, typeColumn, typeSort){
  return (dispatch) => {
    let usersFiltered;

    switch (typeColumn) {
      case 'name':
        usersFiltered = users.filter(user => {
          return user.completeName ? (user.completeName.toLowerCase().indexOf(query.toLowerCase()) !== -1) : (user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        })
        break;

      case 'company':
        usersFiltered = users.filter(user => {
          return user.companies.filter(company => (company.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)).length > 0;
        })
        break

      case 'rol':
        usersFiltered = users.filter(user => {
          return user.roles.filter(role => (role.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)).length > 0;
        })
        break

      default:
        usersFiltered = users
        break;
    }

    if (typeSort !== '') {
      usersFiltered = sortUsers(usersFiltered, typeSort)
    }

    dispatch({
      type: userConstants.FILTER_USERS_SUCCESS,
      payload: (query === '' ?  users : usersFiltered)
    })
  }
}

function sortUsers(users, typeSort) {
  let sortedUsers

  if (typeSort === 'a-z') {
    sortedUsers = users.sort((x,y) => {
      let a = x.username.toLowerCase().charAt(0),
      b = y.username.toLowerCase().charAt(0);
      return a === b ? 0 : a > b ? 1 : -1;
    })
    
  } else if (typeSort === 'z-a') {
    sortedUsers = users.sort((x, y) => {
      let a = x.username.toLowerCase().charAt(0),
      b = y.username.toLowerCase().charAt(0);
      return a === b ? 0 : a < b ? 1 : -1;
    });
  }

  return sortedUsers
}

function createNewUser(user) {
  return dispatch => {
    dispatch({
      type: userConstants.CREATE_NEW_USER
    })
    
    userhttp.createUser(user, (data)=> {
      dispatch({
        type: userConstants.CREATE_NEW_USER_SUCCESS,
        payload: data
      })
      dispatch(alertActions.success('Usuario creado correctamente'))
    }, error => {
      dispatch({
        type: userConstants.EDIT_USER_FAILURE
      })
      if (error.response.status === 400) {
        dispatch(alertActions.error(error.response.data))
      }else {
        dispatch(alertActions.error('Ocurrió un error al crear el usuario'))
      }
      
      console.error(error)
    })
  }
}

function editUser(user, id) {
  return dispatch => {
    dispatch({
      type: userConstants.EDIT_USER
    })

    userhttp.editUser(user, id, (data)=> {
      dispatch({
        type: userConstants.EDIT_USER_SUCCESS
      })
      dispatch(alertActions.success('Usuario editado correctamente'))
    }, error => {
      dispatch({
        type: userConstants.EDIT_USER_FAILURE
      })
      dispatch(alertActions.error('Ocurrió un error al editar el usuario'))
      console.error(error)
    })
  }
}

function blockUser(blocked,id) {
  return dispatch => {
    dispatch({type: userConstants.DISABLE_USER})

    userhttp.blockUser({blocked, id}, data => {
      dispatch({type: userConstants.DISABLE_USER_SUCCESS})
      dispatch(alertActions.success(`Usuario ${blocked ? 'Bloqueado' : 'Desbloqueado'} correctamente`))
    }, error => {
      dispatch({type: userConstants.DISABLE_USER_FAILURE})
      dispatch(alertActions.error('Error al intentar la acción'))
    })
  }
}

function deleteUser(id) {
  return dispatch => {
    dispatch({type: userConstants.DELETE_USER})

    userhttp.deleteUser({id}, data => {
      dispatch({type: userConstants.DELETE_USER_SUCCESS})
      dispatch(alertActions.success(`Usuario eliminado correctamente`))
    }, error => {
      dispatch({type: userConstants.DELETE_USER_FAILURE})
      dispatch(alertActions.error('Error al intentar la acción'))
    })
  }
}

function assignRoles(id, roles) {
  return dispatch => {
    dispatch({type: userConstants.ASSIGN_ROLES})

    userhttp.assignRoles({id, roles}, data => {
      dispatch({type: userConstants.ASSIGN_ROLES_SUCCES})
    }, error => {
      dispatch({type: userConstants.ASSIGN_ROLES_FAILURE})
      dispatch(alertActions.error('Ocurrió un error al asignar los roles'))
    })
  }
}

// function get(id) {
//   return {type: 'alertConstants.INFO', payload: message}
// }

// function disable(id) {
//   return {type: 'alertConstants.WARNING', payload: message}
// }

// function deleteUser(id) {
//   return {type: 'alertConstants.ERROR', payload: message}
// }

// function editUser(id, params) {
//   return {type: alertConstants.CLOSE_ALERT}
// }