import { axiosCompanyRequest, axiosProviderRequest, authHeader, axiosRequestBlockChain } from '../../configs/axios.config';

export class ProviderHttp {

    findAllProjects(success, error) {
        axiosCompanyRequest.get('/projects', { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    deleteProject(id, success, error) {
        axiosCompanyRequest.delete(`/projects/${id}`, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    findAllUsers(success, error) {
        axiosProviderRequest.get('/users', { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    async findUsersById({ id }, success, error) {
        await axiosProviderRequest.get(`/users/${id}`, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => error(errorResp));
    }

    findServicesByUser(success, error) {
        axiosCompanyRequest.get(`/services/getServices/byUser`, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => error(errorResp));
    }

    findAllServices(success, error) {
        axiosCompanyRequest.get('/services', { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    findServicesByProviderId(id, success, error) {
        axiosCompanyRequest.get(`/services/getServices/byProvider/${id}`, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    deleteService(id, success, error) {
        axiosCompanyRequest.delete(`/services/${id}`, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(respErr => error(respErr))
    }

    findAllProviders(success, error) {
        axiosProviderRequest.get('/providers', { headers: authHeader() })
            .then(({ data }) => {
                success(data);
            }).catch((respErr) => {
                error(respErr);
            });
    }

    findActiveProviders(success, error) {
        axiosProviderRequest.get('/providers/active', { headers: authHeader() })
            .then(({ data }) => {
                success(data);
            }).catch((respErr) => {
                error(respErr);
            });
    }

    findproviderById = (id, success, error) => {
        axiosProviderRequest.get(`/providers/${id}`, { headers: authHeader() })
            .then((response) => { success(response.data) })
            .catch((respErr) => error(respErr))
    }

    saveProject(project, success, error) {
        const filteredProject = {
            name: project.name,
            suppliers: project.providers?.map(({ id }) => id),
            services: []
        }
        project.providers?.forEach(({ services }) => {
            if (services) {
                services.forEach(({ id }) => {
                    if (id) {
                        filteredProject.services.push(id)
                    }
                })
            }
        })
        axiosCompanyRequest.post('/projects', filteredProject, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    updateProject(project, success, error) {
        const filteredProject = {
            name: project.name,
            suppliers: project.providers.map(({ id }) => id),
            services: []
        }
        project.providers.forEach(({ services }) => {
            if (services) {
                services.forEach(({ id }) => {
                    if (id) {
                        filteredProject.services.push(id)
                    }
                })
            }
        })
        axiosCompanyRequest.put(`/projects/${project.id}`, filteredProject, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    saveProvider({ name, providerType, users, admin = 0, rfc, companyId, rootFolderId }, success, error) {
        const usersInfo = !users ? '' : users.map(({ name, email, rfc, curp }, index) => {
            return { id: null, name, email, isProviderAdmin: admin === index, rfc, curp }
        })
        console.log({ name, providerType, users, admin, rfc, companyId })
        const filteredProvider = {
            name: name,
            providerType: providerType,
            usersInfo,
            companyId: companyId,
            rootFolderId: rootFolderId,
            rfc
        }
        axiosProviderRequest.post('/providers', filteredProvider, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {

                axiosRequestBlockChain.delete(`/v1/folders/${filteredProvider.rootFolderId}`, { headers: authHeader() })
                    .then(({ data }) => success(data))
                    .catch(errorResp => {
                    });

                const message = errorResp.response.data.status === "500" ? ("Hubo un error Intente de nuevo" || "Agregue un representante legal") : errorResp.message
                error({ message })
                console.log("error", message);
            });
    }

    updateProvider({ name, providerType, users, admin, id, rfc }, success, error) {
        const usersInfo = users.map(({ name, email, id, rfc, curp }, index) => {
            return { id, name, email, rfc, curp, isProviderAdmin: admin === index }
        })
        console.log({ name, providerType, users, admin, id, rfc })
        const filteredProvider = {
            name: name,
            rfc,
            providerType: providerType,
            usersInfo,
        }
        axiosProviderRequest.put(`/providers/${id}`, filteredProvider, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    addUserToProvider(payload, success, error) {
        axiosProviderRequest.put(`/providers/${payload.id}`, payload, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    deleteProvider(id, success, error) {
        axiosProviderRequest.delete(`/providers/${id}`, { headers: authHeader() }, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    toggleEnableprovider({ id, enabled }, success, error) {
        axiosProviderRequest.put(`/providers/enabled/${id}`, {
            enabled,
        }, { headers: authHeader() })
            .then(({ data }) => success(data))
            .catch(errorResp => {
                const message = errorResp.response.data ? (errorResp.response.data.message || errorResp.message) : errorResp.message
                error({ message })
            });
    }

    getCountProvidersInProcess(success, error) {
        axiosProviderRequest.get(`/providers/getCountProvidersInProcess`, { headers: authHeader() })
            .then((response) => { success(response.data) })
            .catch((respErr) => error(respErr))
    }
    getCountProvidersHire(success, error) {
        axiosProviderRequest.get(`/providers/getCountProvidersHire`, { headers: authHeader() })
            .then((response) => { success(response.data) })
            .catch((respErr) => error(respErr))
    }
    getCountContractsToExpire(success, error) {
        axiosProviderRequest.get(`/providers/getCountContractsToExpire`, { headers: authHeader() })
            .then((response) => { success(response.data) })
            .catch((respErr) => error(respErr))
    }

}

// 60dab2e87f686a2db6dffee6