/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  Radio,
  Backdrop,
  CircularProgress,
  Select as MatSelect,
  // FormControl,
  // OutlinedInput,
  FormHelperText,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import TextField from "../../../inputs/inputs-v2/RenderTextFieldV2";
import Select from "../../../inputs/inputs-v2/RenderSelectV2";
import { useFormik } from "formik";
import { CompaniesService } from "../../../../core/services/companies.service";
//import { UserService } from "../../../../core/services/user.service";
import RadioGroup from "@material-ui/core/RadioGroup";
import { AdditionalProduct } from "../../../../types/global";
import { AlertModal } from "../../../dashboard-home/provider-services/AlertModal";
import OrkestaMediaCells from "../Orkestamedia/OrkestaMediaCells";

const curpReg = new RegExp(
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
);
const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

type personType = "moral" | "fisica";

type FormFields = {
  platform?: string;
  personType: personType | "";
  name?: string;
  rfc: string;
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  postalCode: string;
  country: string;
  legalAgentName: string;
  legalAgentCURP: string;
  webSite?: string;
  sector: string;
  employesNumber: string;
  services: string[];
  status: string;
  adminUserName: string;
  admiName: string;
  adminEmail: string;
  adminRfc: string;
  groupId?: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FormNewUserData() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isGroup, setIsGroup] = useState(false);
  const [isGroupChildren, setIsGroupChildren] = useState(false);

  const [companyGroups, setCompanyGroups] = useState([]);
  const [additionalProducts, setAdditionalProducts] = useState<AdditionalProduct[]>([]);
  const [additionalProductsSelected, setAdditionalProductsSelected] = useState<string[]>([]);

  const [alertDeleteError, setAlertDeleteError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [ isSelectedOrkestaMedia, setisSelectedOrkestaMedia ] = useState( false );

  const handleChange = (event: any) => {
    console.log("event",event)
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setAdditionalProductsSelected([" "]);
    } else {
      setAdditionalProductsSelected(values.filter((e) => e !== " "));
    }
  };

  const handleSubmit = async (values: FormFields) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
    if (isGroupChildren && !values.groupId) {
      alert("Selecciona un grupo");
      return;
    }

    const body = {
      // id: userInfo?.companies[0],
      name: values.name,
      type: values.personType,
      rfc: values.rfc,
      taxResidence: {
        street: values.street,
        outdoorNum: values.exteriorNumber,
        colonia: values.colony,
        cp: values.postalCode,
        interiorNumber: values.interiorNumber,
        city: values.city,
        country: values.country,
      },
      user: {
        username: values.adminEmail,
        name: values.admiName,
        rfc: values.adminRfc,
        email: values.adminEmail,
        roles: ["Admin"],
        status: true,
      },
      platform: values.platform,
      sector: "",
      employeesSize: values.employesNumber,
      web: values.webSite,
      legalUserName: values.legalAgentName,
      legalUserCurp: values.legalAgentCURP,
      servicesOffered: "",
      enable: true,
      legalEmail: userInfo.email,
      status: values.status,
      phone: userInfo.phone,
      group: isGroup,
      groupId: isGroupChildren ? values.groupId : "",
    };

    //console.log(JSON.stringify(body, null, 2));
    
    setLoading(true);
    try {
      const res = await CompaniesService.getInstance().createCompanies(body);
      console.log("📌 ~ res", res);
      if (res?.id) {
        // await updateUser();
        if (additionalProductsSelected.length > 0) {
          //const pName = additionalProductsSelected[0] || "";

          // fix filtrar sobre los productos recibidos y los proudctos seleccionados
          const products = additionalProducts
              .filter(item => additionalProductsSelected.includes(item.name))
              .map(item => item.id);
         // const products = additionalProducts.filter((p: any) => p.name === pName)?.map(p=>p.id)!;
          const saveAdditionalProductsRes = await CompaniesService.getInstance().saveAdditionalProducts(res.id, products);
          if (saveAdditionalProductsRes?.codigo !== 0) {
            //alert("Error al guardar los productos adicionales");
            setErrorMessage("Error al guardar los productos adicionales");
            setIsError(true);
            setAlertDeleteError(true);
            return;
          }
        }
        setLoading(false);
        setErrorMessage("Se creó la empresa con éxito");
        setIsError(false);
        setAlertDeleteError(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error("Error:", error); // Asegúrate de que el error se registre en la consola
      if (error instanceof Error) {
        setErrorMessage(error.message || "Ocurrió un error inesperado");
      } else {
        setErrorMessage("Ocurrió un error inesperado");
      }
      setIsError(true);
      setAlertDeleteError(true);
    }

    setLoading(false);
  };

  function generateValidRFC() {
    const now = new Date();
    
    const year = now.getFullYear().toString().substr(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const homoclave = Math.random().toString(36).substring(2, 5).toUpperCase(); // Generar una cadena aleatoria de 3 caracteres
  
    return `AAA${year}${month}${day}${homoclave}`;
  }
  
  function generateValidCURP() {
    const now = new Date();
    
    const year = now.getFullYear().toString().substr(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const sex = "H"; 
    const state = "DF"; 
    const verifier = "09"; 
  
    const internalConsonants = getInternalConsonants("GARC", "MENE", "JUAN");
  
    return `GARC${"MENE"}${"J"}${year}${month}${day}${sex}${state}${internalConsonants}${verifier}`;
  }
  
  function getInternalConsonants(firstSurname : string, secondSurname: string, name : string) {
    const internalConsonants = `${firstSurname[1]}${secondSurname[1]}${name[1]}`;
    return internalConsonants.toUpperCase();
  }
  

  const formik = useFormik<FormFields>({
    initialValues: {
      platform: "KIVIT",
      personType: "moral",
      rfc: generateValidRFC(),
      name: "Nombre",
      street: "Calle",
      exteriorNumber: "12",
      interiorNumber: "",
      colony: "Colonia",
      city: "Cuidad",
      postalCode: "2323",
      country: "Estado",
      legalAgentName: " Nombre Legal",
      legalAgentCURP: "GOTA540308MNELTN43",
      webSite: "",
      sector: "Sector",
      employesNumber: "",
      services: [" "],
      status: "FREE",
      adminUserName: "Admin user name",
      admiName: "Admin Name",
      adminEmail: "admin@yompmail.com",
      adminRfc: generateValidRFC(),
      groupId: "",
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      rfc: Yup.string().matches(rfcReg, { message: "Formato inválido" }).required("Campo requerido"),
      street: Yup.string().required("Campo requerido"),
      exteriorNumber: Yup.string(),
      interiorNumber: Yup.string(),
      colony: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      postalCode: Yup.string().required("Campo requerido"),
      country: Yup.string().required("Campo requerido"),
      legalAgentName: Yup.string().required("Campo requerido"),
      legalAgentCURP: Yup.string().matches(curpReg, { message: "Formato inválido" }).required("Campo requerido"),
      webSite: Yup.string(),
      // sector: Yup.string().required("Campo requerido"),
      employesNumber: Yup.string().required("Campo requerido"),
      // services: Yup.array(Yup.string().min(2, "Seleccione almenos 1 servicio")).required("Campo requerido"),
      admiName: Yup.string().required("Campo requerido"),
      adminEmail: Yup.string().required("Campo requerido"),
      adminRfc: Yup.string().required("Campo requerido"),
    }),
  });

  useEffect(() => {
    CompaniesService.getInstance()
      .getCompaniesGroups()
      .then((res) => {
        console.log(res);
        if (res?.codigo === 0) {
          setCompanyGroups(res.respuesta);
        }
      });

    CompaniesService.getInstance()
      .getAllAdditionalProducts()
      .then((res) => {
        console.log(res);
        if (res?.codigo === 0) {
          setAdditionalProducts(res.respuesta);
        }
      });
  }, []);

  const handleConfirm = () => {
    setAlertDeleteError(false);
    if (!isError) {
      history.push('/dashboard/companies/0');
    }
  };

  function changeOrkestaMedia() {
    /** */
    setisSelectedOrkestaMedia( true );
    formik.values.status = "PREMIUM";
  }

  return (
    <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center"></Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="primary">{`Plataforma`}</Typography>
            <RadioGroup row aria-label="position" onChange={formik.handleChange} name="platform">
              <FormControlLabel
                value="KIVIT"
                checked={formik.values.platform === "KIVIT"}
                control={<Radio color="primary" />}
                label="Kivit"
                onChange={ () => setisSelectedOrkestaMedia( false ) }
              />
              <FormControlLabel value="SCM" checked={formik.values.platform === "SCM"} control={<Radio color="primary" />} label="SCM"
                onChange={ () => setisSelectedOrkestaMedia( false ) }
              />
              <FormControlLabel
                value="MATERIALIDAD"
                checked={formik.values.platform === "MATERIALIDAD"}
                control={<Radio color="primary" />}
                label="Materialidad fiscal"
                onChange={ () => setisSelectedOrkestaMedia( false ) }
              />
              <FormControlLabel
                value="ORKESTAMEDIA"
                checked={formik.values.platform === "ORKESTAMEDIA"}
                control={<Radio color="primary" />}
                label="Orkesta Media"
                onChange={changeOrkestaMedia}
              />
            </RadioGroup>
            <FormHelperText>{formik.errors.platform}</FormHelperText>
          </Grid>

          {/* OrkestaMedia Cells */}
          {isSelectedOrkestaMedia && (
            <div className={classes.borderContainer}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">{`Células`}</Typography>
                <OrkestaMediaCells />
              </Grid>
            </div>
          )}

          <Grid item xs={12}>
            <Typography variant="h6" color="primary">{`Identidad`}</Typography>
            <RadioGroup row aria-label="position" onChange={formik.handleChange} name="personType">
              <FormControlLabel
                value="moral"
                checked={formik.values.personType === "moral"}
                control={<Radio color="primary" />}
                label="Persona moral"
              />
              <FormControlLabel
                value="fisica"
                checked={formik.values.personType === "fisica"}
                control={<Radio color="primary" />}
                label="Persona física"
              />
            </RadioGroup>
            <FormHelperText>{formik.errors.personType}</FormHelperText>
          </Grid>

          <div className={classes.subContainer}>
            <TextField
              disabled={formik.values.personType === ""}
              error={formik.errors.name}
              onChange={formik.handleChange}
              touched={formik.touched.name}
              value={formik.values.name}
              placeholder={formik.values.personType === "fisica" ? "Nombre completo *" : "Razón social *"}
              name="name"
            />
            <TextField
              error={formik.errors.rfc}
              onChange={formik.handleChange}
              touched={formik.touched.rfc}
              value={formik.values.rfc}
              placeholder="RFC *"
              name="rfc"
            />
            <Typography variant="h6" color="primary">{`Tipo de Plan`}</Typography>
            <div className={classes.inputDoubleRow}>
              <RadioGroup row aria-label="position" onChange={formik.handleChange} name="status">
                <FormControlLabel
                  value="PREMIUM"
                  checked={formik.values.status === "PREMIUM"}
                  control={<Radio color="primary" />}
                  label="Premiun"
                  disabled={isSelectedOrkestaMedia}
                />
                <FormControlLabel value="FREE" checked={formik.values.status === "FREE"} control={<Radio color="primary" />} label="Free"
                  disabled={isSelectedOrkestaMedia}
                />
              </RadioGroup>
              <FormHelperText>{formik.errors.status}</FormHelperText>
            </div>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">{`Tipo de empresa`}</Typography>
              <RadioGroup
                row
                onChange={(e) => {
                  const val = e.target.value;
                  switch (val) {
                    case "2":
                      setIsGroup(true);
                      setIsGroupChildren(false);
                      break;
                    case "3":
                      setIsGroup(false);
                      setIsGroupChildren(true);
                      break;
                    default:
                      setIsGroup(false);
                      setIsGroupChildren(false);
                      break;
                  }
                }}
              >
                <FormControlLabel value="1" defaultChecked={true} control={<Radio color="primary" />} label="Normal" />
                <FormControlLabel value="2" defaultChecked={false} control={<Radio color="primary" />} label="Grupo" />
                <FormControlLabel value="3" defaultChecked={false} control={<Radio color="primary" />} label="Perteneciente a un grupo" />
              </RadioGroup>

              {isGroupChildren && (
                <Select
                  name="groupId"
                  touched={formik.touched.groupId}
                  error={formik.errors.sector}
                  placeholder="Grupo al que pertenece *"
                  onChange={(e: any) => {
                    formik.setFieldValue("groupId", e.target.value);
                  }}
                >
                  <>
                    <option value="" selected disabled>
                      Seleccionar grupo
                    </option>
                    {companyGroups
                      ?.filter((g: any) => g.enable)
                      .map((group: any) => (
                        <option value={group.id}>{group.name}</option>
                      ))}
                  </>
                </Select>
              )}
            </Grid>
            {!isSelectedOrkestaMedia && (
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">{`Producto adicional`}</Typography>
                <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.services && !!formik.errors.services}>
                  <MatSelect
                    name="services"
                    id="demo-mutiple-chip"
                    value={additionalProductsSelected}
                    multiple
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selected: any) => {
                      return (
                        <div>
                          {selected[0] === " " && <span>Productos adicionales (uno o varios) *</span>}
                          {selected.map(
                            (value: string, idx: number) =>
                              !!value &&
                              value !== " " && (
                                <span key={value}>
                                  {idx > 0 && ", "}
                                  {value.substr(0, 24)} {value.length > 24 && "..."}
                                </span>
                              )
                          )}
                        </div>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {additionalProducts?.map((p) => (
                      <option
                        key={p.id}
                        value={p.name}
                        style={{
                          backgroundColor: `${additionalProductsSelected.includes(p.name) ? "#3297FD" : "unset"}`,
                          color: `${additionalProductsSelected.includes(p.name) ? "#fff" : "unset"}`,
                          cursor: "pointer",
                        }}
                      >
                        {p.name}
                      </option>
                    ))}
                  </MatSelect>
                  <FormHelperText>{!!formik.touched.services && formik.errors.services}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <div className="mt-2">
              <Typography className={classes.subtitle}>Domicilio Fiscal:</Typography>
            </div>
            <TextField
              error={formik.errors.street}
              onChange={formik.handleChange}
              touched={formik.touched.street}
              value={formik.values.street}
              placeholder="Calle *"
              name="street"
            />
            <div className={classes.inputDoubleRow}>
              <TextField
                error={formik.errors.exteriorNumber}
                onChange={formik.handleChange}
                touched={formik.touched.exteriorNumber}
                value={formik.values.exteriorNumber}
                placeholder="Número exterior *"
                name="exteriorNumber"
                type="text"
              />

              <TextField
                error={formik.errors.interiorNumber}
                onChange={formik.handleChange}
                touched={formik.touched.interiorNumber}
                value={formik.values.interiorNumber}
                placeholder="Número interior"
                name="interiorNumber"
                type="text"
              />
            </div>
            <div className={classes.inputDoubleRow}>
              <TextField
                error={formik.errors.colony}
                onChange={formik.handleChange}
                touched={formik.touched.colony}
                value={formik.values.colony}
                placeholder="Colonia *"
                name="colony"
              />

              <TextField
                error={formik.errors.city}
                onChange={formik.handleChange}
                touched={formik.touched.city}
                value={formik.values.city}
                placeholder="Ciudad *"
                name="city"
              />
            </div>
            <div className={`${classes.inputDoubleRow} mb-3`}>
              <TextField
                error={formik.errors.postalCode}
                onChange={formik.handleChange}
                touched={formik.touched.postalCode}
                value={formik.values.postalCode}
                placeholder="Código postal *"
                name="postalCode"
                type="number"
              />

              <TextField
                error={formik.errors.country}
                onChange={formik.handleChange}
                touched={formik.touched.country}
                value={formik.values.country}
                placeholder="País *"
                name="country"
              />
            </div>
            <Typography variant="h6" color="primary">{`Administrador`}</Typography>
            <div className={`${classes.inputDoubleRow}`}>
              <TextField
                error={formik.errors.admiName}
                onChange={formik.handleChange}
                touched={formik.touched.admiName}
                value={formik.values.admiName}
                placeholder="Nombre Administrador"
                name="admiName"
              />

              <TextField
                error={formik.errors.adminEmail}
                onChange={formik.handleChange}
                touched={formik.touched.adminEmail}
                value={formik.values.adminEmail}
                placeholder="Email del Administrador"
                name="adminEmail"
              />
            </div>{" "}
            <div className={`${classes.inputDoubleRow} mb-3`}>
              <TextField
                error={formik.errors.adminRfc}
                onChange={formik.handleChange}
                touched={formik.touched.adminRfc}
                value={formik.values.adminRfc}
                placeholder="RFC del Administrador"
                name="adminRfc"
              />
            </div>
            <Typography variant="h6" color="primary">{`Representante Legal`}</Typography>
            <TextField
              error={formik.errors.legalAgentName}
              onChange={formik.handleChange}
              touched={formik.touched.legalAgentName}
              value={formik.values.legalAgentName}
              placeholder="Nombre completo del representante legal *"
              name="legalAgentName"
            />
            <TextField
              error={formik.errors.legalAgentCURP}
              onChange={formik.handleChange}
              touched={formik.touched.legalAgentCURP}
              value={formik.values.legalAgentCURP}
              placeholder="CURP del representante legal *"
              name="legalAgentCURP"
            />
            <TextField
              error={formik.errors.webSite}
              onChange={formik.handleChange}
              touched={formik.touched.webSite}
              value={formik.values.webSite}
              placeholder="Sitio web"
              name="webSite"
            />
            {/**  <Select
              name="sector"
              touched={formik.touched.sector}
              error={formik.errors.sector}
              placeholder="Sector al que pertenece *"
              onChange={(e: any) => {
                formik.setFieldValue("sector", e.target.value);
              }}
            >
              <>
                <option value="" selected disabled>
                  Sector al que pertenece *
                </option>
                {activities.map((sector) => (
                  <option value={sector}>{sector}</option>
                ))}
              </>
            </Select >*/}
            <TextField
              error={formik.errors.employesNumber}
              onChange={formik.handleChange}
              touched={formik.touched.employesNumber}
              value={formik.values.employesNumber}
              type="number"
              placeholder="N° de empleados *"
              name="employesNumber"
            />
            {/** 
               * 
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              error={!!formik.touched.services && !!formik.errors.services}
            >
               *  <MatSelect
                name="services"
                value={formik.values.services}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected: any) => {
                  return (
                    <div>
                      {selected[0] === " " && <span>Servicios ofrecidos (uno o varios) *</span>}
                      {selected.map(
                        (value: string, idx: number) =>
                          !!value &&
                          value !== " " && (
                            <span key={value}>
                              {idx > 0 && ", "}
                              {value.substr(0, 24)} {value.length > 24 && "..."}
                            </span>
                          )
                      )}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                {services.map((name) => (
                  <option
                    key={name}
                    value={name}
                    style={{
                      backgroundColor: `${servicesSelected.includes(name) ? "#3297FD" : "unset"}`,
                      color: `${servicesSelected.includes(name) ? "#fff" : "unset"}`,
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </option>
                ))}
              </MatSelect>
              <FormHelperText>{!!formik.touched.services && formik.errors.services}</FormHelperText>
            </FormControl>*/}
          </div>

          <Grid container xs={12} justifyContent="center">
            <div className="text-center">
              <Typography style={{ display: "block" }} variant="caption">
                * Campos obligatorios
              </Typography>
              <Button
                onClick={() => formik.handleSubmit()}
                type="submit"
                className="mt-2"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Guardar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => {}}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <AlertModal
        open={alertDeleteError}
        title={isError ? '¡Ups!' : '¡Éxito!'}
        textContent={errorMessage}
        onConfirm={() => handleConfirm()}
        onCancel={() => handleConfirm()}         

        isError={isError}
      />
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    //color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    // color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
    //paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  personSelect: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  borderContainer: {
    borderTop: "1px solid rgba(0,0,0,0.2)",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    margin: "1rem 0",
    padding: "1rem 0"
  }
}));

export default FormNewUserData;

/*var activities = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final",
  "Construcción",
  "Industrias manufactureras",
  "Comercio al por mayor",
  "Comercio al por menor",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Otro",
];

var services = [
  "Agricultura",
  "Cría y explotación de animales",
  "Aprovechamiento forestal",
  "Servicios relacionados con las actividades agropecuarias y forestales",
  "Pesca, caza y captura",
  "Extracción de petróleo y gas",
  "Minería de minerales metálicos y no metálicos, excepto petróleo y gas",
  "Servicios relacionados con la minería",
  "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final",
  "Edificación",
  "Construcción de obras de ingeniería civil",
  "Trabajos especializados para la construcción",
  "Industria alimentaria",
  "Industria de las bebidas y del tabaco",
  "Fabricación de insumos textiles y acabado de textiles",
  "Fabricación de productos textiles, excepto prendas de vestir",
  "Fabricación de prendas de vestir",
  "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos",
  "Industria de la madera",
  "Industria del papel",
  "Impresión e industrias conexas",
  "Fabricación de productos derivados del petróleo y del carbón",
  "Industria química",
  "Industria del plástico y del hule",
  "Fabricación de productos a base de minerales no metálicos",
  "Industrias metálicas básicas",
  "Fabricación de productos metálicos",
  "Fabricación de maquinaria y equipo",
  "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos",
  "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica",
  "Fabricación de equipo de transporte",
  "Fabricación de muebles, colchones y persianas",
  "Otras industrias manufactureras",
  "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por mayor de productos textiles y calzado",
  "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca",
  "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho",
  "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general",
  "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones",
  "Intermediación de comercio al por mayor",
  "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por menor de artículos de ferretería, tlapalería y vidrios",
  "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal",
  "Comercio al por menor de artículos para el cuidado de la salud",
  "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados",
  "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado",
  "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes",
  "Comercio al por menor en tiendas de autoservicio y departamentales",
  "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares",
  "Transporte aéreo",
  "Transporte por ferrocarril",
  "Transporte por agua",
  "Autotransporte de carga",
  "Transporte terrestre de pasajeros, excepto por ferrocarril",
  "Transporte por ductos",
  "Transporte turísticas",
  "Servicios relacionados con el transporte",
  "Servicios postales",
  "Servicios de mensajería y paquetería",
  "Servicios de almacenamiento",
  "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión",
  "Industria fílmica y del video, e industria del sonido",
  "Radio y televisión",
  "Telecomunicaciones",
  "Procesamiento electrónico de información, hospedaje y otros servicios relacionados",
  "Otros servicios de información",
  "Banca central",
  "Actividades bursátiles, cambiarias y de inversión financiera",
  "Compañías de seguros, fianzas, y administración de fondos para el retiro",
  "Instituciones de intermediación crediticia y financiera no bursátil",
  "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión",
  "Servicios inmobiliarios",
  "Servicios de alquiler de bienes muebles",
  "Servicios de alquiler de marcas registradas, patentes y franquicias",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios",
  "Limpieza",
  "Servicios de mantenimiento",
  "Protección y Seguridad",
  "Manejo de residuos y servicios de remediación",
  "Servicios educativos",
  "Servicios médicos de consulta externa y servicios relacionados",
  "Hospitales pertenecientes al sector privado",
  "Residencias de asistencia social y para el cuidado de la salud",
  "Otros servicios de asistencia social",
  "Servicios artísticos, culturales y deportivos, y otros servicios relacionados",
  "Museos, sitios históricos, zoológicos y similares",
  "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos",
  "Servicios de alojamiento temporal",
  "Servicios de preparación de alimentos y bebidas",
  "Servicios de reparación y mantenimiento",
  "Servicios personales",
  "Asociaciones y organizaciones",
  "Hogares con empleados domésticos",
  "Otro",
];*/
