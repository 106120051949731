import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Grid, SvgIconTypeMap } from '@material-ui/core';

interface CounterCardProps {
  totalCount: number;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  title: string;
  onClick?: () => void;
  infoList: {
    label: string;
    value: number;
  }[];
}

function CounterCard({ totalCount, title, Icon, infoList, onClick }: CounterCardProps) {
  const classes = useStyles();
  return (
    <Grid item>
      <Card className={classes.root}>
        <CardHeader
          classes={{ action: classes.actionButton }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <Icon fontSize='large' className={classes.avatarIcon} />
            </Avatar>
          }
          action={
              <Button
                variant="contained"
                color="primary" endIcon={<ArrowForwardIcon />}
                onClick={onClick}
                size="large"
              >
                Consultar Lista
              </Button>
          }
        />

        <CardContent>
          <div className={classes.divText}>
            <Typography variant="h2">
              {totalCount}
            </Typography>
            <Typography variant="h4">
              {title}
            </Typography>
          </div>
          <List>
            {infoList.map((item, i) => (
              <ListItem key={i}>
                <ListItemText
                  className={classes.listInfo}
                  disableTypography
                >
                  <Typography variant="body1" className={classes.listInfoItem}>{item.label}:</Typography>
                  <Typography variant="body1" className={classes.listInfoItem}>{item.value}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 385,
    height: '100%'
  },
  actionButton: {
    marginRight: 0,
    alignSelf: 'unset'
  },
  divText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingLeft: 7,
    gap: 20
  },
  listInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listInfoItem: {
    fontWeight: 'bold',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  avatarIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));
export default CounterCard;