import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react'


const PolicyListItem: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <li className={classes.item}>
      {children}
    </li>
  )
}

const useStyles = makeStyles({
  item: {
    marginBottom: 10
  }
});

export default PolicyListItem
