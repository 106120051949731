import React from 'react';
import { Dialog, Button, makeStyles, DialogTitle, DialogActions, Typography,DialogContent,DialogContentText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { blue } from '@material-ui/core/colors';

function AlertDialog(props : any) {
  const {message,type} = props;


  const [open, setOpen] = React.useState(true);
  const classes = useStyles();


  const handleClose = () => {
    setOpen(false);
    //refresh();
  };

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon className={classes.textSuccess} />;
      case 'error':
        return <ErrorIcon className={classes.textError} />;
      default:
        return <InfoIcon className={classes.textInfo} />;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography>
        <div style={{ textAlign: 'center' }}>{renderIcon()}</div>
        <Typography variant="h4" color="primary" align="center">
          {message}
        </Typography>
      </DialogTitle>
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
           Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles({
  dialogContainer: { padding: 30 },
  textSuccess: { fontSize: 50, color: '#4caf50' },
  textError: { fontSize: 50, color: '#f44336' },
  textInfo: { fontSize: 50, color: blue[500] }
});

export default AlertDialog;
