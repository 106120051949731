import {
  authHeader,
  axiosMaterialityRequestV1,
} from "../../configs/axios.config";

export class StagesHttp {
  findAllStages(sucess, error) {
    axiosMaterialityRequestV1
      .get("/stage/template", { headers: authHeader() })
      .then(({ data }) => sucess(data))
      .catch((err) => error(err));
  }

  // createTemplate(template, sucess, error) {
  //   axiosRequest
  //     .post("/templates", template, { headers: authHeader() })
  //     .then(({ data }) => sucess(data))
  //     .catch((err) => error(err));
  // }

  // deleteTemplate(templateId, sucess, error) {
  //   axiosRequest
  //     .delete(`/templates/${templateId}`, { headers: authHeader() })
  //     .then(({ data }) => sucess(data))
  //     .catch((err) => error(err));
  // }
}
