/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography, } from "@material-ui/core";
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import { green } from '@material-ui/core/colors';
import { useRouteMatch } from 'react-router';

import DialogClient from '../components/management/company/SectionNewCompany/DialogClient';
import ListOtherDetails from '../components/management/company/DetailsCompany/CompanyListUser';
import FormUsers from '../components/management/company/FormUsersInternal';
import { useHandleDialog } from '../core/hooks/useHandleDialog';
import { FormType } from './ManagementPage';
import { ManagementContext } from '../core/contexts/management-context/ManagementContext';
import DialogConfirm from '../components/general/DialogConfirm'
import { CompaniesService } from '../core/services/companies.service';
import { UserService } from "../core/services/user.service";
import { ServicesService } from '../core/services/services.service';
import { toBase64 } from '../core/utils/ntpUtils';
import FormCompanyEdit from '../components/management/company/DetailsCompany/FormCompanyEdit';
import FormTemplate from '../components/management/company/DetailsCompany/FormTemplate';
import SearchTextField from '../components/inputs/inputs-v2/RenderTextFieldV2';
import ServicesTable from '../components/management/company/DetailsCompany/ServicesTable';
import { useHandleDialogMd } from '../core/hooks/useHandleMdDialog';


interface State {
  name: string;
  rfc: string;
  addres: string;
  street: string;
  web: string;
  employees: string;
  legalEmail: string,
  legalUserName: string,
  numeberInt: string,
  numberExt: string,
  colony: string,
  city: string,
  postal: string,
  country: string,
  legalUserCurp: string,
  adminUserName: string,
  emailAdminName: string,
  employeesSize: string,
  status: string,
  email: string,
  cel: string;
  colonia: string;
  cp: string;
  interiorNumber: string;
  outdoorNum: string;
  servicesOffered: string;
  sector: string;

}

const initialState: State = {
  name: '',
  rfc: '',
  addres: '',
  numeberInt: '',
  numberExt: '',
  colony: '',
  city: '',
  postal: '',
  country: '',
  web: '',
  employees: '',
  legalEmail: '',
  legalUserName: '',
  legalUserCurp: '',
  employeesSize: '',
  status: '',
  street: '',
  colonia: '',
  cp: '',
  interiorNumber: '',
  outdoorNum: '',
  servicesOffered: '',
  sector: '',
  adminUserName: '',
  emailAdminName: '',
  email: '',
  cel: ''
};

const initialStateAdmin = {
  name: '',
  email: '',
  rfc: '',
  cel: ''
};

function CompanyDetailPage() {
  const { editUser } = useContext(ManagementContext);
  const [MaterialityDialog, handleShowModal, handleCloseModal] = useHandleDialog();

  let match = useRouteMatch<{ companyId?: string, page?: string }>({ path: "/dashboard/details/company/:companyId/:page" });
  const companyId = match?.params.companyId;

  const classes = useStyles();
  const history = useHistory();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [showDialog, setShowDialog] = React.useState(false);
  const [formType, setFormType] = useState<FormType>('create');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [details, setDetails] = React.useState(initialState);
  const [taxResidence, setResidence] = React.useState(initialState);
  const [detailsAdmin, setAdmin] = React.useState(initialStateAdmin);
  const [usersCompany, setUsersCompany] = useState<any[]>([]);
  const [showDialogUpdate, setShowDialogUpdate] = React.useState(false);
  const [DialogType, setDialogType] = React.useState('');
  const [idCompany] = React.useState(match?.params.companyId);
  const [documents, setDocuments] = useState<any[]>([]);
  const [companyActivities] = useState<any[]>([
    { label: "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza", value: "1" },
    { label: "Minería", value: "2" },
    { label: "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final", value: "3" },
    { label: "Construcción", value: "4" },
    { label: "Industrias manufactureras", value: "5" },
    { label: "Comercio al por mayor", value: "6" },
    { label: "Comercio al por menor", value: "7" },
    { label: "Transportes, correos y almacenamiento", value: "8" },
    { label: "Información en medios masivos", value: "9" },
    { label: "Servicios financieros y de seguros", value: "10" },
    { label: "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos", value: "11" },
    { label: "Servicios profesionales, científicos y técnicos", value: "12" },
    { label: "Corporativos", value: "13" },
    { label: "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación", value: "14" },
    { label: "Servicios educativos", value: "15" },
    { label: "Servicios de salud y de asistencia social", value: "16" },
    { label: "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos", value: "17" },
    { label: "Servicios de alojamiento temporal y de preparación de alimentos y bebidas", value: "18" },
    { label: "Otros servicios excepto actividades gubernamentales", value: "19" },
    { label: "Otro", value: "20" },
  ]);
  const [servicesActivity] = useState<any[]>([
    { label: "Agricultura", value: "1" },
    { label: "Cría y explotación de animales", value: "2" },
    { label: "Aprovechamiento forestal", value: "3" },
    { label: "Servicios relacionados con las actividades agropecuarias y forestales", value: "4" },
    { label: "Pesca, caza y captura", value: "5" },
    { label: "Extracción de petróleo y gas", value: "6" },
    { label: "Minería de minerales metálicos y no metálicos, excepto petróleo y gas", value: "7" },
    { label: "Servicios relacionados con la minería", value: "8" },
    { label: "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final", value: "9" },
    { label: "Edificación", value: "10" },
    { label: "Construcción de obras de ingeniería civil", value: "11" },
    { label: "Trabajos especializados para la construcción", value: "12" },
    { label: "Industria alimentaria", value: "13" },
    { label: "Industria de las bebidas y del tabaco", value: "14" },
    { label: "Fabricación de insumos textiles y acabado de textiles", value: "15" },
    { label: "Fabricación de productos textiles, excepto prendas de vestir", value: "16" },
    { label: "Fabricación de prendas de vestir", value: "17" },
    { label: "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos", value: "18" },
    { label: "Industria de la madera", value: "19" },
    { label: "Industria del papel", value: "20" },
    { label: "Impresión e industrias conexas", value: "21" },
    { label: "Fabricación de productos derivados del petróleo y del carbón", value: "22" },
    { label: "Industria química", value: "23" },
    { label: "Industria del plástico y del hule", value: "24" },
    { label: "Fabricación de productos a base de minerales no metálicos", value: "25" },
    { label: "Industrias metálicas básicas", value: "26" },
    { label: "Fabricación de productos metálicos", value: "27" },
    { label: "Fabricación de maquinaria y equipo", value: "28" },
    { label: "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos", value: "29" },
    { label: "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica", value: "30" },
    { label: "Fabricación de equipo de transporte", value: "31" },
    { label: "Fabricación de muebles, colchones y persianas", value: "32" },
    { label: "Otras industrias manufactureras", value: "33" },
    { label: "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco", value: "34" },
    { label: "Comercio al por mayor de productos textiles y calzado", value: "35" },
    { label: "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca", value: "36" },
    { label: "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho", value: "37" },
    { label: "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general", value: "38" },
    { label: "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones", value: "39" },
    { label: "Intermediación de comercio al por mayor", value: "40" },
    { label: "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco", value: "41" },
    { label: "Comercio al por menor de artículos de ferretería, tlapalería y vidrios", value: "42" },
    { label: "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal", value: "43" },
    { label: "Comercio al por menor de artículos para el cuidado de la salud", value: "44" },
    { label: "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados", value: "45" },
    { label: "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado", value: "46" },
    { label: "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes", value: "47" },
    { label: "Comercio al por menor en tiendas de autoservicio y departamentales", value: "48" },
    { label: "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares", value: "49" },
    { label: "Transporte aéreo", value: "50" },
    { label: "Transporte por ferrocarril", value: "51" },
    { label: "Transporte por agua", value: "52" },
    { label: "Autotransporte de carga", value: "53" },
    { label: "Transporte terrestre de pasajeros, excepto por ferrocarril", value: "54" },
    { label: "Transporte por ductos", value: "55" },
    { label: "Transporte turísticas", value: "56" },
    { label: "Servicios relacionados con el transporte", value: "57" },
    { label: "Servicios postales", value: "58" },
    { label: "Servicios de mensajería y paquetería", value: "59" },
    { label: "Servicios de almacenamiento", value: "60" },
    { label: "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión", value: "61" },
    { label: "Industria fílmica y del video, e industria del sonido", value: "62" },
    { label: "Radio y televisión", value: "63" },
    { label: "Telecomunicaciones", value: "64" },
    { label: "Procesamiento electrónico de información, hospedaje y otros servicios relacionados", value: "65" },
    { label: "Otros servicios de información", value: "66" },
    { label: "Banca central", value: "67" },
    { label: "Actividades bursátiles, cambiarias y de inversión financiera", value: "68" },
    { label: "Compañías de seguros, fianzas, y administración de fondos para el retiro", value: "69" },
    { label: "Instituciones de intermediación crediticia y financiera no bursátil", value: "70" },
    { label: "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión", value: "71" },
    { label: "Servicios inmobiliarios", value: "72" },
    { label: "Servicios de alquiler de bienes muebles", value: "73" },
    { label: "Servicios de alquiler de marcas registradas, patentes y franquicias", value: "74" },
    { label: "Servicios profesionales, científicos y técnicos", value: "75" },
    { label: "Corporativos", value: "76" },
    { label: "Servicios de apoyo a los negocios", value: "77" },
    { label: "Limpieza", value: "78" },
    { label: "Servicios de mantenimiento", value: "79" },
    { label: "Protección y Seguridad", value: "80" },
    { label: "Manejo de residuos y servicios de remediación", value: "81" },
    { label: "Servicios educativos", value: "82" },
    { label: "Servicios médicos de consulta externa y servicios relacionados", value: "83" },
    { label: "Hospitales pertenecientes al sector privado", value: "84" },
    { label: "Residencias de asistencia social y para el cuidado de la salud", value: "85" },
    { label: "Otros servicios de asistencia social", value: "78" },
    { label: "Servicios artísticos, culturales y deportivos, y otros servicios relacionados", value: "77" },
    { label: "Museos, sitios históricos, zoológicos y similares", value: "78" },
    { label: "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos", value: "77" },
    { label: "Servicios de alojamiento temporal", value: "78" },
    { label: "Servicios de preparación de alimentos y bebidas", value: "79" },
    { label: "Servicios de reparación y mantenimiento", value: "80" },
    { label: "Servicios personales", value: "81" },
    { label: "Asociaciones y organizaciones", value: "82" },
    { label: "Hogares con empleados domésticos", value: "83" },
    { label: "Otro", value: "84" },
  ]);
  const [companyServices, setCompanyServices] = useState<ServiceCompany[]>([]);

  const companiesService = CompaniesService.getInstance();
  const userService = UserService.getInstance();
  const servicesService = ServicesService.getInstance();

  useEffect(() => {
    //const companyId = match?.params.companyId;
    getCompany(idCompany);
    getUsersByCompany(idCompany);
    getSetDocumentsByCompany(idCompany);
    getServicesByCompany(idCompany ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadDataComponent = () => {
    const companyId = match?.params.companyId;
    getCompany(companyId);
    getUsersByCompany(companyId);
    getSetDocumentsByCompany(companyId);
  }

  const refreshComponentData = () => {
    getCompany(idCompany);
    getUsersByCompany(idCompany);
    getSetDocumentsByCompany(idCompany);
  }

  const getCompany = (companyId: any) => {
    setLoading(true);
    companiesService.getCompanyByID(companyId).then(async (data) => {
      setLoading(false)
      setDetails(data);
      setResidence(data.taxResidence === null ? initialStateAdmin : data.taxResidence);
      setAdmin(data.user === null ? initialStateAdmin : data.user);
    }, (error) => {
      console.error(error);
    });
  }

  const getSetDocumentsByCompany = (companyId: any) => {
    setLoading(true);

    servicesService.getDocumentSetByCompany(companyId).then(async (data) => {
      let documents = data.data;
      const dataSelectMapped = documents != null ? documents.map((doc: any) => ({ id: doc.id, label: doc.name, value: '', value2: '', rol: '' })) : [];
      setLoading(false);
      setDocuments(dataSelectMapped);
    }, (error) => {
      console.error(error);
    });
  }

  const getUsersByCompany = (companyId: any) => {
    setLoading(true);

    companiesService.getUsersByCompany(companyId).then(async (data) => {
      let users = data.users;
      const dataSelectMapped = users != null ? users.map((user: any) => ({ id: user.id, label: user.name, value: user.email, value2: user.rfc, rol: user.roles })) : [];
      setLoading(false);
      setUsersCompany(dataSelectMapped);
      //let result = dataSelectMapped.filter(u => names.includes(u.label));
    }, (error) => {
      console.error(error);
    });
  }

  const getServicesByCompany = async (companyId: string) => {
    const services = await companiesService.getServicesByCompany(companyId);
    setCompanyServices(services);
  };

  const editCompany = async ({ ...data }: any) => {
    handleCloseModal();

    setLoading(true);
    setShowDialogUpdate(false);

    let json = {
      "id": idCompany,
      "name": data.nameCompany,
      "rfc": data.rfc,
      "taxResidence": {
        "street": data.address,
        "outdoorNum": data.numExt,
        "colonia": data.colony,
        "cp": data.cp,
        "interiorNumber": data.numInt,
        "city": data.city,
        "country": data.country
      },
      "employeesSize": data.employes,
      "web": data.website,
      "legalUserName": data.nameLegal,
      "legalUserCurp": data.curp,
      "status": data.status,
      "servicesOffered": data.actividad?.join("|"),
      "sector": data.sector,
    }

    companiesService.editCompany(json)
      .then((data) => {
        setInitSate("Empresa actualizada exitosamente.");
      })
      .catch((error) => {
        handleError("Error en actualizar empresa")
      }).finally(() => setLoading(false));
  };

  const handlegDeleteUser = async (item: any) => {
    setLoading(true)
    userService.deleteUser(item.id)
      .then((data) => {
        setInitSate("Usuario eliminado.");
      })
      .catch((error) => {
        handleError("Error al eliminar usuario.")
      }).finally(() => setLoading(false));
  }

  const handlegDeleteSetDocument = async (item: any) => {
    setLoading(true)
    servicesService.deleteService(item.id)
      .then((data) => {
        setInitSate("Set de documentos eliminado.");
      })
      .catch((error) => {
        handleError("Error al eliminar Set de documentos.")
      }).finally(() => setLoading(false));
  }

  const handlegDeleteCompany = async () => {
    setLoading(true)
    companiesService.deactivateCompany(idCompany)
      .then((data) => {
        setInitSate("Compañia desactivada.");
      })
      .catch((error) => {
        handleError("Error al desactivar compañia.")
      }).finally(() => setLoading(false));
  }

  const handleSubmit = async ({ imageFile, email, ...data }: FormUserFields) => {
    let closeForm;
    setLoading(true)
    let company = data.companies;

    if (formType === 'create') {
      const dataToSend: CreateUserRequestBody2 = {
        ...data,
        password: formType === 'create' ? '1234567890' : undefined,
        email,
        username: email,
        roles: ["Admin"],
        companies: company,
        image: imageFile?.type,
        image64: undefined,
        status: true,
        rfc: "MUST_UPDATE_PASS" //TODO: temporal

      };

      userService.createUser(dataToSend)
        .then((data) => {
          setInitSate("Usuario Administrador creado.");
          handleCloseModal();
        }).catch((error) => {
          handleError("Error al crear Usuario")
        })
        .finally(() => handleFinallyAction());
    }
    else if (formType === 'edit') {
      const dataToSend: EditUserRequestBody = {
        ...data,
        id: selectedUser!.id
      }
      closeForm = await editUser(dataToSend);
    }
  };

  const createDocumentSet = async (data: FormTemplateFields) => {
    setLoading(true);

    const JsonRequest: CreateDocumentSetReqBody = {
      documentHash: await toBase64(data.setFile as File),
      fileExtension: "xslx",
      traceabilityPoints: 65,
      requiredPoints: 50,
      serviceTypeName: data.name,
      companyId: idCompany
    };

    servicesService.createDocumentSet(JsonRequest)
      .then((data) => {
        handleCloseModal();
        setLoading(false);
        setInitSate("Set de Documentos creado.");
      }).catch((error) => {
        handleError("Error al crear Set Documentos")
      })
      .finally(() => handleFinallyAction());
  };

  const handleError = (message: string) => {
    setMessage(message);
    setShowDialog(true);
    handleCloseModal();
  };

  const setInitSate = (message: string) => {
    setShowDialog(true);
    setMessage(message);
    reloadDataComponent();
    getCompany(idCompany);
  };

  const handleFinallyAction = () => {
    setLoading(false);
    getCompany(idCompany);
    reloadDataComponent();
  }

  const handleOpenEditUserForm = (user: User) => {
    setSelectedUser(user);
    setFormType('edit');
    handleShowModal();
  }

  const handleOpenCreateUserForm = () => {
    setSelectedUser(undefined);
    setDialogType('userForm');
    setFormType('create');
    handleShowModal();
  }

  const handleOpenCreateTemplateForm = () => {
    setDialogType('templateForm');
    handleShowModal();
  }
  const handleOpenEditCompanyForm = () => {
    setDialogType('companyEditForm');
    handleShowModal();
  }

  const handleClickBack = () => {
    history.push(`/dashboard/companies/${match?.params.page}`);
  };

  const handleCloseModals = () => {
  };

  const handleCreate = () => {
  };

  const handleOpenUpdateModal = () => {
    setShowDialogUpdate(true)
  };

  const handleClickService = (serviceId: string) => {
    history.push(`/dashboard/details/service/${serviceId}`);
  }

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} className={classes.buttonBack}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={handleClickBack}
        >
          Regresar
        </Button>
      </Grid>


      {showDialog && (
        <DialogClient message={message} refresh={refreshComponentData} type={''} />
      )}

      <Divider className={classes.divider} />

      <Grid container style={{ paddingTop: '20px' }}>

        <Grid item xs={2}>
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button>{details.name}</Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={4}>
          <Button disableElevation endIcon={<EditIcon />} color="primary" onClick={handleOpenEditCompanyForm}>
            Editar datos de la empresa
          </Button>
        </Grid>

        <Grid item xs={2} >
          <Button variant="contained" color="primary" onClick={() => setShowDialogUpdate(true)}>
            Bloquear empresa
          </Button>
        </Grid>

        <Grid item xs={2} >
          <Button variant="contained" color="primary">
            Eliminar empresa
          </Button>
        </Grid>

        <Grid item xs={1}>
          {details.status === "PREMIUM" ?
            <Typography variant="h6" color="primary">Estatus: {details.status}</Typography> :
            <Typography variant="h6">Estatus: {details.status}</Typography>
          }
        </Grid>

        <Grid item xs={12}>
          <Divider className={classes.dividerLineSmall} />
          {loading && (
            <LinearProgress />
          )}
        </Grid>

        <Grid item xs={6} className={classes.divider}>
          <Typography variant="h6" color="primary">Administradores:</Typography>
        </Grid>
        <Grid item xs={6} className={classes.divider}>
          <Typography variant="h6" color="primary">Metodos de Pago:</Typography>
        </Grid>

        <ListOtherDetails
          title={"Agregar Administrador"}
          modalMessage={"Administrador"}
          dataList={usersCompany}
          onCancel={handleCloseModals}
          onAccept={handleOpenCreateUserForm}
          onDelete={handlegDeleteUser}
        />

        <ListOtherDetails
          title={"Método de Pago"}
          modalMessage={"Método de Pago"}
          dataList={
            [

              {
                label: 'MasterCard',
                value: '1228292',
                value2: '12/30/50'
              }
            ]
          }
          onCancel={handleCloseModals}
          onAccept={handleCreate}
          onDelete={handleCloseModals}
        />
        <Grid item xs={12} className={classes.divider}>
          <Typography variant="h6" color="primary">Set de Documentos:</Typography>
        </Grid>

        <ListOtherDetails
          title={"Agregar Set de Documentos"}
          modalMessage={"Set de Documentos"}
          dataList={documents}
          onCancel={handleCloseModals}
          onAccept={handleOpenCreateTemplateForm}
          onDelete={handlegDeleteSetDocument}
        />

        <ServicesTable
          hasTitle
          services={companyServices}
          onClickService={handleClickService}
        />
      </Grid>

      {loading && (
        <LinearProgress />
      )}

      <DialogConfirm
        open={showDialogUpdate}
        title={""}
        message={`¿Esta seguro que quiere desactivar la empresa?`}
        onConfirm={() => handlegDeleteCompany()}
        onClose={() => setShowDialogUpdate(false)}
      />

      <MaterialityDialog>
        {
          DialogType === 'templateForm' ?
            <FormTemplate
              onCancel={handleCloseModal}
              onSubmit={createDocumentSet}
              title={''}
            /> :
            DialogType === 'companyEditForm' ?
              <FormCompanyEdit
                formType={formType}
                onCancel={handleCloseModal}
                onSubmit={editCompany}
                typeForm={formType}
                actividad={servicesActivity}
                companyActivities={companyActivities}
                userSelected={selectedUser!}
                initialValues={details ? {
                  name: details.name,
                  rfc: details.rfc,
                  nameLegal: details.legalUserName,
                  address: taxResidence.street,
                  cp: taxResidence.cp,
                  country: taxResidence.country,
                  city: taxResidence.city,
                  colony: taxResidence.colonia,
                  curp: details.legalUserCurp,
                  website: details.web,
                  employes: details.employeesSize,
                  numExt: taxResidence.outdoorNum,
                  numInt: taxResidence.interiorNumber,
                  status: details.status,
                  actividad: [details.servicesOffered],
                  sector: details.sector

                } : undefined}
                companies={[companyId]}
                roles={[{ label: 'Admin', value: 'Admin' }]}
              /> :
              <FormUsers
                formType={formType}
                onCancel={handleCloseModal}
                onSubmit={handleSubmit}
                typeForm={formType}
                userSelected={selectedUser!}
                initialValues={selectedUser ? {
                  companies: [],
                  email: selectedUser.email,
                  name: selectedUser.name,
                  lastName: selectedUser.lastName,
                  projects: selectedUser.projects,
                  roles: []
                } : undefined}
                companies={[companyId]}
              />
        }
      </MaterialityDialog>
    </Grid>
  );
}

export default CompanyDetailPage;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 1000,
    height: 'auto',
    justifyContent: 'center',
  },
  divForm: {
    backgroundColor: theme.palette.background.paper,
    width: 900,
    height: 900,
    justifyContent: 'center',
    paddingBottom: 40,
    paddingTop: 40,
  },
  premiumButton: {
    backgroundColor: green[500],
    width: 200,
    borderRadius: '0'
  },
  editButton: {
    backgroundColor: 'white',
    width: 200,
    borderRadius: '0'
  },
  grid: {
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonBack: {
    paddingBottom: 10,
  },
  buttonSave: {
    paddingTop: 40,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  button2: {
    paddingBottom: 10,
    paddingTop: 40
  },
  input: {
    width: 460,
    paddingLeft: 5
  },
  panel: {
    width: 400,
  },
  selectDiv: {
    paddingBottom: 10,
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto'
  },
  dividerLine: {
    paddingTop: 1,
    paddingBottom: 1,
    height: 'auto',
  },
  dividerLineSmall: {
    marginTop: 12,
    paddingTop: 1,
    paddingBottom: 1,
    height: 'auto',
    width: '500px'
  },
  inputLarge: {
    width: 660,
    paddingLeft: 2
  },
}));

