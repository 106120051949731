import React, { Component } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/RenderTextField';
import Visibility from '@material-ui/icons/Visibility';
import TermsAndConditions from './policy/TermsConditions';
import SafetyIndicator from "./safety-indicator";
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';
import LinkCheckbox from './LinkCheckbox';
import PolicyDialog from './policy/PolicyDialog';
import PrivacyDataProtection from './policy/PrivacyDataProtection';

class CreateAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogTandCOpen: false,
      dialogPrivacyOpen: false,
      termConditionInvalid: false,
      TandCChecked: false,
      PrivacyChecked: false,
      password: ''
    }
  }

  submitActions(form, formProps) {
    const { submitActions } = formProps;

    if (this.state.TandCChecked) {
      submitActions(form);
      this.setState({ termConditionInvalid: false });
    } else {
      this.setState({ termConditionInvalid: true });
    }
  }

  render() {
    const { handleSubmit, onCancel, errors } = this.props;
    const { dialogTandCOpen, dialogPrivacyOpen, TandCChecked, PrivacyChecked, password, termConditionInvalid } = this.state;

    return (
      <form onSubmit={ handleSubmit((form, metaData, formProps) => this.submitActions(form, formProps)) }>
        <PolicyDialog
          onReturn={ () => this.setState({ dialogTandCOpen: !dialogTandCOpen }) }
          open={ dialogTandCOpen }
          onAccept={ () =>  this.setState({ TandCChecked: true, dialogTandCOpen: false }) }
          onReject={ () => this.setState({ TandCChecked: false, dialogTandCOpen: false }) }
          title="Términos y Condiciones"
        >
          <TermsAndConditions />
        </PolicyDialog>

        <PolicyDialog
          onReturn={ () => this.setState({ dialogPrivacyOpen: !dialogPrivacyOpen }) }
          open={ dialogPrivacyOpen }
          onAccept={ () =>  this.setState({ PrivacyChecked: true, dialogPrivacyOpen: false }) }
          onReject={ () => this.setState({ PrivacyChecked: false, dialogPrivacyOpen: false }) }
          title="Aviso de Privacidad y Protección de datos"
        >
          <PrivacyDataProtection />
        </PolicyDialog>

        <Grid 
          className={ `${ isMobile ? 'p-5' : 'pb-3' } animate__animated animate__backInUp` } 
          container 
          spacing={ 1 }
        >
          <Grid item md={ 12 } xs={ 12 }>
            <h3>Crear Cuenta</h3>
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field 
              label="Nombre completo"
              name="completeName"
              required
              component={ RenderTextField } 
            />
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field 
              label="Correo"
              name="email"
              required
              component={ RenderTextField } 
            />
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field 
              label="Contraseña"
              name="password"
              type="password"
              required
              passwordAction
              onKeyUp={ keyUp => this.setState({ password: keyUp }) }
              icon={
                <Visibility />
              }
              component={ RenderTextField } 
            />
          </Grid>

          <Grid item md={ 12 } xs={ 12 }>
            <Field
              label="Confirmar contraseña"
              name="passwordConfirm"
              type="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={ RenderTextField }
            />

            <SafetyIndicator className="mt-3" password={ password } />
          </Grid>

          <LinkCheckbox
            checked={TandCChecked}
            onChange={(e) => this.setState({ TandCChecked: e.target.checked })}
            onClickLink={() => this.setState({ dialogTandCOpen: !dialogTandCOpen })}
            text="Acepto los"
            linkText="términos y condiciones"
          />

          <LinkCheckbox
            checked={PrivacyChecked}
            onChange={(e) => this.setState({ PrivacyChecked: e.target.checked })}
            onClickLink={() => this.setState({ dialogPrivacyOpen: !dialogPrivacyOpen })}
            text="Acepto el"
            linkText="Aviso de Privacidad y Protección de Datos"
          />

          {
            termConditionInvalid &&
              <Grid item md={ 12 } xs={ 12 }>
                <Alert variant="filled" severity="error">
                  Si no acepta los términos y condiciones no se puede concluir el registro de usuario.
                </Alert>
              </Grid>
          }

          {
            errors.message &&
              <Grid item md={ 12 } xs={ 12 }>
                <Alert variant="filled" severity={errors.type}>
                  {errors.message}
                </Alert>
              </Grid>
          }

          <Grid item md={ 12 } xs={ 12 }>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth={ true }
            >
              Registrame
            </Button>
          </Grid>

          <Grid className="pt-2" item md={ 12 } xs={ 12 }>
            <Button 
              variant="outlined" 
              color="primary" 
              fullWidth={ true }
              onClick={ () => onCancel() }
            >
              Ya tengo una cuenta
            </Button>
          </Grid>

          <Grid item className={'mt-5 pt-3 mb-3'} xs={12}>
            <Grid container justifyContent="center" item sm={12}>
              <Typography style={{color: '#989EB4'}}>Powered by</Typography>
            </Grid>
            <Grid container justifyContent="center" item sm={12}>
              <img src={logoAxeleratum} alt="Axeleratum" className="icon-powered-by" />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

}

const validate = (values) => {
  const errors = { 
    completeName: '',
    email: '',
    password: '',
    passwordConfirm: ''
  }
  
  if (!values.completeName) {
    errors.completeName = 'El nombre completo es requerido';
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'La confirmación de contraseña es requerida';
  }

  if (values.password && values.passwordConfirm) {
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Las contraseñas deben coincidir';
    }
  }

  if (values.completeName) {
    if (values.completeName.length > 50) {
      errors.completeName = 'El nombre completo no puede ser mayor a 50 caracteres';
    }
  }

  if (values.email) {
    if (values.email.length > 50) {
      errors.email = 'El correo no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }

    if (values.password.length < 10) {
      errors.password = 'La contraseña no puede ser menor a 10 caracteres';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'CreateAccount',
  validate
})(CreateAccount);
