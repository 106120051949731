import { axiosCompanyRequest, axiosRequestBlockChain, authHeader ,axiosNtpRequestV1} from "../../configs/axios.config";
import { GenericResponse } from "../../types/global";
import { ServiceDashboardRes } from "../../types/service-dashboard.interface";

export class ServicesService {
  private static instance: ServicesService;

  private constructor() { };

  public static getInstance(): ServicesService {
    if (!ServicesService.instance) {
      ServicesService.instance = new ServicesService();
    }
    return ServicesService.instance;
  }

  async findAllServiceTypes() {
    try {
      const { data } = await axiosCompanyRequest.get('/servicesType', { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async listServices(page: number, size?: number, query?: string) {
    const params = {
      page,
      size,
      name: query
    }
    const { data } = await axiosNtpRequestV1.get<GenericResponse<ListServiceResponse>>('/services', { params, headers: authHeader() });
    return data.data;
  }

  async getServicesStats(){
    const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<ServicesStatsResponse>>('/services/stats', { headers: authHeader() });
    return data.data;
  }

  async getServiceDocuments(serviceId: string, querySearch?: string) {
    const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<ListServiceDocumentsResponse>>(`/services/${serviceId}/documents`, {
      params: {
        name: querySearch
      },
      headers: authHeader()
    });
    return data.data;
  }

  async findServiceById(serviceId: string) {
    try {
      const { data } = await axiosCompanyRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async createService(body: CreateServiceReqBody) {
    try {
      const { data } = await axiosCompanyRequest.post('/services', body, { headers: authHeader() });
      return data;
    } catch (error) {
      try {
        axiosRequestBlockChain.delete(`/v1/folders/${body.rootFolderId}`, { headers: authHeader() });
      } catch (folderError) {
        throw folderError;
      }
      throw error;
    }
  }

  async getDocumentSetByCompany(companyId: string){
    try {
      const { data } = await axiosNtpRequestV1.get(`/service-type/company/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createDocumentSet(body: CreateDocumentSetReqBody){
    try {
      const { data } = await axiosNtpRequestV1.post("/service-type/",  body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateService({ id, ...body }: UpdateServiceReqBody) {
    try {
      const { data } = await axiosCompanyRequest.put(`/services/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteService(serviceId: string) {
    try {
      const { data } = await axiosNtpRequestV1.delete(`/service-type//${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
}