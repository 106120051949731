import { AuthService } from '../services';
import { authConstants } from '../constants';
import { alertActions } from './'
import { parseJwt } from '../utils/utils';

const authService = new AuthService()

export const authActions = {
  login,
  logout,
  signUp,
  activeSession,
  sendEmailPassword,
  refreshToken,
  restorePassword,
  refreshTokenNew
}

async function setIsProvider(userId) {
 // const providerHttp = new ProviderHttp();
  sessionStorage.setItem('is_provider', false);
    sessionStorage.setItem('id_provider', '1');
}

function login(username, password) {
  return async (dispatch) => {
    await authService.ipfsToken(username, password).then(response => {
      const {
        access_token,
        refresh_token,
      } = response;
      const tokenPayload = parseJwt(access_token);
      const userId = tokenPayload.sub;
      sessionStorage.setItem('access_token', access_token);
      sessionStorage.setItem('refresh_token', refresh_token);
      sessionStorage.setItem('userId', userId);
      setIsProvider(userId).then(() => dispatch({ type: authConstants.LOGIN_SUCCESS, payload: { userId } }))
    }).catch((error) => {
      dispatch({
        type: authConstants.LOGIN_FAILURE
      })
      if (error.response && error.response.status === 400) {
        if (error.response.data.error_description === 'Bad credentials') {
          dispatch(alertActions.error('Usuario o contraseña incorrectos.'))
        } else if (error.response.data.error_description === "User account is locked") {
          dispatch(alertActions.error('La cuenta ha sido bloqueada.'))
        }
      } else {
        dispatch(alertActions.error('Ocurrión un error inesperado, intenta más tarde.'))
      }
    });
  }
}

function signUp(user) {
  return dispatch => {
    authService.signUp(user)
      .then(() => {
        dispatch({
          type: authConstants.SIGNUP_SUCCESS
        })
        dispatch(alertActions.success('Activación exitosa'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SIGNUP_FAILURE
        })
        dispatch(alertActions.error(!error.response.data.message ? 
          "Ocurrio un error al crear la cuenta" : error.response.data.message ))
      })
  }
}

function activeSession(keep_active_session) {
  return dispatch => {
    dispatch({ type: authConstants.ACTIVE_SESSION, payload: { keep_active_session } })
  }
}

function logout() {
  return dispatch => {
    sessionStorage.clear()
    dispatch({
      type: authConstants.LOGOUT
    })
  }
}

function sendEmailPassword(email) {
  return dispatch => {
    authService.sendEmailPassword(email)
      .then(response => {
        dispatch({
          type: authConstants.SENT_EMAIL_PAWSSWORD
        })
        dispatch(alertActions.success('Email enviado correctamente. Revise su bandeja con las instrucciones para restablecer su contraseña.'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SEND_EMAIL_PASSWORD_FAILURE
        })
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function restorePassword(uuid) {
  return dispatch => {
    authService.restorePassword(uuid)
      .then(response => {
        dispatch({
          type: authConstants.RESTORE_PASSWORD_SUCCESS
        })
        dispatch(alertActions.success('Constraseña restablecida correctamente.'))
      })
      .catch(error => {
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function refreshToken() {
  return async (dispatch) => {
    await authService.refreshToken(sessionStorage.getItem('refresh_token'))
      .then((response) => {
        dispatch({
          type: authConstants.REFRESH_TOKEN_SUCESS
        })
      })
      .catch(error => {
        dispatch({ type: authConstants.REFRESH_TOKEN_FAILURE })
      })
  }
}

function refreshTokenNew() {
  return async (dispatch) => {
    await authService.newRefreshToken(sessionStorage.getItem('refresh_token_ipfs'))
      .then((response) => {
        const {
          access_token,
          refresh_token,
        } = response;
    
        sessionStorage.setItem('access_token_ipfs', access_token)
        sessionStorage.setItem('refresh_token_ipfs', refresh_token)
    
        dispatch({
          type: authConstants.REFRESH_TOKEN_SUCESS
        })
      })
      .catch(error => {
        dispatch({ type: authConstants.REFRESH_TOKEN_FAILURE })
      })
  }
}