import React, { useEffect, useState } from "react";
import { Button, Dialog, Grid, Icon, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useParams } from "react-router";

import RenderTextFieldV2 from "../components/inputs/inputs-v2/RenderTextFieldV2";
import StagesTable, { StagesTableProps } from "../components/management/company/DetailsCompany/StagesTable";
import { NTPService } from "../core/services/NTPService.service";
import ViewPDFPage from "./ViewPDFPage";
import { DocumentValidationStatus } from "../types";
import { UpdateDocStatusReqBody } from "../types/service-dashboard.interface";
import Alert from "../components/general/Alert";
import { ServicesService } from "../core/services/services.service";
import { useSearchDebounce } from "../core/hooks/useDebounce";
import { mapDeliverable } from "../core/utils/ntpUtils";

const CompanyServicePage = () => {
  const [serviceStages, setServiceStages] = useState<StagesTableProps[]>([]);
  const [serviceName, setServiceName] = useState("");
  const [selectedDeliverable, setSelectedDeliverable] = useState<number>();
  const [selectedStage, setSelectedStage] = useState<number>();
  const [renderModal, setRenderModal] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [search, setSearch] = useSearchDebounce(600);

  const history = useHistory();
  const dasboardService = NTPService.getInstance();
  const servicesService = ServicesService.getInstance();
  let { serviceId } = useParams<{ serviceId: string }>();

  useEffect(() => {
    servicesService.getServiceDocuments(serviceId, search).then((response) => {
      setServiceName(response.serviceName);
      const aux: StagesTableProps[] | any = [
        {
          title: "Expediente",
          deliverables: ((response as any).Expediente || response.expediente)?.map(mapDeliverable),
        },
        {
          title: "Cumplimiento",
          deliverables: ((response as any).Cumplimiento || response.cumplimiento)?.map(mapDeliverable),
        },
        {
          title: "Reporteo",
          deliverables: ((response as any).Reporteo || response.reporteo)?.map(mapDeliverable),
        },
      ];
      setServiceStages(aux);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, search]);

  const handleClickBack = () => {
    history.go(-1);
  };

  const handleChangeFilter: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const handleOpenDocument = (stage: StagesTableProps) => (id: string) => {
    const docToOpen = stage.deliverables.findIndex((deliverable) => deliverable.id === id);
    const stageFound = serviceStages.findIndex((serviceStage) => stage.title === serviceStage.title);
    if (docToOpen > -1 && stageFound > -1) {
      setSelectedDeliverable(docToOpen);
      setSelectedStage(stageFound);
      setRenderModal(true);
    }
  };

  const handleCloseDocumentView = () => {
    setSelectedDeliverable(undefined);
    setSelectedStage(undefined);
    setRenderModal(false);
  };

  const handleValidateDocument = async (status: DocumentValidationStatus) => {
    try {
      const body: UpdateDocStatusReqBody = {
        uid: serviceStages[selectedStage!].deliverables[selectedDeliverable!].id,
        validationStatus: {
          status: status.status,
          comments: status.comments,
          updatedAt: status.date,
        },
      };
      await dasboardService.updateServiceRequirementDocStatus(
        serviceId,
        serviceStages[selectedStage!].deliverables[selectedDeliverable!].requirementUID,
        body
      );
      const aux = [...serviceStages];
      aux[selectedStage!].deliverables[selectedDeliverable!].status = status.status;
      setServiceStages(aux);
      handleCloseDocumentView();
    } catch (error) {
      setShowAlertError(true);
    }
  };

  const handleCloseAlertError = () => setShowAlertError(false);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item>
          <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={handleClickBack}>
            Regresar
          </Button>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">{serviceName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Documentos:</Typography>
        </Grid>
        <Grid item xs={4}>
          <RenderTextFieldV2 onChange={handleChangeFilter} placeholder="Buscar por Nombre" startIcon={<Icon>search</Icon>} />
        </Grid>
        {serviceStages.map((stage, i) => (
          <StagesTable key={i} {...stage} onOpenDocument={handleOpenDocument(stage)} />
        ))}
      </Grid>
      {renderModal && (
        <Dialog fullScreen open={renderModal} onClose={handleCloseDocumentView}>
          <ViewPDFPage
            documentId={serviceStages[selectedStage!].deliverables[selectedDeliverable!].documentId}
            documentStatus={serviceStages[selectedStage!].deliverables[selectedDeliverable!].status}
            name={serviceStages[selectedStage!].deliverables[selectedDeliverable!].name}
            onReturn={handleCloseDocumentView}
            onValidateStatus={handleValidateDocument}
          />
        </Dialog>
      )}
      <Alert open={showAlertError} title="Hubo un error al tratar de valdiar el documento" onConfirm={handleCloseAlertError} />
    </>
  );
};

export default CompanyServicePage;
