import React from 'react';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface SecondaryMenuProps {
  show: boolean;
  /** Event executed when the user clicks on 'Cerrar sesión' button. */
  onLogout: () => void;
}

const SecondaryMenu = (props: SecondaryMenuProps) => {
  const classes = useStyles();
  return (
    <List className={`${classes.secondMenu} ${props.show ? classes.secondMenuOpen : classes.secondMenuClosed}`}>
      {props.show && <ListItem
        className={classes.listItem}
        button
        component={Link}
        to={`/dashboard/logout`}
        key={'logout'}
        onClick={props.onLogout}
      >
        <ListItemText primary="Cerrar sesión" />
      </ListItem>}
    </List>
  );
}


const useStyles = makeStyles((theme) => {
  return {
    listItem: {
      opacity: 0.5,
      width: 'fit-content',
      transition: 'opacity 0.3s, background-color 0.3s',
      '&:hover': {
        opacity: 1,
        color: 'white',
      },
      '&:focus': {
        textDecoration: 'none',
        outline: 'none',
        color: 'white',
      },
      '& span': {
        fontSize: 14,
      },
    },
    secondMenu: {
      height: 'inherit',
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      justifyContent: 'flex-end',
      transition: 'width 0.5s, opacity 0.6s',
    },
    secondMenuClosed: {
      opacity: 0,
      width: 0,
    },
    secondMenuOpen: {
      opacity: 100,
      width: '100%',
    }
  };
});

export default SecondaryMenu;
