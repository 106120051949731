import React from 'react';
import { IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { getStatusColor } from '../../../../core/utils/utils';
import StatusButton from '../../../general/StatusButton';

type StagesTableRowProps = {
  onOpenDocument?: (id: string) => void;
  onDeleteDocument?: (id: string) => void;
}

const StagesTableRow = (props: StagesTableRowProps & Deliverable) => {
  const classes = useRowStyles();

  const handleOpenDocument = () => props.onOpenDocument?.(props.id);

  return (
    <TableRow hover className={classes.root}>
      <TableCell>{props.name}</TableCell>
      <TableCell>{props.documentName}</TableCell>
      <TableCell>{props.category}</TableCell>
      <TableCell>{props.month}</TableCell>
      <TableCell>{props.uploadDate.toLocaleString()}</TableCell>
      <TableCell>
        <StatusButton bgColor={getStatusColor(props.status)}>{props.status}</StatusButton>
      </TableCell>
      <TableCell>{props.revisionDate?.toLocaleString() ?? '-'}</TableCell>
      <TableCell>
        <IconButton size="small" onClick={handleOpenDocument}>
          <VisibilityIcon />
        </IconButton>
        {/* { props.status !== 'Aceptado' && (
          <IconButton size="small">
            <DeleteIcon />
          </IconButton>
        )} */}
      </TableCell>
    </TableRow>
  )
}

const useRowStyles = makeStyles({
  root: {
  },
});

export default StagesTableRow;