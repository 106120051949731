import mime from 'mime-types';
import { BlockchainService } from '../services/blockchain.service';
import { DocumentsService } from '../services/documets.service';

export interface MappedDocumentInfo {
  name: string;
  MIME: string | false;
  blob: Blob;
  url: string;
}

export interface DownloadedDocument {
  document: BlobPart;
  documentInfo: {
    name: string;
    extension: string;
    id: string;
  };
}

export interface HistoryBlockChainReqBody {
  action: 'READ' | 'DELETE';
  fileId: string;
  user: string;
  fileName: string;
}

export const useDownloadDocument = () => {

  const handleDownloadDocument = async (documentId: string): Promise<DownloadedDocument> => {
    const documentService = DocumentsService.getInstance();
    const blockChainService = BlockchainService.getInstance();
    try {
      const document = await documentService.downloadDocument(documentId);
      const info = await documentService.getDocumentInfo(documentId);
      const json: HistoryBlockChainReqBody = {
        action: 'READ',
        fileId: info.id,
        user: sessionStorage.getItem('currentUser') ?? '',
        fileName: info.name + info.extension
      };
      try {
        await blockChainService.addDocumentHistoryKaliedoContract(json);
      } catch (error) {}
      return {
        document,
        documentInfo: {
          name: info.name,
          id: info.id,
          extension: info.extension
        }
      };
    } catch (error) {
      throw error;
    }
  }

  const downloadDocument = async (documentId: string) => { 
    const { documentInfo, document } = await handleDownloadDocument(documentId);
    const MIME = mime.lookup(documentInfo.extension.toLocaleLowerCase());
    const blob = new Blob([document], { type: MIME || undefined });
    return {
      name: `${documentInfo.name}${documentInfo.extension}`,
      MIME,
      blob,
      url: URL.createObjectURL(blob)
    };
   }

   return downloadDocument;

};
