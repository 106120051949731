
import React, { useEffect, useState } from 'react';

import { Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlertInfo from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CardUser } from '../components/management/company/CardUser';
import DashedButton from '../components/general/DashedButton';
import ManagementTable from '../components/management/management-table/ManagementTable';
import { CompaniesService } from '../core/services/companies.service';
import { useHistory } from 'react-router';
import TextField from "../components/inputs/inputs-v2/RenderTextFieldV2";
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import BlockerLoadPage from '../components/general/BlockerLoadPage';
import { useSearchDebounce } from '../core/hooks/useDebounce';

interface UserTable {
  user: JSX.Element;
  status: string;
  rfc: string;
  createdAt: string;
}

const CompaniesPage = () => {
  //let match = useRouteMatch<{ page?: string }>({ path: "/dashboard/companies/:page" });

  const history = useHistory();

  const [companies, setCompanies] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useSearchDebounce(600);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const companiesService = CompaniesService.getInstance();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getCompaniesActive(page, pageSize, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, search]);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (e) => {
    const value: string = e.target.value.toLowerCase();
    setSearch(value.length > 0 ? value : undefined);
    setLoading(false);
  };

  const getCompaniesActive = async (currentPage: number, pageSize: number, querySearch?: string) => {
    setLoading(true);
    try {
      const data = await companiesService.getAllCompanies(currentPage, pageSize, querySearch);
      const cleanedCompanies = data?.content?.filter((comp: any) => !!comp.name);
      setCompanies(cleanedCompanies || []);
      //setTotalPage(data.totalPages)
      setTotalElements(data.totalElements)
    } finally {
      setLoading(false);
    }
  }

  const handleClickBack = () => { history.push('/dashboard') };

  const handleClickToFormComapny = () => { history.push('/dashboard/new/company') };

  const convertDate = (time: any) => {
    let dateTimeString = moment(time).format("DD-MMMM-YYYY");
    return dateTimeString;
  }

  const mapUsetsToTable = (companies: User[]) => {
    return companies.map<UserTable>((user) => ({
      user: <CardUser user={user} />,
      status: user.status === "" ? "___" : user.status,
      createdAt: user.createdAt === null ? "___" : convertDate(user.createdAt),
      rfc: user.rfc === "" ? "___" : user.rfc,
    }));
  }


  return (
    <>
      <Grid container>

        <Grid item xs={12} className={classes.buttonBack}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={handleClickBack}
          >
            Regresar
          </Button>
        </Grid>

        <Grid item xs={6}>
          <TextField onChange={handleSearch} placeholder="Buscar empresa por Nombre" startIcon={<Icon>search</Icon>} />
        </Grid>

        <Grid item xs={12}>
          {loading && (
            <LinearProgress />
          )}

        </Grid>
        {loading && (
          <BlockerLoadPage />
        )}
        <Grid item xs={12} className={classes.dashedButton}>
          <DashedButton
            color="primary"
            fullWidth
            size="large"
            onClick={handleClickToFormComapny}
          >
            + Nueva Empresa
          </DashedButton>
        </Grid>
        <Grid item xs={12}>
          <ManagementTable
            tableItemsValues={companies}
            page={page}
            count={totalElements}
            pageSize={pageSize}
            onChangePage={handleChangePage}
            onChangePageSize={handleChangePageSize}
            tableItems={mapUsetsToTable}
            header={[
              {
                key: 'user',
                title: 'Razon Social'
              },
              {
                key: 'rfc',
                title: 'RFC'
              },
              {
                key: 'status',
                title: 'Tipo de Licencia',
              },
              {
                key: 'createdAt',
                title: 'Inicio de Licencia'
              }

            ]}

          />
        </Grid>
        {loading && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info" >Cargando clientes...</AlertInfo>
          </Grid>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonBack: {
    paddingBottom: 10,
  }, dashedButton: {
    paddingBottom: 30,
  }
}));
export default CompaniesPage;