import { decodeToken } from '../utils'
import { UserHttp } from "../http/user.http";


export function setIsProvider(userId) {
  const userHttp = new UserHttp();
  userHttp.findUsersById({ id: userId }, resp => {
    sessionStorage.setItem('is_provider', resp.isProvider);
  }, error => {
    console.log(error)
  });
}


export const getCurrentUser = (userId) => {
  // setIsProvider(userId);
  const decodedToken = decodeToken(sessionStorage.getItem('access_token'))
  const isProvider = sessionStorage.getItem('is_provider') === 'true'
  const { user_name, authorities, client_id } = decodedToken


  return {
    isProvider,
    user_name,
    authorities,
    client_id,
    userId
  }
}
