import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Theme from  './styles/common';
import Routing from './core/routes/routing.routes';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { alertActions } from './core/actions';

function App() {
  const alert = useSelector(state => state.alertReducer)
  const dispatch = useDispatch()
  console.log("v1.0.2");

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        dispatch(alertActions.close())
      }, 8000)
    }
  }, [alert.message, dispatch])

  return (
    <ThemeProvider theme={ Theme.normal }>
      <Routing />
    </ThemeProvider>
  );
}

export default App;
